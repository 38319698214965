import * as React from 'react';
import Common from '../../Util/Common';
import {
  MenuItemModel,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import {
  DropDownButtonComponent,
  ItemModel as dromenuitem,
} from '@syncfusion/ej2-react-splitbuttons';
import LeaveInfoListing from './LeaveInfoListing';
import {
  ButtonPropsModel,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import LeaveApplication from './LeaveApplication';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import './Leave.css';
const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

interface Props {
  jsonDataViewer: any;
}

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

export default class LeaveInfo extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private items: dromenuitem[] = [];
  private child: LeaveApplication | any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.child = React.createRef();
  }

  state = {
    leavetypes: [],
    showDialog: false,
    leavetypeid: undefined,
    employees: [],
    employeeid: undefined,
    istabloaded: false,
    clickTrue: true,
  };

  componentDidMount() {
    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }

    if (this.state.employees.length <= 0) {
      this.fetchemployee();
    }
  }

  private fetchemployee() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search${
        !this.user.isManager ? '/ess' : ''
      }`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        if (this.user.employee) {
          this.setState({
            employees: response.data,
            employeeid: this.user.employee.id,
          });
        } else {
          this.setState({ employees: response.data });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      {
        employeeId: this.user.isManager ? null : this.user.employee.id,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ leavetypes: resopnse.data, istabloaded: false });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  setMenuitems = () => {
    if (this.state.leavetypes.length > 0 && this.items.length <= 0) {
      for (let i = 0; i < this.state.leavetypes.length; i++) {
        let item: any = this.state.leavetypes[i];
        this.items.push({ id: item.id, text: item.name });
    }
  }
  };

  onSelect = () => {
    this.setState({ showDialog: true, istabloaded: true });
  };

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false, istabloaded: false });
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        let data: any = this.child.current.GetState();

        // if (this.allocation_hasCode && !data.alloccode) {
        // DialogConfirm.showDialog({
        //   content : '<p class="dialog-contain">Please enter code</p>'
        // })
        //   return;
        // }

        // if (!data.allocname) {
        // DialogConfirm.showDialog({
        //   content : '<p class="dialog-contain">Please enter name</p>'
        // })
        //   return;
        // }

        // if (
        //   this.allocation_hasCustomCode &&
        //   this.selectednode.isgroup === false &&
        //   !data.alloccustomcode
        // ) {
        // DialogConfirm.showDialog({
        //   content : '<p class="dialog-contain">Please enter custom code</p>',
        // })
        //   return;
        // }

        // let payload: any = null;
        // payload = {
        //   id:
        //     this.selectedmenuid === 'changeitem'
        //       ? this.selectednode!['id']
        //       : null,
        //   code: data.alloccode,
        //   name: data.allocname,
        //   isgroup: this.editingnode.isgroup,
        //   customCode: data.alloccustomcode,
        //   [this.allocation_idfield]:
        //     this.grdAllocation.dataSource.length > 0 &&
        //       this.selectednode &&
        //       this.editingnode.addnew === true
        //       ? this.selectednode!['id']
        //       : this.selectednode && this.selectedmenuid === 'changeitem'
        //         ? this.selectednode![this.allocation_idfield]
        //         : null,
        // };

        // this.SaveAllocation(payload);
      },
    },
  ];

  handleSave = (data: any) => {
    if (data !== undefined) {
      let strMsg: any = '';
      strMsg =
        'Leave request (' +
        data.leaveType.name +
        ') for ' +
        Common.formatDate(new Date(data.startDate), 'MMM dd, yyyy') +
        ' to ' +
        Common.formatDate(new Date(data.endDate), 'MMM dd, yyyy') +
        ' has been successfully submitted. You will be notified once your request is approved.';
      DialogConfirm.showDialog({
        content: `<p class="dialog-contain">${strMsg}</p>`,
      });
      this.setState({ showDialog: false, istabloaded: false });
    }
  };

  render() {
    this.setMenuitems();
    return (
      <>
        <div className='row'>
          <div className='col-12 leave-head-class leave-header-label'>
            <label className=''>Leave</label>

            {this.state.leavetypes.length > 0 ? (
              <ButtonComponent
                cssClass='e-flat-leave-apply e-round-corner'
                onClick={this.onSelect}
              >
                Apply
              </ButtonComponent>
            ) : null}
          </div>
        </div>
        {this.state.leavetypes.length > 0 && this.state.istabloaded == false ? (
          <div id='divleaves' className='h-100'>
            <div className='row'>
              <TabComponent
                id='tableaveinfo'
                heightAdjustMode='Auto'
                enablePersistence={true}
              >
                <TabItemsDirective>
                  <TabItemDirective
                    headerText={'Summary'}
                    content={() => (
                      <LeaveInfoListing
                        viewtypeid={1}
                        employees={this.state.employees}
                        leavetypes={this.state.leavetypes}
                        tabname='summary'
                      />
                    )}
                  />
                  <TabItemDirective
                    headerText={'Approval Status'}
                    content={() => (
                      <LeaveInfoListing
                        viewtypeid={2}
                        employees={this.state.employees}
                        leavetypes={this.state.leavetypes}
                        tabname='approval'
                      />
                    )}
                  />
                  <TabItemDirective
                    headerText={'Balances'}
                    content={() => (
                      <LeaveInfoListing
                        viewtypeid={3}
                        employees={this.state.employees}
                        leavetypes={this.state.leavetypes}
                        tabname='balances'
                      />
                    )}
                  />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        ) : (
          <></>
        )}
        {this.state.showDialog ? (
            <DialogComponent
              id='dlgLeaveApplication'
              showCloseIcon={true}
            className='dlgClassRes'
              header={'Leave Application'}
              width={'40vw'}
              visible={this.state.showDialog}
            close={() =>
              this.setState({ showDialog: false, istabloaded: false })
            }
              content={() => (
                <LeaveApplication
                  leaveobj={undefined}
                mainDive={this.state.clickTrue}
                  onSave={this.handleSave}
                onCancel={() =>
                  this.setState({ showDialog: false, istabloaded: false })
                }
                  ref={this.child}
                ></LeaveApplication>
            )}
              isModal={true}
              buttons={[{}]}
              statelessTemplates={[]}
            ></DialogComponent>
        ) : null}
      </>
    );
  }
}