import * as React from 'react';
import Common from '../../Util/Common';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { Inject, ResourceDirective, ResourcesDirective, ScheduleComponent, TimelineMonth, TimelineViews, ViewDirective, ViewsDirective } from '@syncfusion/ej2-react-schedule';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { createRef } from 'react';
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';
import './Schedular.css';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
}

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

export default class Schedular extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private scheduleObj: ScheduleComponent | any;
  private gridObj: GridComponent | any;
  private dateRangePickerRef = createRef<DateRangePickerComponent>();
  private menuObj: ContextMenuComponent | null = null;
  private disabledDates: any = [];
  private weekends: any = [];
  private menuItems: MenuItemModel[];
  private employeeId: any = undefined;
  private shiftId: any = undefined;
  private shiftDate: any = undefined;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.menuItems = [
      { id: '101', text: 'Assign', iconCss: 'e-icons e-plus' },
      { id: '103', text: 'Delete', iconCss: 'e-icons e-trash' },
    ];
  }

  state = {
    events: [],
    employeeData: [],
    shifts: [],
    event: undefined,
    showDialog: false,
    holidays: [],
  }

  componentDidMount() {
    if (this.state.events.length <= 0) {
      this.fetchevents(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
        new Date(new Date().setDate(new Date().getDate() + 7)))
    }

    if (this.state.shifts.length <= 0) {
      this.fetchshifts()
    }

    if (this.state.holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear())
    }
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({ holidays: response.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  fetchshifts() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/shift/listshift`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        if (response.data) {
          this.setState({ shifts: response.data })
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  fetchevents(sDate: any, eDate: any) {
    let payload: any = {
      startDate: sDate,
      endDate: eDate
    }
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/employeeevents${!this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        if (response.data) {
          const clientTimezoneOffset = new Date().getTimezoneOffset() * 60000;

          let ievents: any = response.data.map((ev: any) => ({
            Id: ev.eventId,
            Subject: ev.eventTitle,
            StartTime: new Date(new Date(ev.startDate).getTime() + clientTimezoneOffset),
            EndTime: new Date(new Date(ev.endDate).getTime() + clientTimezoneOffset),
            EmployeeId: ev.employeeId,
            Color: ''
          }));

          let eventColorMap: any = {};
          response.data.forEach((event: any) => {
            const eventName = event.eventTitle;
            if (!eventColorMap[eventName]) {
              eventColorMap[eventName] = event.color !== undefined && event.color !== '' ? event.color : '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
            }
          });

          let evts: any = [];
          var evtname: string = '';
          var evtcolor: string = ''
          for (let i = 0; i < response.data.length; i++) {
            const ev = response.data[i];
            if (evtname.toLowerCase() !== ev.eventTitle.toLowerCase()) {
              evtname = ev.eventTitle;
              // evtcolor = '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
            }

            evts.push({
              Id: ev.eventId,
              Subject: ev.eventTitle,
              StartTime: new Date(new Date(ev.startDate).getTime() + clientTimezoneOffset),
              EndTime: new Date(new Date(ev.endDate).getTime() + clientTimezoneOffset),
              EmployeeId: ev.employeeId,
              color: eventColorMap[ev.eventTitle]
            });
          }

          const distinctEmployees = Array.from(new Map(response.data.map((item: any) => [item.employeeId, {
            employeeId: item.employeeId,
            employeeCodeName: item.employeeCodeName
          }])).values());

          let iemployeeData = distinctEmployees.map((em: any) => ({
            text: em.employeeCodeName,
            id: em.employeeId,
            // color: '#ea7a57'
            // color: '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
          }));

          this.setState({ events: evts, employeeData: iemployeeData })
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  handleActionComplete = (args: any) => {
    if (args.requestType === 'dateNavigate' || args.requestType === 'viewNavigate') {
      const dates = this.scheduleObj.getCurrentViewDates();
      const startDate = Common.RemoveTimezoneOffsetFromDate(dates![0]);
      const endDate = Common.RemoveTimezoneOffsetFromDate(dates![dates!.length - 1]);
      this.fetchevents(startDate, endDate);
    }
  };

  private onEventRendered = (args: any) => {
    if (args.data && args.data.color) {
      args.element.style.backgroundColor = args.data.color;
      args.element.style.borderColor = args.data.color;
    }
  };

  private drpchange = (e: any) => {
    if (e) {
      let shiftdays = (this.state.shifts.filter((x: any) => x.id == e.value)[0] as any).shiftTran
      this.gridObj.dataSource = shiftdays;
      let dayids = shiftdays.filter((x: any) => x.isWeekend === true);
      this.weekends = dayids;
      this.shiftId = e.value;
    }
  }

  onDateChange = () => {
    var startDate = this.dateRangePickerRef.current?.startDate;
    var endDate = this.dateRangePickerRef.current?.endDate;
    if (
      this.dateRangePickerRef &&
      this.dateRangePickerRef.current?.value !== null
    ) {
      let sRange: any = Common.getDatesInRange(startDate, endDate);
      this.disabledDates = this.dateRangePickerRef.current!['disabledDays'];

      let uniqueElements: any = [];
      if (this.disabledDates !== undefined) {
        for (let i = 0; i < sRange.length; i++) {
          let sDate = sRange[i];
          if (
            this.disabledDates.filter(
              (d: any) =>
                new Date(d).getDate() == new Date(sDate).getDate() &&
                new Date(d).getMonth() == new Date(sDate).getMonth()
            ).length <= 0
          ) {
            uniqueElements.push(sDate);
          }
        }
      }

      if (uniqueElements.length > 0) {
        sRange = uniqueElements;
      }
    }
  };

  disabledDate = (e: any) => {
    if (e) {
      if (this.state.holidays.length > 0) {
        if (
          this.state.holidays.filter(
            (h: any) => new Date(h.date).getTime() == e.date.getTime()
          ).length > 0
        ) {
          e.isDisabled = true;
        }
      }
      if (this.weekends.length > 0) {
        if (
          this.weekends.filter((w: any) => w.dayid == e.date.getDay()).length > 0
        ) {
          e.isDisabled = true;
        }
      }
    }
  };

  private generateuploadcontent() {
    let data: any = this.state.event;
    let iname = (this.state.employeeData.filter((x: any) => x.id == (data as any).EmployeeId)[0] as any).text
    let shiftdays: any = undefined;
    let shiftid: any = undefined;
    let dayids: any = [];
    if (this.state.shifts.filter((x: any) => x.name == data.Subject).length > 0) {
      shiftdays = (this.state.shifts.filter((x: any) => x.name == data.Subject)[0] as any).shiftTran
      shiftid = (this.state.shifts.filter((x: any) => x.name == data.Subject)[0] as any).id
      dayids = shiftdays.filter((x: any) => x.isWeekend === true);
    }
    this.weekends = dayids;

    this.employeeId = data.EmployeeId;
    this.shiftDate = new Date(data.StartTime);
    this.shiftId = shiftid;

    if (!data) return <div>No Data</div>
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>Shift</label>
            <DropDownListComponent
              id="Shift"
              name="Shift"
              dataSource={this.state.shifts}
              fields={{ text: 'name', value: 'id' }}
              placeholder="Select Shift"
              value={shiftid}
              text={data.Subject}
              onChange={this.drpchange.bind(this)}
            />
          </div>
          <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>Employee</label>
            <input
              style={{ backgroundColor: 'transparent' }}
              className='input-text-border'
              type="text"
              id="employee"
              name="employee"
              value={iname}
              disabled
            />
          </div>
        </div>
        <div className='row align-item-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>Dates</label>
            <DateRangePickerComponent
              format={this.user.settings.formats.DateShort}
              showTodayButton={false}
              showClearButton={true}
              allowEdit={false}
              openOnFocus={true}
              renderDayCell={this.disabledDate}
              startDate={new Date(data.StartTime)}
              endDate={new Date(data.EndTime)}
              onChange={this.onDateChange}
              onBlur={this.onDateChange}
              ref={this.dateRangePickerRef}
              enabled={false}
            />
          </div>
        </div>
        <div className='row align-item-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>Shift Days</label>
            <GridComponent allowTextWrap={true}
              ref={g => this.gridObj = g}
              allowGrouping={false}
              dataSource={shiftdays}
              showColumnChooser={false}
              editSettings={{
                allowEditing: false,
                allowAdding: false,
                allowDeleting: false,
                allowEditOnDblClick: false,
              }}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field='dayName'
                  headerText='Day'
                  allowEditing={false}
                />
                <ColumnDirective
                  field='startTime'
                  headerText='Start Time'
                  type='datetime'
                  format='HH:mm'
                  editType='datetimepickeredit'
                />
                <ColumnDirective
                  field='endTime'
                  headerText='End Time'
                  type='datetime'
                  format='HH:mm'
                  editType='datetimepickeredit'
                />
                <ColumnDirective
                  field='isWeekend'
                  headerText='Weekend'
                  displayAsCheckBox={true}
                  editType='booleanedit'
                />
              </ColumnsDirective>
            </GridComponent>
          </div>
        </div>
      </>
    );
  }

  onPopupClose = (args: any): void => {
    if (args) {

    }
  }

  eventClick = (args: any) => {
    if (args) {
      this.setState({ showDialog: true, event: args.event })
    }
  }

  onPopupOpen(args: any) {
    if (args.type === 'Editor') {
      args.cancel = true;
    }
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.setState({ showDialog: false, event: undefined });
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        DialogConfirm.showDialog({
          isConfirm: true,
          content:
            '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain" style="font-size:19px; font-weight: 100 !important;">Are you sure you want to modify selected shift for that day?</p></div>',
          okCaption: 'Yes',
          OkEvent: this.confirm.bind(this),
        });
      }
    },
  ];

  private confirm() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/assignshift`,
      {
        employeeId: this.employeeId,
        shiftId: this.shiftId,
        shiftDate: Common.formatDate(Common.RemoveTimezoneOffsetFromDate(new Date(this.shiftDate)), 'yyyy-MM-dd')
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.employeeId = undefined;
        this.shiftId = undefined;
        this.shiftDate = undefined;
        this.setState({ showDialog: false, event: undefined });
        if (this.scheduleObj) {
          const dates = this.scheduleObj.getCurrentViewDates();
          const startDate = Common.RemoveTimezoneOffsetFromDate(dates![0]);
          const endDate = Common.RemoveTimezoneOffsetFromDate(dates![dates!.length - 1]);
          this.fetchevents(startDate, endDate);
        }
        else {
          this.fetchevents(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
            new Date(new Date().setDate(new Date().getDate() + 7)))
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private confirmDelete() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/unassignshift`,
      {
        employeeId: (this.state.event as any).EmployeeId,
        id: (this.state.event as any).Id,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ event: undefined });
        if (this.scheduleObj) {
          const dates = this.scheduleObj.getCurrentViewDates();
          const startDate = Common.RemoveTimezoneOffsetFromDate(dates![0]);
          const endDate = Common.RemoveTimezoneOffsetFromDate(dates![dates!.length - 1]);
          this.fetchevents(startDate, endDate);
        }
        else {
          this.fetchevents(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())),
            new Date(new Date().setDate(new Date().getDate() + 7)))
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private onMenuItemSelect(e: any) {
    if (e) {
      if (this.state.event) {
        if (e.item.id === '101') {
          if ((this.state.event as any).Id == Common.blankguid) {
            this.setState({ showDialog: true })
          }
          else {
            DialogConfirm.showDialog({
              content:
                '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain" style="font-size:19px; font-weight: 100 !important;">Sorry, you cannot assign shift on selected date for this employee, As shift is already present for the date selected.</p></div>',
            });
          }
        }
        else if (e.item.id === '103') {
          if ((this.state.event as any).Id == Common.blankguid) {
            DialogConfirm.showDialog({
              content:
                '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain" style="font-size:19px; font-weight: 100 !important;">Sorry, you cannot perform selected operation as there is not shift assigned for the selected employee and date.</p></div>',
            });
          }
          else {
            DialogConfirm.showDialog({
              isConfirm: true,
              content:
                '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain" style="font-size:19px; font-weight: 100 !important;">Are you sure you want to delete assigned shift for that day?</p></div>',
              okCaption: 'Yes',
              OkEvent: this.confirmDelete.bind(this),
            });
          }
        }
      }
    }
  }

  private onContextMenuBeforeOpen(e: any) {
    if (e) {
      if (!(e.event.target.classList.contains('e-appointment') || e.event.target.classList.contains('e-subject') || e.event.target.classList.contains('e-time'))) {
        e.cancel = true;
      }

      if (e.event.target.offsetParent.classList.contains('e-appointment')) {
        if (this.scheduleObj) {
          let evt = this.scheduleObj.getEventDetails(e.event.target.offsetParent);
          if (evt) {
            this.setState({ event: evt })
          }
        }
      }
    }
  }

  render() {
    return (
      <>
        <div>
          <ScheduleComponent
            id='shiftschedule'
            eventSettings={{ dataSource: this.state.events }}
            group={{ resources: ['Employees'] }}
            timeScale={{ enable: false }}
            height='calc(100vh - 110px)'
            ref={t => this.scheduleObj = t}
            actionComplete={this.handleActionComplete}
            eventRendered={this.onEventRendered}
            showQuickInfo={false}
            popupClose={this.onPopupClose.bind(this)}
            popupOpen={this.onPopupOpen.bind(this)}
          //eventClick={this.eventClick.bind(this)}            
          >
            <ViewsDirective>
              <ViewDirective option='TimelineWeek' allowVirtualScrolling={true} displayName='Weekly' />
              <ViewDirective option='TimelineMonth' allowVirtualScrolling={true} displayName='Monthly' />
            </ViewsDirective>
            <ResourcesDirective>
              <ResourceDirective
                field='EmployeeId'
                title='employeeCodeName'
                name='Employees'
                allowMultiple={true}
                dataSource={this.state.employeeData}
                textField='text'
                idField='id'
                colorField='color'
              />
            </ResourcesDirective>
            <Inject services={[TimelineViews, TimelineMonth]} />
          </ScheduleComponent>
          {this.state.showDialog ?
            <>
              <DialogComponent
                id='dlgAddEdit'
                showCloseIcon={true}
                header={'Add/Modify Shift'}
                width={'40vw'}
                visible={this.state.showDialog}
                close={() => this.setState({ showDialog: false })}
                content={this.generateuploadcontent.bind(this)}
                isModal={true}
                buttons={this.DialogButtonModel}
                statelessTemplates={[]}
              ></DialogComponent>
            </> : null}
        </div>
        <ContextMenuComponent cssClass='schedule-context-menu' ref={menu => this.menuObj = menu} target='.e-schedule' items={this.menuItems} beforeOpen={this.onContextMenuBeforeOpen.bind(this)} select={this.onMenuItemSelect.bind(this)} />
      </>
    );
  }
}