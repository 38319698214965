// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-02-20 - 240 - meeting
// * SUHAIL - 2023-02-21 - 180
// * SOURAV - 2023-07-05 - 80 -- meeting.
// * SOURAV - 2023-07-06 - 30 -- meeting.
// * SOURAV - 2023-07-07 - 50 -- meeting.
// * SOURAV - 2023-07-10 - 105 -- meeting.
// * SOURAV - 2023-07-12 - 80 -- meeting.
// * SOURAV - 2023-07-14 - 60 -- meeting.
// * SOURAV - 2023-07-18 - 40 -- meeting.
// * SOURAV - 2023-07-20 - 80 -- meeting.
// * SOURAV - 2023-07-21 - 60 -- meeting.
// * SOURAV - 2023-09-22 - 70 -- meeting.
// * SOURAV - 2023-09-29 - 30 -- meeting.
// * SOURAV - 2023-10-03 - 30 -- meeting.
// * SOURAV - 2023-11-10 - 30 -- meeting.

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-21 - 10
// * SUHAIL - 2023-02-22 - 10
// * SUHAIL - 2023-02-23 - 10

import React, { useContext, useEffect, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import Common from '../../Util/Common';
import { PersonCircleOutline, LockClosedOutline } from 'react-ionicons';
//import { LoginState, useLoginContext } from './LoginReducer';
import { AppContext } from './AppState';
//import Register from '../../Pages/Register';
import { googleLogout, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
//import Wizard from './Wizard';
//import SignupWizard from './SignupWizard';
//import Form from './Registration/Form';
import Regwizard from './GSignup/Regwizard';
import './Login.css';
// import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Main from './Signup/Main';
//import '../../App.css';
const {
  REACT_APP_ENDPOINT_USERSERVICE,
  REACT_APP_ENDPOINT_TOKENS,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_SIDEBARPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
  REACT_APP_VERSION,
} = process.env;

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});
interface props {
  googleErrorMsg: boolean;
}
// * ------------------------------------------------------------------------------------------------------------------3.Style

// * ------------------------------------------------------------------------------------------------------------------4.Api
// * SUHAIL - 2023-02-23 - 60
// * SUHAIL - 2023-02-24 - 20
// * SUHAIL - 2023-03-28 - 20
// * SOURAV - 2023-06-21 - 300 -- writing code for  registration page.
// * SOURAV - 2023-06-23 - 60 -- working on registration page.
// * SOURAV - 2023-07-01 - 380 -- working on registration page google authentication.
// * SOURAV - 2023-07-04 - 300 -- writing code for google auth for registraion.
// * SOURAV - 2023-07-04 - 170 -- reading docs related to google auth.
// * SOURAV - 2023-07-05 - 380 -- working on wizard for registration and also implemented changes in google auth.
// * SOURAV - 2023-07-06 - 450 --  writing code to implement wizard for registration and also reading docs related to it.
// * SOURAV - 2023-07-07 - 420 --  writing code for different different steps in wizard of registration page.
// * SOURAV - 2023-07- 10 - 360 --  working on modification of wizard and also implementing identity form in my code as per the shared ppt(registration).
// * SOURAV - 2023-07- 11 - 460 --  writing code for registration content page and also working on to set wizard.
// * SOURAV - 2023-07- 12 - 350 --  working on to introduce icon in place of steps in wizard form and doing research related to it.
// * SOURAV - 2023-07- 13 - 450 --  Implementing code in aruti 2.0 and working on it's layout and functionality.
// * SOURAV - 2023-07- 14 - 400 --  working on icon and functionality of registration form.
// * SOURAV - 2023-07- 17 - 460 --  working on functionality of registration form including all step form component and also working on the layout of access code page.
// * SOURAV - 2023-07- 18 - 220 --  working on registration (layout and functionality)form without google auth.
// * SOURAV - 2023-07- 18 - 200 --  working on functionality of google registration form(including all step form).
// * SOURAV - 2023-07- 20 - 380 --  working on to store form input data in an object and also working on functionality of both the form.
// * SOURAV - 2023-07- 21 - 400 --  working on to implement dropdown and datepicker component (in both google reg and without google reg) and also implement some functionality changes as required.
// * SOURAV - 2023-09- 22 - 390 --  working on to fix styling issue of registration page.
// * SOURAV - 2023-09- 29 - 430 --  working on the layout of details page,confirm page,message pege of registration page(with google) and also worked on confirm page of register(without google) .
// * SOURAV - 2023-10- 03 - 320 --  working on the layout of details page,confirm page,message pege of registration page .
// * SOURAV - 2023-10-04 - 180 --  working on the layout all stepper form of registration page .
// * SOURAV - 2023-10-05 - 340 --  working on the layout (to make it responsieve) all stepper form of registration page .
// * SOURAV - 2023-10-17 - 120 --  working on the layout (to make it responsieve) all stepper form of registration page .
// * SOURAV - 2023-10-20 - 240 --  working on the aignup button and layout (to make it responsieve)  stepper form of registration page .
// * SOURAV - 2023-11-10 - 450 --  working on to make login page responsive.
// * SOURAV - 2023-11-16 - 150 --  working on to make login page responsive.
// * SOURAV - 2023-11-20 - 300 --  working on the layout of login page.
// * SUHAIL - 2023-11-27 - 180 - Login : Show validation message in username and password text as place holder
// * SOURAV - 2023-11-29 - 400 - Working on to set bootstrap modal dialog box in place of syncfusion dialog box and also working on to fix login with google button issue

export default function Login(props: any) {
  // * ------------------------------------------------------------------------------------------------------------------5.State
  // * SUHAIL - 2023-02-23 - 20

  //const { state, dispatch } = useLoginContext();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //let { username, password, isLoading, error, isLoggedIn } = state;
  let { username, password, isLoading, error, isLoggedIn } = state.login;

  var intrvl: any;
  var splashInterval = 0;
  var maxInterval = 4; //seconds
  // * ----------------------------------------------------------------------------------------------------------------6.Reader

  // * ----------------------------------------------------------------------------------------------------------------7.Adapter

  // * --------------------------------------------------------Event Handler
  // * SUHAIL - 2023-02-21 - 180
  // * SUHAIL - 2023-02-22 - 120
  // * SUHAIL - 2023-02-23 - 60

  useEffect(() => {
    if (props.googleErrorMsg) {
      dispatch({ type: 'error' });
    }
  }, [props.googleErrorMsg]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true);

    try {
      if (username && password) {
        dispatch({ type: 'login' });

        intrvl = setInterval(() => {
          splashInterval += 1;
        }, 1000);

        let rs: any = await DoLogin(username, password);
        dispatch({ type: 'success' });
        navigate('/');
      }
    } catch (error) {
      dispatch({ type: 'error' });
    }
  };

  const handleClick = () => {
    setDialog(true);
  };

  const openModal = () => {
    setShowModal(true);
  };

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  const closeModal = (data: any) => {
    setShowModal(!data);
  };
  // *  ------------------------------------------------------------------------------------------------------------------8.HTML
  // * SUHAIL - 2023-02-21 - 60
  // * SUHAIL - 2023-02-22 - 90
  // * SUHAIL - 2023-02-23 - 30

  async function DoLogin(username: string, password: string): Promise<void> {
    try {
      return new Promise((resolve, reject) => {
        let requestdata: any = { email: username, password: password };

        Common.ApiCallAsync(
          'post',
          `${REACT_APP_ENDPOINT_USERSERVICE}/gettenant`,
          requestdata
        )
          .then((response: any) => {
            let _tenant: any = response.data;
            if (_tenant == '') {
              _tenant = 'root';
            }

            Common.ApiCallAsync(
              'post',
              '' + REACT_APP_ENDPOINT_TOKENS,
              requestdata,
              '',
              null,
              _tenant
            )
              .then((response: any) => {
                let token: string = response.data.token;
                let refreshtoken: string = response.data.refreshToken;
                Common.ApiCallAsync(
                  'post',
                  `${REACT_APP_ENDPOINT_USERSERVICE}/getuser`,
                  requestdata,
                  token,
                  null,
                  _tenant
                )
                  .then((response: any) => {
                    let user: any = response.data;
                    Common.setItem('token', token);
                    Common.setItem('refreshtoken', refreshtoken);
                    Common.setItem('user', user);
                    console.log('frontend-version', REACT_APP_VERSION);
                    console.log('headers', response.headers);
                    console.log(
                      'backend-version',
                      response.headers['x-custom-header-frontendversion']
                    );
                    // if (
                    //   REACT_APP_VERSION !==
                    //   response.headers['x-custom-header-frontendversion']
                    // ) {
                    //   Common.setItem('version-update-needed', true);
                    // }
                    if (splashInterval < maxInterval) {
                      setTimeout(() => {
                        clearInterval(intrvl);

                        resolve();
                      }, (maxInterval - splashInterval) * 1000);
                    } else {
                      clearInterval(intrvl);

                      resolve();
                    }
                  })
                  .catch((error: any) => {
                    console.error('error in getting fulljson');
                    reject(error);
                  });
              })
              .catch((error: any) => {
                console.error('error in getting structure');
                clearInterval(intrvl);
                reject(error);
              });
            //resolve()
          })
          .catch((error: any) => {
            console.error('error in getting user');
            clearInterval(intrvl);
            reject(error);
          });
        // })
        // .catch((error: any) => {
        //   console.log('error in login')
        //   reject(error)
        // })
      });
    } catch (error) {
      clearInterval(intrvl);
      console.log(error);
    }
  }

  return (
    <>
      <form className='form' onSubmit={onSubmit}>
        {/* {error && <p className='error text-danger fw-bold small'>{error}</p>} */}
        {/* <div className='row' style={{ border: '1px solid red' }}>
          <div className='col-5  login-textrow'>Login</div>
          <div className='col-6'>New here? Signup</div>
        </div> */}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className='login-textrow'>Login</div>
          <div
            className='d-none'
            style={{
              fontSize: '14px',
              marginBottom: '13px',
            }}
          >
            New here?
            <button
              type='button'
              className='btn'
              data-bs-toggle='modal'
              data-bs-target='#exampleModal'
              onClick={(e: any) => {
                props.showDialogs('signup');
                props.showFooter(false);
                props.dialogHeader('Sign Up');
                props.dialogWidth('1100px');
              }}
              style={{
                color: '#0d6efd',
                fontSize: '14px',
                outline: 'none',
                border: 'none',
              }}
            >
              Signup
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder={error && !username ? 'Invalid' : 'username'}
                value={username}
                onChange={(e) =>
                  dispatch({
                    type: 'field',
                    fieldName: 'username',
                    payload: e.currentTarget.value,
                  })
                }
              />
              <span className='input-group-text' id='basic-addon2'>
                <PersonCircleOutline
                  color={'#828282'}
                  title={'Username'}
                  height='25px'
                  width='25px'
                />
              </span>
            </div>
            {/* {submitted && !username && (
              <p className='text-danger fw-bold small'>Username is required</p>
            )} */}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='input-group mb-3'>
              <input
                type='password'
                placeholder={error && !password ? 'Invalid' : 'password'}
                className='form-control'
                autoComplete='new-password'
                value={password}
                onChange={(e) =>
                  dispatch({
                    type: 'field',
                    fieldName: 'password',
                    payload: e.currentTarget.value,
                  })
                }
              />
              <span className='input-group-text' id='basic-addon2'>
                <LockClosedOutline
                  color={'#828282'}
                  title={'Password'}
                  height='25px'
                  width='25px'
                />
              </span>
            </div>
            {/* {submitted && !password && (
              <p className='text-danger fw-bold small'>Password is required</p>
            )} */}
          </div>
        </div>
        {props.googleErrorMsg && error && !username && (
          <>
            <div className='row'>
              <div className='col-12'>
                <div className='' style={{ width: '275px' }}>
                  <span className='text-danger fw-bold small'>
                    {props.googleErrorMsg}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='row'>
          <div className='col-12'>
            <div className='input-group mb-3'>
              <button
                type='submit'
                className='btn submit w-100'
                disabled={isLoading}
              >
                {isLoading ? 'Loggin in.....' : 'Login'}
              </button>
            </div>
          </div>
        </div>
        {/* 
        
        <div className='row'>
          <div className='col-md-12'>
            <GoogleLogin
              text={'signin_with'}
              onSuccess={responseGoogle}
              //onFailure={responseGoogle}
              //scope='profile email'
              //responseType='code'
              theme={'outline'}
              logo_alignment={'center'}
              shape={'rectangular'}
              size={'large'}
              width={'290px'}
            />
          </div>

        </div> */}

        <div>
          {/* {dialog ? (
            <DialogComponent
              id='signupDialog2'
              showCloseIcon={true}
              visible={dialog}
              width={'58vw'}
              height='57vh'
              close={() => setDialog(false)}
              isModal={true}
              content={() => <Main />}
              statelessTemplates={[]}
              //style={{ border: '2px solid black' }}
            ></DialogComponent>
          ) : null} */}
        </div>

        {/* <div
          className={`modal fade ${showModal ? 'show' : ''}`}
          tabIndex={-1}
          role='dialog'
          style={{ display: showModal ? 'block' : 'none', zIndex: '1000' }}
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered custom-width'
            role='document'
            //style={{ width: '1000px' }}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'></h5>
                <button
                  type='button'
                  className='close'
                  onClick={closeModal}
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <Main exitStep={closeModal} />
              </div>
            </div>
          </div>
        </div> */}
      </form>
    </>
  );
}
