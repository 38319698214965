import * as React from 'react';
import Common from '../../Util/Common';
import {
  ColorPickerComponent,
  NumericTextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import {
  CheckBoxComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import './Leave.css';
import {
  Accordion,
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import DialogConfirm from '../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface leavetypedetails {
  leavedata: any;
  leavetypes: any;
  refreshLeavedata?: any;
  handleRefreshSidebar?: any;
}

export default class LeaveTypeDetail extends React.Component<leavetypedetails> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private months: any = [];
  private days: any = [];
  private edays: any = [];
  private deductleavetypes: any = [];
  private color: string = '';

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.months = Common.getMonthNames(true);
    this.days = Common.getDaysNumber('Last Day', false);
    this.edays = Common.getDaysNumber(undefined, false, true);
  }
  state = {};

  componentDidMount(): void {
    this.deductleavetypes = this.props.leavetypes.filter(
      (x: any) => x.id !== this.props.leavedata.id
    );
  }

  componentDidUpdate(): void {
    this.deductleavetypes = this.props.leavetypes.filter(
      (x: any) => x.id !== this.props.leavedata.id
    );
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    this.SaveData();
  }

  onUnload = (e: any) => {
    window.removeEventListener('beforeunload', this.onUnload);
    e.preventDefault();
    e.returnValue = '';
    this.SaveData();
  };

  radcheckevent = (e: any) => {
    if (e.target.id.toLowerCase() === 'radmonthly') {
      if (e.target.properties.checked === true) {
        (document.getElementById('yearlydays') as any).value = 0;
        (document.getElementById('yDayList') as any).value = 0;
        (document.getElementById('yMonthList') as any).value = 0;
      }
    } else if (e.target.id.toLowerCase() === 'radyearly') {
      if (e.target.properties.checked === true) {
        (document.getElementById('monthlydays') as any).value = 0;
        (document.getElementById('mDayList') as any).value = 0;
      }
    }
  };

  checkevent = (e: any) => {
    let elem: string = '';
    if (e.target.id.toLowerCase() === 'chkaccruedleave') {
      elem = 'divaccrgrp';
    } else if (e.target.id.toLowerCase() === 'chkresetyearly') {
      if (e.target.properties.checked === false) {
        (document.getElementById('yResetDayList') as any).value = 0;
        (document.getElementById('yResetMonthList') as any).value = 0;
      }
      elem = 'resetyearly';
    } else if (e.target.id.toLowerCase() === 'chkexpirydays') {
      if (e.target.properties.checked === false) {
        (document.getElementById('yexpiredDayList') as any).value = 0;
      }
      elem = 'expirydays';
    } else if (e.target.id.toLowerCase() === 'chknoticedays') {
      if (e.target.properties.checked === false) {
        (document.getElementById('noticedayvalue') as any).value = 0;
      }
      elem = 'dvnoticedays';
    } else if (e.target.id.toLowerCase() === 'chkdocrequired') {
      if (e.target.properties.checked === false) {
        (document.getElementById('attchdocdays') as any).value = 0;
      }
      elem = 'dvlvdocdays';
    } else if (e.target.id.toLowerCase() === 'radapprove') {
      if (e.target.properties.checked === true) {
        (document.getElementById('rejectdaysafter') as any).value = 0;
      }
      elem = 'dvAutoApprove';
    } else if (e.target.id.toLowerCase() === 'radreject') {
      if (e.target.properties.checked === true) {
        (document.getElementById('approvedaysafter') as any).value = 0;
      }
      elem = 'dvAutoReject';
    }

    if (e.target.properties.checked === true && elem.trim().length > 0) {
      (document.getElementById(elem) as any).classList.remove('disablediv');
    } else if (e.target.properties.checked == false) {
      (document.getElementById(elem) as any).classList.add('disablediv');
    }
  };

  oncolorchange = (e: any) => {
    if (e) {
      this.color = e.currentValue.hex;
    }
  };

  SaveData = () => {
    let blnchange: boolean = false;
    // Commonpart -- SART
    if (
      document.getElementById('txtCode') &&
      (document.getElementById('txtCode') as any).value !==
        this.props.leavedata.code
    ) {
      this.props.leavedata.code = (
        document.getElementById('txtCode') as any
      ).value;
      blnchange = true;
    }

    if (
      document.getElementById('txtCode') &&
      (document.getElementById('txtName') as any).value !==
        this.props.leavedata.name
    ) {
      this.props.leavedata.name = (
        document.getElementById('txtName') as any
      ).value;
      blnchange = true;
    }

    if (
      document.getElementById('colorpicker') &&
      (document.getElementById('colorpicker') as any).value != '' &&
      this.color !== ''
    ) {
      this.props.leavedata.color =
        this.color == ''
          ? (document.getElementById('colorpicker') as any).value
          : this.color;
      blnchange = true;
    }

    if (
      document.getElementById('radFullDays') &&
      (document.getElementById('radFullDays') as any).checked == true &&
      (document.getElementById('radFullDays') as any).value ==
        this._structure.Unit.FullDay.id
    ) {
      if (this.props.leavedata.unitId !== this._structure.Unit.FullDay.id) {
        this.props.leavedata.unitId = this._structure.Unit.FullDay.id;
        blnchange = true;
      }
    }

    if (
      document.getElementById('radFlexHours') &&
      (document.getElementById('radFlexHours') as any).checked == true &&
      (document.getElementById('radFlexHours') as any).value ==
        this._structure.Unit.FlexHours.id
    ) {
      if (this.props.leavedata.unitId !== this._structure.Unit.FlexHours.id) {
        this.props.leavedata.unitId = this._structure.Unit.FlexHours.id;
        blnchange = true;
      }
    }
    // Commonpart -- END

    // Entitlement -- START
    if (
      document.getElementById('effectiveDaysAfter') &&
      (document.getElementById('effectiveDaysAfter') as any).value > 0
    ) {
      if (
        this.props.leavedata.effectiveDaysAfter !==
        Number((document.getElementById('effectiveDaysAfter') as any).value)
      ) {
        this.props.leavedata.effectiveDaysAfter = Number(
          (document.getElementById('effectiveDaysAfter') as any).value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('chkAccruedLeave') &&
      (document.getElementById('chkAccruedLeave') as any).checked == true
    ) {
      if (
        this.props.leavedata.isAccrued !==
        (document.getElementById('chkAccruedLeave') as any).checked
      ) {
        this.props.leavedata.isAccrued = (
          document.getElementById('chkAccruedLeave') as any
        ).checked;
        blnchange = true;
      }
    }

    if (
      document.getElementById('radMonthly') &&
      (document.getElementById('radMonthly') as any).checked == true &&
      (document.getElementById('radMonthly') as any).value ==
        this._structure.AccrualType.Monthly.id
    ) {
      if (
        (document.getElementById('monthlydays') as any).value > 0 &&
        (document.getElementById('mDayList') as any).value > 0
      ) {
        if (
          this.props.leavedata.accrualTypeId !==
          this._structure.AccrualType.Monthly.id
        ) {
          this.props.leavedata.accrualTypeId =
            this._structure.AccrualType.Monthly.id;
          blnchange = true;
        }

        if (
          this.props.leavedata.accruedValue !==
          Number((document.getElementById('monthlydays') as any).value)
        ) {
          this.props.leavedata.accruedValue = Number(
            (document.getElementById('monthlydays') as any).value
          );
          blnchange = true;
        }

        if (
          this.props.leavedata.accruedStartDay !==
          Number((document.getElementById('mDayList') as any).value)
        ) {
          this.props.leavedata.accruedStartDay = Number(
            (document.getElementById('mDayList') as any).value
          );
          blnchange = true;
        }
      }
    }

    if (
      document.getElementById('radYearly') &&
      (document.getElementById('radYearly') as any).checked == true &&
      (document.getElementById('radYearly') as any).value ==
        this._structure.AccrualType.Yearly.id
    ) {
      if (
        (document.getElementById('yearlydays') as any).value > 0 &&
        (document.getElementById('yDayList') as any).value > 0 &&
        (document.getElementById('yMonthList') as any).ej2_instances[0].value >
          0
      ) {
        if (
          this.props.leavedata.accrualTypeId !==
          this._structure.AccrualType.Yearly.id
        ) {
          this.props.leavedata.accrualTypeId =
            this._structure.AccrualType.Yearly.id;
          blnchange = true;
        }

        if (
          this.props.leavedata.accruedValue !==
          Number((document.getElementById('yearlydays') as any).value)
        ) {
          this.props.leavedata.accruedValue = Number(
            (document.getElementById('yearlydays') as any).value
          );
          blnchange = true;
        }

        if (
          this.props.leavedata.accruedStartDay !==
          Number(
            (document.getElementById('yDayList') as any).ej2_instances[0].value
          )
        ) {
          this.props.leavedata.accruedStartDay = Number(
            (document.getElementById('yDayList') as any).ej2_instances[0].value
          );
          blnchange = true;
        }

        if (
          this.props.leavedata.accruedStartMonth !==
          Number(
            (document.getElementById('yMonthList') as any).ej2_instances[0]
              .value
          )
        ) {
          this.props.leavedata.accruedStartMonth = Number(
            (document.getElementById('yMonthList') as any).ej2_instances[0]
              .value
          );
          blnchange = true;
        }
      }
    }

    if (
      document.getElementById('leaveid') &&
      (document.getElementById('leaveid') as any).value !== ''
    ) {
      if (
        this.props.leavedata.deductFromLeaveId !==
        (document.getElementById('leaveid') as any).ej2_instances[0].value
      ) {
        this.props.leavedata.deductFromLeaveId = (
          document.getElementById('leaveid') as any
        ).ej2_instances[0].value;
        blnchange = true;
      }
    }

    if (
      document.getElementById('chkConsecutiveDays') &&
      document.getElementById('chkConsecutiveDays')
    ) {
      if (
        this.props.leavedata.isConsecutive !==
        (document.getElementById('chkConsecutiveDays') as any).checked
      ) {
        this.props.leavedata.isConsecutive = (
          document.getElementById('chkConsecutiveDays') as any
        ).checked;
        blnchange = true;
      }
    }

    if (
      document.getElementById('yResetDayList') &&
      (document.getElementById('yResetDayList') as any).value !== '' &&
      (document.getElementById('yResetMonthList') as any).value !== ''
    ) {
      if (
        this.props.leavedata.resetDay !==
        Number(
          (document.getElementById('yResetDayList') as any).ej2_instances[0]
            .value
        )
      ) {
        this.props.leavedata.resetDay = Number(
          (document.getElementById('yResetDayList') as any).ej2_instances[0]
            .value
        );
        blnchange = true;
      }

      if (
        this.props.leavedata.resetMonth !==
        Number(
          (document.getElementById('yResetMonthList') as any).ej2_instances[0]
            .value
        )
      ) {
        this.props.leavedata.resetMonth = Number(
          (document.getElementById('yResetMonthList') as any).ej2_instances[0]
            .value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('yexpiredDayList') &&
      (document.getElementById('yexpiredDayList') as any).value !== ''
    ) {
      if (
        this.props.leavedata.expiryDays !==
        Number(
          (document.getElementById('yexpiredDayList') as any).ej2_instances[0]
            .value
        )
      ) {
        this.props.leavedata.expiryDays = Number(
          (document.getElementById('yexpiredDayList') as any).ej2_instances[0]
            .value
        );
        blnchange = true;
      }
    }
    // Entitlement -- END

    // Eligibility Data -- START
    if (
      document.getElementById('genderid') &&
      (document.getElementById('genderid') as any).value !== ''
    ) {
      if (
        this.props.leavedata.genderId !==
        (document.getElementById('genderid') as any).ej2_instances[0].value
      ) {
        this.props.leavedata.genderId = (
          document.getElementById('genderid') as any
        ).ej2_instances[0].value;
        blnchange = true;
      }
    }
    // Eligibility Data -- END

    // Rules -- START
    if (document.getElementById('chkIssueOnHoliday')) {
      if (
        this.props.leavedata.issueOnHoliday !==
        (document.getElementById('chkIssueOnHoliday') as any).checked
      ) {
        this.props.leavedata.issueOnHoliday = (
          document.getElementById('chkIssueOnHoliday') as any
        ).checked;
        blnchange = true;
      }
    }

    if (document.getElementById('chkIssueOnWeekend')) {
      if (
        this.props.leavedata.issueOnWeekend !==
        (document.getElementById('chkIssueOnWeekend') as any).checked
      ) {
        this.props.leavedata.issueOnWeekend = (
          document.getElementById('chkIssueOnWeekend') as any
        ).checked;
        blnchange = true;
      }
    }

    if (document.getElementById('chkallowbackdate')) {
      if (
        this.props.leavedata.isBackDateLeaveAllowed !==
        (document.getElementById('chkallowbackdate') as any).checked
      ) {
        this.props.leavedata.isBackDateLeaveAllowed = (
          document.getElementById('chkallowbackdate') as any
        ).checked;
        blnchange = true;
      }
    }

    if (
      document.getElementById('noticedayvalue') &&
      (document.getElementById('noticedayvalue') as any).value > 0
    ) {
      if (
        this.props.leavedata.noticeDays !==
        Number((document.getElementById('noticedayvalue') as any).value)
      ) {
        this.props.leavedata.noticeDays = Number(
          (document.getElementById('noticedayvalue') as any).value
        );
        blnchange = true;
      }
    }

    if (document.getElementById('chkSkipapproval')) {
      if (
        this.props.leavedata.isApprovalSkipped !==
        (document.getElementById('chkSkipapproval') as any).checked
      ) {
        this.props.leavedata.isApprovalSkipped = (
          document.getElementById('chkSkipapproval') as any
        ).checked;
        blnchange = true;
      }
    }

    if (document.getElementById('chkRelieverrequired')) {
      if (
        this.props.leavedata.isRelieverNeeded !==
        (document.getElementById('chkRelieverrequired') as any).checked
      ) {
        this.props.leavedata.isRelieverNeeded = (
          document.getElementById('chkRelieverrequired') as any
        ).checked;
        blnchange = true;
      }
    }

    if (
      document.getElementById('attchdocdays') &&
      (document.getElementById('attchdocdays') as any).value > 0
    ) {
      if (
        this.props.leavedata.leaveExceedDaysRequireAttachment !==
        Number((document.getElementById('attchdocdays') as any).value)
      ) {
        this.props.leavedata.leaveExceedDaysRequireAttachment = Number(
          (document.getElementById('attchdocdays') as any).value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('radApprove') &&
      (document.getElementById('radApprove') as any).checked == true &&
      (document.getElementById('radApprove') as any).value ==
        this._structure.LeaveOperationType.Approve.id
    ) {
      if ((document.getElementById('approvedaysafter') as any).value > 0) {
        if (
          this.props.leavedata.operationTypeId !==
          this._structure.LeaveOperationType.Approve.id
        ) {
          this.props.leavedata.operationTypeId =
            this._structure.LeaveOperationType.Approve.id;
          this.props.leavedata.operationDaysAfter = Number(
            (document.getElementById('approvedaysafter') as any).value
          );
          blnchange = true;
        }
      }
    }

    if (
      document.getElementById('radReject') &&
      (document.getElementById('radReject') as any).checked == true &&
      (document.getElementById('radReject') as any).value ==
        this._structure.LeaveOperationType.Reject.id
    ) {
      if ((document.getElementById('approvedaysafter') as any).value > 0) {
        if (
          this.props.leavedata.operationTypeId !==
          this._structure.LeaveOperationType.Approve.id
        ) {
          this.props.leavedata.operationTypeId =
            this._structure.LeaveOperationType.Approve.id;
          this.props.leavedata.operationDaysAfter = Number(
            (document.getElementById('rejectdaysafter') as any).value
          );
          blnchange = true;
        }
      }
    }

    if (
      document.getElementById('mindays') &&
      (document.getElementById('mindays') as any).value > 0
    ) {
      if (
        this.props.leavedata.minDays !==
        Number((document.getElementById('mindays') as any).value)
      ) {
        this.props.leavedata.minDays = Number(
          (document.getElementById('mindays') as any).value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('maxdays') &&
      (document.getElementById('maxdays') as any).value > 0
    ) {
      if (
        this.props.leavedata.maxDays !==
        Number((document.getElementById('maxdays') as any).value)
      ) {
        this.props.leavedata.maxDays = Number(
          (document.getElementById('maxdays') as any).value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('maxdayslvperiod') &&
      (document.getElementById('maxdayslvperiod') as any).value > 0
    ) {
      if (
        this.props.leavedata.maxDaysLeavePeriod !==
        Number((document.getElementById('maxdayslvperiod') as any).value)
      ) {
        this.props.leavedata.maxDaysLeavePeriod = Number(
          (document.getElementById('maxdayslvperiod') as any).value
        );
        blnchange = true;
      }
    }

    if (
      document.getElementById('radExceedWithoutLimit') &&
      (document.getElementById('radExceedWithoutLimit') as any).checked == true
    ) {
      if (
        this.props.leavedata.accrualSettingId !==
        this._structure.AccrualSetting.AllowNoLimit.id
      ) {
        this.props.leavedata.accrualSettingId =
          this._structure.AccrualSetting.AllowNoLimit.id;
        blnchange = true;
      }
    } else if (
      document.getElementById('radExceedNoPay') &&
      (document.getElementById('radExceedNoPay') as any).checked == true
    ) {
      if (
        this.props.leavedata.accrualSettingId !==
        this._structure.AccrualSetting.AllowNoPay.id
      ) {
        this.props.leavedata.accrualSettingId =
          this._structure.AccrualSetting.AllowNoPay.id;
        blnchange = true;
      }
    } else if (
      document.getElementById('radNotExceedBalance') &&
      (document.getElementById('radNotExceedBalance') as any).checked == true
    ) {
      if (
        this.props.leavedata.accrualSettingId !==
        this._structure.AccrualSetting.NotAllowBalance.id
      ) {
        this.props.leavedata.accrualSettingId =
          this._structure.AccrualSetting.NotAllowBalance.id;
        blnchange = true;
      }
    } else if (
      document.getElementById('radNotExceedEntitlement') &&
      (document.getElementById('radNotExceedEntitlement') as any).checked ==
        true
    ) {
      if (
        this.props.leavedata.accrualSettingId !==
        this._structure.AccrualSetting.NotAllowEntitlement.id
      ) {
        this.props.leavedata.accrualSettingId =
          this._structure.AccrualSetting.NotAllowEntitlement.id;
        blnchange = true;
      }
    }
    // Rules -- END

    if (blnchange == true) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/update`,
        this.props.leavedata,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          this.props.refreshLeavedata(response);

          let sidebar: any = JSON.parse(Common.getItem('sidebar')!);
          let s: any = sidebar.dynamicMenu.filter(
            (x: any) => x.id === 'correspondencemenu'
          );
          if (s.length > 0) {
            let sm: any = s[0].subMenuJsonList.filter(
              (x: any) => x.name === 'Leave'
            );
            s = s[0].dynamicNode.dynamicSelector.dynamicContextMenu;
            s = s.filter((x: any) => x.controlType === 2);
            if (s.length > 0) {
              s = s[0].subMenuJsonList.filter((x: any) => x.name === 'Leave');
              s = s[0].subMenuJsonList;
              let req: any = s[0].subMenuJsonList.filter(
                (x: any) => x.leaveTypesId === this.props.leavedata.id
              );
              req[0].name = this.props.leavedata.name;
              let auth: any = s[1].subMenuJsonList.filter(
                (x: any) => x.leaveTypesId === this.props.leavedata.id
              );
              auth[0].name = this.props.leavedata.name;

              sm = sm[0].subMenuJsonList;
              let reqsm: any = sm[0].subMenuJsonList.filter(
                (x: any) => x.leaveTypesId === this.props.leavedata.id
              );
              reqsm[0].name = this.props.leavedata.name;
              let authsm: any = sm[1].subMenuJsonList.filter(
                (x: any) => x.leaveTypesId === this.props.leavedata.id
              );
              authsm[0].name = this.props.leavedata.name;

              let t: any = sidebar.dynamicMenu.filter(
                (x: any) => x.id === 'templatemenu'
              );
              if (t.length > 0) {
                t = t[0].dynamicNode.dynamicSelector.dynamicContextMenu;
                t = t.filter((x: any) => x.controlType === 2);
                if (t.length > 0) {
                  t = t[0].subMenuJsonList.filter(
                    (x: any) => x.name === 'Leave'
                  );
                  t = t[0].subMenuJsonList;
                  let reqt: any = t[0].subMenuJsonList.filter(
                    (x: any) => x.leaveTypesId === this.props.leavedata.id
                  );
                  reqt[0].name = this.props.leavedata.name;
                  let autht: any = t[1].subMenuJsonList.filter(
                    (x: any) => x.leaveTypesId === this.props.leavedata.id
                  );
                  autht[0].name = this.props.leavedata.name;
                }
              }

              Common.setItem('sidebar', sidebar);
              this.props.handleRefreshSidebar(true);
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
            // });
          }
        })
        .finally(() => {});
    } else {
      this.props.refreshLeavedata(null);
    }
  };

  EntitlementContent = () => {
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-2 py-2'>
            <label>Effective After</label>
          </div>
          <div className='col-2 py-2'>
            <NumericTextBoxComponent
              id='effectiveDaysAfter'
              name='effectiveDaysAfter'
              decimals={0}
              max={366}
              min={0}
              value={this.props.leavedata.effectiveDaysAfter!}
              //onChange={this.onchangeevent.bind(this)}
            />
          </div>
          <div className='col-8 py-2'>
            <label>Day(s) from Date of Joining</label>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <CheckBoxComponent
              id='chkAccruedLeave'
              name='chkAccruedLeave'
              className='ck_chkaccruedleave'
              label={'Accrued Leave'}
              cssClass='larger'
              checked={this.props.leavedata.isAccrued!}
              onChange={this.checkevent.bind(this)}
            />
          </div>
        </div>
        <div
          id='divaccrgrp'
          className={
            this.props.leavedata.isAccrued! == false ? 'disablediv' : ''
          }
        >
          <div className='row align-items-center'>
            <div className='col-1 py-2'></div>
            <div className='col-10 py-2'>
              <div className='row align-items-center'>
                <div className='col-2 py-2'>
                  <RadioButtonComponent
                    id='radMonthly'
                    name='radAccrual'
                    value={this._structure.AccrualType.Monthly.id}
                    label='Monthly'
                    onChange={this.radcheckevent.bind(this)}
                    checked={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Monthly.id
                        ? true
                        : false
                    }
                  />
                </div>
                <div className='col-2 py-2'>
                  <NumericTextBoxComponent
                    id='monthlydays'
                    name='monthlydays'
                    decimals={2}
                    max={100}
                    min={0}
                    value={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Monthly.id
                        ? this.props.leavedata.accruedValue!
                        : 0
                    }
                    //onChange={this.onchangeevent.bind(this)}
                  />
                </div>
                <div className='col-2 py-2'>
                  <label>Day(s) On</label>
                </div>
                <div className='col-2 py-2'>
                  <DropDownListComponent
                    id='mDayList'
                    dataSource={this.days}
                    filterBarPlaceholder='Select Day'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'name' }}
                    placeholder='Select Day'
                    popupHeight='220px'
                    value={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Monthly.id
                        ? this.props.leavedata.accruedStartDay!
                        : 0
                    }
                    //onChange={this.onchangeevent.bind(this)}
                  ></DropDownListComponent>
                </div>
                <div className='col-2 py-2'>
                  <label>Of the month</label>
                </div>
              </div>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-1'></div>
            <div className='col-10'>
              <div className='row align-items-center'>
                <div className='col-2'>
                  <RadioButtonComponent
                    id='radYearly'
                    name='radAccrual'
                    value={this._structure.AccrualType.Yearly.id}
                    label='Yearly'
                    onChange={this.radcheckevent.bind(this)}
                    checked={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Yearly.id
                        ? true
                        : false
                    }
                  />
                </div>
                <div className='col-2'>
                  <NumericTextBoxComponent
                    id='yearlydays'
                    name='yearlydays'
                    decimals={2}
                    max={100}
                    min={0}
                    value={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Yearly.id
                        ? this.props.leavedata.accruedValue!
                        : 0
                    }
                    //onChange={this.onchangeevent.bind(this)}
                  />
                </div>
                <div className='col-2'>
                  <label>Day(s) On</label>
                </div>
                <div className='col-2'>
                  <DropDownListComponent
                    id='yDayList'
                    dataSource={this.days}
                    filterBarPlaceholder='Select Day'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'name' }}
                    placeholder='Select Day'
                    popupHeight='220px'
                    value={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Yearly.id
                        ? this.props.leavedata.accruedStartDay!
                        : undefined
                    }
                    //onChange={this.onchangeevent.bind(this)}
                  ></DropDownListComponent>
                </div>
                <div className='col-1'>
                  <label>Of</label>
                </div>
                <div className='col-2'>
                  <DropDownListComponent
                    id='yMonthList'
                    dataSource={this.months}
                    filterBarPlaceholder='Select Month'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'name' }}
                    placeholder='Select Month'
                    popupHeight='220px'
                    popupWidth={'220px'}
                    value={
                      this.props.leavedata.accrualTypeId ==
                      this._structure.AccrualType.Yearly.id
                        ? this.props.leavedata.accruedStartMonth!
                        : undefined
                    }
                    //onChange={this.onchangeevent.bind(this)}
                  ></DropDownListComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-2 py-2'>
            <label>Deduct From Leave</label>
          </div>
          <div className='col-5 py-2'>
            <DropDownListComponent
              id='leaveid'
              dataSource={this.deductleavetypes}
              filterBarPlaceholder='Search a Leave'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Leave'
              popupHeight='220px'
              value={this.props.leavedata.deductFromLeaveId!}
            ></DropDownListComponent>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <CheckBoxComponent
              id='chkConsecutiveDays'
              name='chkConsecutiveDays'
              className='ck_chkconsecutivedays'
              label={'Consecutive Days'}
              cssClass='larger'
              checked={this.props.leavedata.isConsecutive!}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-2 py-2'>
            <CheckBoxComponent
              id='chkResetYearly'
              name='chkResetYearly'
              className='ck_chkresetyearly'
              label={'Reset yearly on'}
              cssClass='larger'
              checked={this.props.leavedata.resetDay! > 0 ? true : false}
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-10 py-2'>
            <div
              id='resetyearly'
              className={
                this.props.leavedata.resetDay! <= 0 ? 'disablediv' : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2'>
                <DropDownListComponent
                  id='yResetDayList'
                  dataSource={this.days}
                  filterBarPlaceholder='Day'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Day'
                  popupHeight='220px'
                  value={this.props.leavedata.resetDay!}
                ></DropDownListComponent>
              </div>
              <div className='col-1 py-2' style={{ marginLeft: '10px' }}>
                <label>Of</label>
              </div>
              <div className='col-2'>
                <DropDownListComponent
                  id='yResetMonthList'
                  dataSource={this.months}
                  filterBarPlaceholder='Month'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Month'
                  popupHeight='220px'
                  popupWidth={'220px'}
                  value={this.props.leavedata.resetMonth!}
                ></DropDownListComponent>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-2 py-2'>
            <CheckBoxComponent
              id='chkexpirydays'
              name='chkexpirydays'
              className='ck_chkexpirydays'
              label={'Expires in'}
              cssClass='larger'
              checked={this.props.leavedata.expiryDays! > 0 ? true : false}
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-10 py-2'>
            <div
              id='expirydays'
              className={
                this.props.leavedata.expiryDays! <= 0 ? 'disablediv' : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2'>
                <DropDownListComponent
                  id='yexpiredDayList'
                  dataSource={this.edays}
                  filterBarPlaceholder='Day'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Day'
                  popupHeight='220px'
                  value={this.props.leavedata.expiryDays!}
                ></DropDownListComponent>
              </div>
              <div className='col-1 py-2' style={{ marginLeft: '10px' }}>
                <label>Day(s)</label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  EligibilityContent = () => {
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-1 py-2'>
            <label>Gender</label>
          </div>
          <div className='col-4 py-2'>
            <DropDownListComponent
              id='genderid'
              dataSource={this.user.commondata.filter(
                (g: any) => g.itemType == this._structure.CommonDataItems.Gender
              )}
              filterBarPlaceholder='Search Gender'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'itemValue' }}
              placeholder='Select Gender'
              popupHeight='220px'
              value={this.props.leavedata.genderId!}
            ></DropDownListComponent>
          </div>
        </div>
      </>
    );
  };

  RulesContent = () => {
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class py-2'>
            <CheckBoxComponent
              id='chkIssueOnHoliday'
              name='chkIssueOnHoliday'
              className='ck_chkissueonholiday'
              label={'Count Holiday as Leave'}
              cssClass='larger'
              checked={this.props.leavedata.issueOnHoliday!}
            />
          </div>
          <div className='col-4 tblleave-class py-2'>
            <CheckBoxComponent
              id='chkIssueOnWeekend'
              name='chkIssueOnWeekend'
              className='ck_chkissueonweekend'
              label={'Count weekend as Leave'}
              cssClass='larger'
              checked={this.props.leavedata.issueOnWeekend!}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class-min py-2'>
            <CheckBoxComponent
              id='chkallowbackdate'
              name='chkallowbackdate'
              className='ck_chkallowbackdate'
              label={'Allow to backdate leave'}
              cssClass='larger'
              checked={this.props.leavedata.isBackDateLeaveAllowed!}
            />
          </div>
          <div className='col-md-2  py-2'>
            <CheckBoxComponent
              id='chknoticedays'
              name='chknoticedays'
              className='ck_chknoticedays'
              label={'Notice'}
              cssClass='larger'
              checked={this.props.leavedata.noticeDays! > 0 ? true : false}
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-md-6 col-sm-3'>
            <div
              id='dvnoticedays'
              className={
                this.props.leavedata.noticeDays! <= 0 ? 'disablediv' : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2'>
                <NumericTextBoxComponent
                  id='noticedayvalue'
                  name='noticedayvalue'
                  decimals={0}
                  max={100}
                  min={0}
                  value={this.props.leavedata.noticeDays!}
                />
              </div>
              <div className='col-1 py-2' style={{ marginLeft: '10px' }}>
                <label>Day(s)</label>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class py-2'>
            <CheckBoxComponent
              id='chkSkipapproval'
              name='chkSkipapproval'
              className='ck_chkskipapproval'
              label={'Skip approval'}
              cssClass='larger'
              checked={this.props.leavedata.isApprovalSkipped!}
            />
          </div>
          <div className='col-2 tblleave-class py-2'>
            <CheckBoxComponent
              id='chkRelieverrequired'
              name='chkRelieverrequired'
              className='ck_chkrelieverrequired'
              label={'Reliever required'}
              cssClass='larger'
              checked={this.props.leavedata.isRelieverNeeded!}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class py-2'>
            <CheckBoxComponent
              id='chkdocrequired'
              name='chkdocrequired'
              className='ck_chkdocrequired'
              label={'Supporting doc required if leave exceeds'}
              cssClass='larger'
              checked={
                this.props.leavedata.leaveExceedDaysRequireAttachment! > 0
                  ? true
                  : false
              }
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-6 py-2'>
            <div
              id='dvlvdocdays'
              className={
                this.props.leavedata.leaveExceedDaysRequireAttachment! <= 0
                  ? 'disablediv'
                  : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2 tblleave-class'>
                <NumericTextBoxComponent
                  id='attchdocdays'
                  name='attchdocdays'
                  decimals={0}
                  max={100}
                  min={0}
                  value={this.props.leavedata.leaveExceedDaysRequireAttachment!}
                />
              </div>
              <div
                className='col-1 tblleave-class py-2'
                style={{ marginLeft: '10px' }}
              >
                <label>Day(s)</label>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class py-2'>
            <RadioButtonComponent
              id='radApprove'
              name='radOperationType'
              value={this._structure.LeaveOperationType.Approve.id}
              label='Auto Approve'
              checked={
                this.props.leavedata.operationTypeId ==
                this._structure.LeaveOperationType.Approve.id
                  ? true
                  : false
              }
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-6 tblleave-class'>
            <div
              id='dvAutoApprove'
              className={
                this.props.leavedata.operationTypeId !==
                this._structure.LeaveOperationType.Approve.id
                  ? 'disablediv'
                  : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2 tblleave-class'>
                <NumericTextBoxComponent
                  id='approvedaysafter'
                  name='approvedaysafter'
                  decimals={0}
                  max={100}
                  min={0}
                  value={this.props.leavedata.operationDaysAfter!}
                />
              </div>
              <div className='col-1 py-2' style={{ marginLeft: '10px' }}>
                <label>Day(s)</label>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class py-2'>
            <RadioButtonComponent
              id='radReject'
              name='radOperationType'
              value={this._structure.LeaveOperationType.Reject.id}
              label='Auto Reject'
              checked={
                this.props.leavedata.operationTypeId ==
                this._structure.LeaveOperationType.Reject.id
                  ? true
                  : false
              }
              onChange={this.checkevent.bind(this)}
            />
          </div>
          <div className='col-6 tblleave-class'>
            <div
              id='dvAutoReject'
              className={
                this.props.leavedata.operationTypeId !==
                this._structure.LeaveOperationType.Reject.id
                  ? 'disablediv'
                  : ''
              }
              style={{ display: 'flex' }}
            >
              <div className='col-2 tblleave-class'>
                <NumericTextBoxComponent
                  id='rejectdaysafter'
                  name='rejectdaysafter'
                  decimals={0}
                  max={100}
                  min={0}
                  value={this.props.leavedata.operationDaysAfter!}
                />
              </div>
              <div className='col-1 py-2' style={{ marginLeft: '10px' }}>
                <label>Day(s)</label>
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class2 py-2'>
            <label>Minimum Day(s) per application</label>
          </div>
          <div className='col-2 py-2'>
            <NumericTextBoxComponent
              id='mindays'
              name='mindays'
              decimals={0}
              max={100}
              min={0}
              value={this.props.leavedata.minDays!}
            />
          </div>
          <div className='col-4 tblleave-class2 py-2'>
            <label>Maximum Day(s) per application</label>
          </div>
          <div className='col-2 py-2'>
            <NumericTextBoxComponent
              id='maxdays'
              name='maxdays'
              decimals={0}
              max={100}
              min={0}
              value={this.props.leavedata.maxDays!}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-4 tblleave-class2 py-2'>
            <label>Maximum Day(s) limit in a Leave period</label>
          </div>
          <div className='col-2 py-2'>
            <NumericTextBoxComponent
              id='maxdayslvperiod'
              name='maxdayslvperiod'
              decimals={0}
              max={100}
              min={0}
              value={this.props.leavedata.maxDaysLeavePeriod!}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-8 py-2'>
            <RadioButtonComponent
              id='radExceedWithoutLimit'
              name='radAccrualSetting'
              label='Allow to Exceed leave balance without limit'
              value={this._structure.AccrualSetting.AllowNoLimit.id}
              checked={
                this.props.leavedata.accrualSettingId ==
                this._structure.AccrualSetting.AllowNoLimit.id
                  ? true
                  : false
              }
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-8 py-2'>
            <RadioButtonComponent
              id='radExceedNoPay'
              name='radAccrualSetting'
              label='Allow to Exceed leave balance with no pay'
              value={this._structure.AccrualSetting.AllowNoPay.id}
              checked={
                this.props.leavedata.accrualSettingId ==
                this._structure.AccrualSetting.AllowNoPay.id
                  ? true
                  : false
              }
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-8 py-2'>
            <RadioButtonComponent
              id='radNotExceedBalance'
              name='radAccrualSetting'
              label={"Don't Allow to Exceed Leave Balance"}
              value={this._structure.AccrualSetting.NotAllowBalance.id}
              checked={
                this.props.leavedata.accrualSettingId ==
                this._structure.AccrualSetting.NotAllowBalance.id
                  ? true
                  : false
              }
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-8 py-2'>
            <RadioButtonComponent
              id='radNotExceedEntitlement'
              name='radAccrualSetting'
              label={"Don't Allow to Exceed Entitlement"}
              value={this._structure.AccrualSetting.NotAllowEntitlement.id}
              checked={
                this.props.leavedata.accrualSettingId ==
                this._structure.AccrualSetting.NotAllowEntitlement.id
                  ? true
                  : false
              }
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-1 py-2'>
            <label>Code </label>
          </div>
          <div className='col-2 py-2'>
            <input
              className='input-text-border'
              id='txtCode'
              name='txtCode'
              type='text'
              placeholder='Enter Code'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
              defaultValue={this.props.leavedata.code!}
            />
          </div>
          <div className='col-1 py-2'>
            <label>Name </label>
          </div>
          <div className='col-4 py-2'>
            <input
              className='input-text-border'
              id='txtName'
              name='txtName'
              type='text'
              placeholder='Enter Name'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
              defaultValue={this.props.leavedata.name!}
            />
          </div>
          <div className='col-2 py-2'>
            <ColorPickerComponent
              id='colorpicker'
              name='colorpicker'
              className='ck_color'
              showButtons={false}
              value={this.props.leavedata.color!}
              change={this.oncolorchange.bind(this)}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-1 py-2'>
            <label>Type</label>
          </div>
          <div className='col-11 py-2'>
            <CheckBoxComponent
              id='chkPaidLeave'
              name='chkPaidLeave'
              className='ck_chkpaidleave'
              label={'Paid'}
              checked={this.props.leavedata.isPaid!}
              cssClass='larger'
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-1 py-2'>
            <label>Unit</label>
          </div>
          <div className='col-3 py-2'>
            <RadioButtonComponent
              id='radFullDays'
              name='radUnits'
              value={this._structure.Unit.FullDay.id}
              label='Full Days'
              checked={
                this.props.leavedata.unitId == this._structure.Unit.FullDay.id
                  ? true
                  : false
              }
            />
          </div>
          <div className='col-3 py-2'>
            <RadioButtonComponent
              id='radFlexHours'
              name='radUnits'
              value={this._structure.Unit.FlexHours.id}
              label='Flex Hours'
              checked={
                this.props.leavedata.unitId == this._structure.Unit.FlexHours.id
                  ? true
                  : false
              }
            />
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-12'>
            <div className='control-pane'>
              <div className='control-section tab-control-section'>
                <TabComponent
                  id='tableaveSettings'
                  heightAdjustMode='Auto'
                  enablePersistence={true}
                >
                  <TabItemsDirective>
                    <TabItemDirective
                      headerText={'Entitlement'}
                      content={() => this.EntitlementContent()}
                    />
                    <TabItemDirective
                      headerText={'Eligibility'}
                      content={() => this.EligibilityContent()}
                    />
                    <TabItemDirective
                      headerText={'Rules'}
                      content={() => this.RulesContent()}
                    />
                  </TabItemsDirective>
                </TabComponent>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
