// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-23 - 10
// * SOURAV - 2023-11-23 - 30
// * SOURAV - 2023-11-29 - 70
import React, { useContext } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useState, useEffect } from 'react';
import Login from '../Components/Login/Login';
import Composer from '../Components/Composer';
//import Register from './Register'
//import Login from './LoginCO';

import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
//import { googleLogout, GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';

// import {
//   LoginContext,
//   loginReducer,
//   LoginState,
//   useLoginContext,
// } from '../Components/Login/LoginReducer';
import { AppContext } from '../Components/Login/AppState';
// import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Common from '../Util/Common';
import Regwizard from '../Components/Login/GSignup/Regwizard';
import Main from '../Components/Login/Signup/Main';
import Splash from '../Components/Login/Splash';
import axios from 'axios';
import ForgotPassword from '../Components/Login/ForgotPassword/ForgotPassword';
import '../Components/Login/Login.css';

// * ----------------------------------------------------------------------------------------------------------------- HTML
// * SUHAIL - 2023-02-23 - 30
const {
  REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
  REACT_APP_VERSION,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_USERSERVICE,
  REACT_APP_ENDPOINT_TOKENS,
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_FRONTENDSERVICE_PORT,
} = process.env;

export default function LoginOuter(props: any) {
  const [res, setRes] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [credit, setCredit] = useState({ name: '', email: '', aud: '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [user, setUser] = useState(null);
  const [forgotpassword, setForgotPassword] = useState(false);
  const [showDialogName, setShowDialogName] = useState('');
  const [showDialogFooter, setShowDialogFooter] = useState(true);
  const [dialogHeader, setDialogHeader] = useState('');
  const [dialogWidth, setDialogWidth] = useState('');
  const navigate = useNavigate();
  let leftBottomImage = './images/Signature.svg';
  //const { state, dispatch } = useLoginContext();
  const { state, dispatch } = useContext(AppContext);
  let { username, password, isLoading, error, isLoggedIn } = state.login;
  var intrvl: any;
  var splashInterval = 0;
  var maxInterval = 4;
  const handleClick = () => {
    setDialog(true);
  };

  const handleLoginGoogle = (e: any) => {
    //dispatch({ type: 'login' });

    intrvl = setInterval(() => {
      splashInterval += 1;
    }, 1000);

    //login();
    const clientId =
      '390741107342-q8786pnumig8ed0c8uir3p1o9acvnr1l.apps.googleusercontent.com';
    const redirectUri = `${REACT_APP_ENDPOINT_API}${REACT_APP_ENDPOINT_FRONTENDSERVICE_PORT}callbackgooglelogin`; // The URI you set in Google Developer Console
    const responseType = 'code';
    const scope =
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

    const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${encodeURIComponent(
      scope
    )}&prompt=consent`;

    const width = window.innerWidth * 0.98;
    const height = window.innerHeight * 0.98;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popup = window.open(
      oauthUrl,
      'google-login',
      `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`
    );

    if (popup) {
      // const timer = setInterval(() => {
      //   if (popup.closed) {
      //     clearInterval(timer);
      //     console.log('Popup closed');
      //   }
      // }, 500);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      let url: string = `${REACT_APP_ENDPOINT_API}`;
      let port: string = `${REACT_APP_ENDPOINT_FRONTENDSERVICE_PORT}`;
      if (port) {
        port = port.slice(0, -1);
      } else {
        url = url.slice(0, -1);
      }

      if (event.origin !== `${url}${port}`) return;

      if (event.data.type === 'oauth-code') {
        const { code } = event.data;

        handleGoogleLogin(code);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleGoogleLogin = (auth_code: string) => {
    Common.ApiCallAsync(
      'post',
      `${REACT_APP_ENDPOINT_USERSERVICE}/googleauthcodeflow?code=${auth_code}`,
      {},
      Common.getToken() || '',
      null
    )
      .then((response: any) => {
        dispatch({ type: 'login' });

        let _tenant: any = response.data.tenant;
        if (_tenant == '') {
          _tenant = 'root';
        }
        if (!_tenant) {
          //reject('Email not registered');
          dispatch({ type: 'error' });
          setErrorMsg(
            'Sorry, we could not identify you using the information you provided. Please contact your HR.'
          );
          return;
        }

        let requestdata: any = {
          email: response.data.email,
          //password: tokenResponse.code,
          password: response.data.idToken,
        };

        Common.ApiCallAsync(
          'post',
          '' + REACT_APP_ENDPOINT_TOKENS + '/googleauthcodeflow',
          requestdata,
          '',
          null,
          _tenant
        )
          .then((response: any) => {
            let token: string = response.data.token;
            let refreshtoken: string = response.data.refreshToken;
            Common.ApiCallAsync(
              'post',
              `${REACT_APP_ENDPOINT_USERSERVICE}/getuser`,
              requestdata,
              token,
              null,
              _tenant
            )
              .then((response: any) => {
                let user: any = response.data;
                Common.setItem('token', token);
                Common.setItem('refreshtoken', refreshtoken);
                Common.setItem('user', user);
                console.log('frontend-version', REACT_APP_VERSION);
                console.log('headers', response.headers);
                console.log(
                  'backend-version',
                  response.headers['x-custom-header-frontendversion']
                );

                if (splashInterval < maxInterval) {
                  setTimeout(() => {
                    clearInterval(intrvl);

                    //resolve();
                    dispatch({ type: 'success' });
                    navigate('/');
                  }, (maxInterval - splashInterval) * 1000);
                } else {
                  clearInterval(intrvl);

                  //resolve();
                  dispatch({ type: 'success' });
                  navigate('/');
                }
              })
              .catch((error: any) => {
                console.error('error in getting fulljson');
                //reject(error);
              });
          })
          .catch((error: any) => {
            console.error('error in getting structure');
            clearInterval(intrvl);
            //reject(error);
            dispatch({ type: 'error' });
          });
        //resolve();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  };

  const handleForgotPassword = (e: any) => {
    setForgotPassword(true);
    setRes(false);
    setDialog(false);
    setShowDialogName('forgotpassword');
    setDialogHeader('Forgot Password');
    setDialogWidth('');

    intrvl = setInterval(() => {
      splashInterval += 1;
    }, 1000);
  };

  const handleDataFromForgot = (forgotData: string) => {
    if (forgotData === 'success') {
      setForgotPassword(false);
    }
  };

  // const responseGoogle = (response: any) => {
  //   if (response.error) {
  //     console.log(response.error);
  //   } else {
  //     // setRes(true);
  //     try {
  //       const credential = response.credential;
  //       let cred = Common.parseJwt(credential);
  //       setCredit(cred);
  //       let requestdata: any = {
  //         name: credit.email,
  //         password: credit.aud,
  //       };
  //       Common.ApiCallAsync(
  //         'post',
  //         '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  //         requestdata
  //       )
  //         .then((response: any) => {
  //           let user: any = response.data;
  //           let token: string = user.token;
  //           Common.ApiCallAsync(
  //             'get',
  //             '' + REACT_APP_ENDPOINT_XMLPATH,
  //             '',
  //             token,
  //             user
  //           )
  //             .then((response: any) => {
  //               let structure: any = response.data;
  //               Common.ApiCallAsync(
  //                 'get',
  //                 '' + REACT_APP_ENDPOINT_FULLJSONPATH,
  //                 '',
  //                 token,
  //                 user
  //               )
  //                 .then((response: any) => {
  //                   let fulljson: any = response.data;
  //                   Common.setUserSession(token, user, structure, fulljson);

  //                   if (
  //                     REACT_APP_VERSION !==
  //                     response.headers['x-custom-header-frontendversion']
  //                   ) {
  //                     Common.setItem('version-update-needed', true);
  //                   }
  //                   if (splashInterval < maxInterval) {
  //                     setTimeout(() => {
  //                       setErrorMsg(true);
  //                       navigate('/');

  //                       clearInterval(intrvl);
  //                     }, (maxInterval - splashInterval) * 1000);
  //                   } else {
  //                     setErrorMsg(true);
  //                     navigate('/');
  //                     clearInterval(intrvl);
  //                   }
  //                 })
  //                 .catch((error: any) => {
  //                   console.error('error in getting fulljson');
  //                   setErrorMsg(true);
  //                   // googleLogout();
  //                 });
  //             })
  //             .catch((error: any) => {
  //               console.error('error in getting structure');
  //               setErrorMsg(true);
  //               clearInterval(intrvl);
  //             });
  //         })
  //         .catch((error: any) => {
  //           console.error('error in getting user');
  //           setErrorMsg(true);
  //           clearInterval(intrvl);
  //         });
  //     } catch (error) {
  //       console.log('Error decoding token:', error);
  //     }
  //   }
  // };
  //new customise google login function ---------------
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      try {
        Common.ApiCallAsync(
          'post',
          `${REACT_APP_ENDPOINT_USERSERVICE}/googleauthcodeflow?code=${tokenResponse.code}`,
          {},
          Common.getToken() || '',
          null
        )
          .then((response: any) => {
            dispatch({ type: 'login' });

            let _tenant: any = response.data.tenant;
            if (_tenant == '') {
              _tenant = 'root';
            }

            if (!_tenant) {
              //reject('Email not registered');
              dispatch({ type: 'error' });
              setErrorMsg(
                'Sorry, we could not identify you using the information you provided. Please contact your HR.'
              );
              return;
            }

            let requestdata: any = {
              email: response.data.email,
              //password: tokenResponse.code,
              password: response.data.idToken,
            };

            Common.ApiCallAsync(
              'post',
              '' + REACT_APP_ENDPOINT_TOKENS + '/googleauthcodeflow',
              requestdata,
              '',
              null,
              _tenant
            )
              .then((response: any) => {
                let token: string = response.data.token;
                let refreshtoken: string = response.data.refreshToken;
                Common.ApiCallAsync(
                  'post',
                  `${REACT_APP_ENDPOINT_USERSERVICE}/getuser`,
                  requestdata,
                  token,
                  null,
                  _tenant
                )
                  .then((response: any) => {
                    let user: any = response.data;
                    Common.setItem('token', token);
                    Common.setItem('refreshtoken', refreshtoken);
                    Common.setItem('user', user);
                    console.log('frontend-version', REACT_APP_VERSION);
                    console.log('headers', response.headers);
                    console.log(
                      'backend-version',
                      response.headers['x-custom-header-frontendversion']
                    );

                    if (splashInterval < maxInterval) {
                      setTimeout(() => {
                        clearInterval(intrvl);

                        //resolve();
                        dispatch({ type: 'success' });
                        navigate('/');
                      }, (maxInterval - splashInterval) * 1000);
                    } else {
                      clearInterval(intrvl);

                      //resolve();
                      dispatch({ type: 'success' });
                      navigate('/');
                    }
                  })
                  .catch((error: any) => {
                    console.error('error in getting fulljson');
                    //reject(error);
                  });
              })
              .catch((error: any) => {
                console.error('error in getting structure');
                clearInterval(intrvl);
                //reject(error);
                dispatch({ type: 'error' });
              });
            //resolve();
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});

        // const res = await axios
        //   .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
        //     headers: {
        //       Authorization: `Bearer ${tokenResponse.access_token}`,
        //       Accept: 'application/json',
        //     },
        //   })
        //   .then((res) => {
        //     setUser(res.data);
        //     let requestdata: any = {
        //       name: res.data.email,
        //       password: res.data.id,
        //     };

        //     Common.ApiCallAsync(
        //       'post',
        //       `${REACT_APP_ENDPOINT_USERSERVICE}/googleauth?code=${tokenResponse.access_token}`,
        //       {},
        //       Common.getToken() || '',
        //       null
        //     )
        //       .then((response: any) => {
        //         console.log(response);
        //       })
        //       .catch((error: any) => {
        //         console.error(error);
        //       })
        //       .finally(() => {});

        //     Common.ApiCallAsync(
        //       'post',
        //       '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
        //       requestdata
        //     )
        //       .then((response: any) => {
        //         let user: any = response.data;
        //         let token: string = user.token;
        //         Common.ApiCallAsync(
        //           'get',
        //           '' + REACT_APP_ENDPOINT_XMLPATH,
        //           '',
        //           token,
        //           user
        //         )
        //           .then((response: any) => {
        //             let structure: any = response.data;
        //             Common.ApiCallAsync(
        //               'get',
        //               '' + REACT_APP_ENDPOINT_FULLJSONPATH,
        //               '',
        //               token,
        //               user
        //             )
        //               .then((response: any) => {
        //                 let fulljson: any = response.data;
        //                 Common.setUserSession(token, user, structure, fulljson);

        //                 if (
        //                   REACT_APP_VERSION !==
        //                   response.headers['x-custom-header-frontendversion']
        //                 ) {
        //                   Common.setItem('version-update-needed', true);
        //                 }
        //                 if (splashInterval < maxInterval) {
        //                   setTimeout(() => {
        //                     clearInterval(intrvl);
        //                     setErrorMsg(true);
        //                     dispatch({ type: 'success' });
        //                     navigate('/');
        //                   }, (maxInterval - splashInterval) * 1000);
        //                 } else {
        //                   clearInterval(intrvl);
        //                   setErrorMsg(true);
        //                   dispatch({ type: 'success' });
        //                   navigate('/');
        //                 }
        //               })
        //               .catch((error: any) => {
        //                 // dispatch({ type: 'login' });
        //                 setErrorMsg(true);
        //                 clearInterval(intrvl);
        //                 dispatch({ type: 'error' });
        //                 // googleLogout();
        //                 console.error('error in getting fulljson');
        //               });
        //           })
        //           .catch((error: any) => {
        //             setErrorMsg(true);
        //             clearInterval(intrvl);
        //             dispatch({ type: 'error' });
        //             console.error('error in getting structure');
        //           });
        //       })
        //       .catch((error: any) => {
        //         setErrorMsg(true);
        //         clearInterval(intrvl);
        //         dispatch({ type: 'error' });
        //         console.error('error in getting user');
        //       });
        //   })
        //   .catch((err: any) => {
        //     clearInterval(intrvl);
        //     dispatch({ type: 'error' });
        //     console.error(err);
        //   });
      } catch (error) {
        clearInterval(intrvl);
        dispatch({ type: 'error' });
        console.error('Error decoding token:', error);
      }
    },
    onError(errorResponse: any) {
      clearInterval(intrvl);
      dispatch({ type: 'error' });
      console.log('errorResponse', errorResponse);
    },
    onNonOAuthError(nonOAuthError: any) {
      clearInterval(intrvl);
      dispatch({ type: 'error' });
      console.log('nonOAuthError', nonOAuthError);
    },
    flow: 'auth-code',
  });
  // useEffect(() => {
  //   if (user) {
  //     try {
  //       let requestdata: any = {
  //         name: credit.email,
  //         password: credit.aud,
  //       };
  //       console.log(requestdata);
  //       Common.ApiCallAsync(
  //         'post',
  //         '' + REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  //         requestdata
  //       )
  //         .then((response: any) => {
  //           let user: any = response.data;
  //           let token: string = user.token;
  //           Common.ApiCallAsync(
  //             'get',
  //             '' + REACT_APP_ENDPOINT_XMLPATH,
  //             '',
  //             token,
  //             user
  //           )
  //             .then((response: any) => {
  //               let structure: any = response.data;
  //               Common.ApiCallAsync(
  //                 'get',
  //                 '' + REACT_APP_ENDPOINT_FULLJSONPATH,
  //                 '',
  //                 token,
  //                 user
  //               )
  //                 .then((response: any) => {
  //                   let fulljson: any = response.data;
  //                   Common.setUserSession(token, user, structure, fulljson);

  //                   if (
  //                     REACT_APP_VERSION !==
  //                     response.headers['x-custom-header-frontendversion']
  //                   ) {
  //                     Common.setItem('version-update-needed', true);
  //                   }
  //                   if (splashInterval < maxInterval) {
  //                     setTimeout(() => {
  //                       setErrorMsg(true);
  //                       navigate('/');

  //                       clearInterval(intrvl);
  //                     }, (maxInterval - splashInterval) * 1000);
  //                   } else {
  //                     setErrorMsg(true);
  //                     navigate('/');
  //                     clearInterval(intrvl);
  //                   }
  //                 })
  //                 .catch((error: any) => {
  //                   console.error('error in getting fulljson');
  //                   setErrorMsg(true);
  //                   // googleLogout();
  //                 });
  //             })
  //             .catch((error: any) => {
  //               console.error('error in getting structure');
  //               setErrorMsg(true);
  //               clearInterval(intrvl);
  //             });
  //         })
  //         .catch((error: any) => {
  //           console.error('error in getting user');
  //           setErrorMsg(true);
  //           clearInterval(intrvl);
  //         });
  //     } catch (error) {
  //       console.log('Error decoding token:', error);
  //     }
  //   }
  // }, [user]);
  const showHideModal = (p: any) => {
    setShowDialogName(p);
    //let d: any = document.getElementsByClassName('modal-backdrop fade');
    let d: any = document.querySelector('.btn-close[data-bs-dismiss="modal"]');
    if (d && !p) {
      d.click();
      // if (d.length > 0) {
      //   // d[0].classList.remove('show');
      //   // d[0].classList.add('d-none');
      //   d[0].remove();
      //   document.getElementsByTagName('body')[0].classList.remove('modal-open');
      // }
    } else {
      // if (d.length > 0) {
      //   d[0].classList.remove('d-none');
      // }
    }
  };

  return (
    <>
      {/* <div className='row App'>
        <div className='col-sm-12 offset-md-3 col-md-6 offset-lg-0 col-lg-6 leftLoginPanel'>
          <div className='row d-md-none d-lg-block '></div>
          <div className='row'>
            <div className='col-lg-4 offset-lg-4'>
              <div className='login-container'>
                {
                  <Login /> //Function Base Component
                  //<Login state={props.state} dispatch={props.dispatch} /> //Class Base Component
                }
              </div>
              <div style={{ textAlign: 'center' }}>
                Not having an account?{' '}
                <a
                  // onClick={handleClick}
                  style={{
                    color: '#0D6EFD',
                    cursor: 'pointer',
                    padding: '2px',
                  }}
                >
                  Register
                </a>
                or
              </div>
              <div>
                {' '}
                <GoogleLogin
                  text={'signin_with'}
                  onSuccess={responseGoogle}
                  //onFailure={responseGoogle}
                  //scope='profile email'
                  //responseType='code'
                  theme={'outline'}
                  logo_alignment={'center'}
                  shape={'rectangular'}
                  size={'large'}
                  // width={'260px'}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            {' '}
            <div className='col-lg-4 offset-lg-4  leftBottomImage'>
              <img src={leftBottomImage} />
            </div>
          </div>
          {/* <div className='row' style={{ border: '1px solid blue' }}>
          <div className='col-md-12 col-lg-4 offset-lg-4 my-2' id='d1'>
            <div className='row'>
              <div className=' col-md-8 '>Not having an account?</div>
              <div className='col-md-4 '>
                <a
                  // onClick={handleClick}
                  style={{
                    color: '#0D6EFD',
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                >
                  Register
                </a>
                or
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className='row' style={{ border: '1px solid gray' }}>
          <div className='col-md-12 offset-lg-4 my-3'>
            <GoogleLogin
              text={'signin_with'}
              onSuccess={responseGoogle}
              //onFailure={responseGoogle}
              //scope='profile email'
              //responseType='code'
              theme={'outline'}
              logo_alignment={'center'}
              shape={'rectangular'}
              size={'large'}
              width={'290px'}
            />
          </div>
        </div> */}

      {/* <div className='row' style={{ border: '1px solid red' }}>
          <div className='col-lg-4 offset-lg-4  leftBottomImage'>
            <img src={leftBottomImage} />
          </div>
        </div> */}
      {/* </div>
        <div className='d-none d-lg-block col-lg-6 loginright-container'></div>
      </div> */}

      {/* ---------New Code for login ---------------------------------------- */}
      {isLoading && !error ? (
        <Splash />
      ) : (
        <div id='loginContainer'>
          <div id='containerChild1'>
            <div id='containerSubChild1'>
              <div style={{ width: '100%' }}>
                <Login
                  googleErrorMsg={errorMsg}
                  showDialogs={(e: any) => setShowDialogName(e)}
                  showFooter={(e: any) => setShowDialogFooter(e)}
                  dialogHeader={(e: any) => setDialogHeader(e)}
                  dialogWidth={(e: any) => setDialogWidth(e)}
                />
              </div>
              <div id='forgot-msg'>
                {
                  /* <a type='default' className='forgot-password'>
                  {' '}
                  Forgot your password?
                </a> */
                  <div>
                    <button
                      type='button'
                      className='btn'
                      data-bs-toggle='modal'
                      data-bs-target='#exampleModal'
                      style={{
                        color: '#0d6efd',
                        fontSize: '14px',
                        outline: 'none',
                        border: 'none',
                      }}
                      onClick={handleForgotPassword}
                    >
                      Forgot your password?
                    </button>
                  </div>
                }
              </div>
              <div id='google-login-btn'>
                <div>
                  {/* <GoogleLogin
                    text={'signin_with'}
                    onSuccess={(responseGoogle) => console.log(responseGoogle)}
                    //onFailure={responseGoogle}
                    theme={'outline'}
                    logo_alignment={'center'}
                    shape={'rectangular'}
                    size={'large'}
                    width={'275'}
                    // auto_select={false}
                    //useOneTap
                  /> */}
                  <button
                    type='button'
                    onClick={handleLoginGoogle}
                    id='google-btn'
                    className=''
                  >
                    <img
                      src='../images/google-logo.png'
                      style={{
                        width: '1rem',
                        height: '1rem',
                        marginRight: '7px',
                      }}
                    />
                    Sign in with Google
                  </button>
                  <div className='d-none'>
                    <GoogleLogin
                      width={'275px'}
                      logo_alignment='center'
                      onSuccess={(credentialResponse): Promise<void> => {
                        return new Promise((resolve, reject) => {
                          Common.ApiCallAsync(
                            'post',
                            `${REACT_APP_ENDPOINT_USERSERVICE}/googleauth?code=${credentialResponse.credential}`,
                            {},
                            Common.getToken() || '',
                            null
                          )
                            .then((response: any) => {
                              dispatch({ type: 'login' });

                              let _tenant: any = response.data.tenant;
                              if (_tenant == '') {
                                _tenant = 'root';
                              }

                              if (!_tenant) {
                                //reject('Email not registered');
                                dispatch({ type: 'error' });
                                setErrorMsg(
                                  'Sorry, we could not identify you using the information you provided. Please contact your HR.'
                                );

                                return;
                              }

                              let requestdata: any = {
                                email: response.data.email,
                                password: credentialResponse.credential,
                              };

                              Common.ApiCallAsync(
                                'post',
                                '' + REACT_APP_ENDPOINT_TOKENS + '/google',
                                requestdata,
                                '',
                                null,
                                _tenant
                              )
                                .then((response: any) => {
                                  let token: string = response.data.token;
                                  let refreshtoken: string =
                                    response.data.refreshToken;
                                  Common.ApiCallAsync(
                                    'post',
                                    `${REACT_APP_ENDPOINT_USERSERVICE}/getuser`,
                                    requestdata,
                                    token,
                                    null,
                                    _tenant
                                  )
                                    .then((response: any) => {
                                      let user: any = response.data;
                                      Common.setItem('token', token);
                                      Common.setItem(
                                        'refreshtoken',
                                        refreshtoken
                                      );
                                      Common.setItem('user', user);
                                      console.log(
                                        'frontend-version',
                                        REACT_APP_VERSION
                                      );
                                      console.log('headers', response.headers);
                                      console.log(
                                        'backend-version',
                                        response.headers[
                                          'x-custom-header-frontendversion'
                                        ]
                                      );

                                      if (splashInterval < maxInterval) {
                                        setTimeout(() => {
                                          clearInterval(intrvl);

                                          resolve();
                                          dispatch({ type: 'success' });
                                          navigate('/');
                                        }, (maxInterval - splashInterval) * 1000);
                                      } else {
                                        clearInterval(intrvl);

                                        resolve();
                                        dispatch({ type: 'success' });
                                        navigate('/');
                                      }
                                    })
                                    .catch((error: any) => {
                                      console.error(
                                        'error in getting fulljson'
                                      );
                                      reject(error);
                                    });
                                })
                                .catch((error: any) => {
                                  console.error('error in getting structure');
                                  clearInterval(intrvl);
                                  reject(error);
                                  dispatch({ type: 'error' });
                                });
                              resolve();
                            })
                            .catch((error: any) => {
                              console.error(error);
                            })
                            .finally(() => {});
                        });
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                      //shape='circle'
                      size='medium'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div id='containerSubChild2'>
              <div>
                <img src={leftBottomImage} id='leftBottomImage' />
              </div>
            </div>
          </div>
          <div
            className='d-none d-lg-block col-lg-6 loginright-container'
            id='containerChild2'
          ></div>
        </div>
      )}

      {/* FOR MODAL DIALOGBOX */}

      <div>
        {/* {res ? (
          <DialogComponent
            id='signupDialog1'
            showCloseIcon={true}
            visible={res}
            width={'57vw'}
            height='57vh'
            close={() => setRes(false)}
            isModal={true}
            content={() => (
              <Regwizard name={credit.name} email={credit.email} />
            )}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null} */}

        {/* {forgotpassword ? (
          <DialogComponent
            id='forgotpasswordDialog'
            showCloseIcon={true}
            visible={forgotpassword}
            width={'26vw'}
            height='auto'
            isModal={true}
            close={() => setForgotPassword(false)}
            content={() => (
              <ForgotPassword sendDataToLogin={handleDataFromForgot} />
            )}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null} */}
      </div>

      {/* <div>
        {dialog ? (
          <DialogComponent
            id='signupDialog2'
            showCloseIcon={true}
            visible={dialog}
            width={'58vw'}
            height='57vh'
            close={() => setDialog(false)}
            isModal={true}
            content={() => <Main />}
            statelessTemplates={[]}
            //style={{ border: '2px solid black' }}
          ></DialogComponent>
        ) : null}
      </div> */}
      <div
        //className='modal fade'
        className={`modal  fade ${showDialogName ? 'show' : ''}`}
        id='exampleModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        style={{ display: showDialogName ? 'block' : 'none' }}
      >
        <div
          className='modal-dialog  modal-dialog-centered custom-width'
          style={{
            maxWidth: dialogWidth ? 'none' : '500px',
            width: dialogWidth ? dialogWidth : 'auto',
          }}
        >
          <div className='modal-content p-2' id='fixedModelHeight1'>
            <div className='modal-header custom-modal-header'>
              <h1 className='modal-title fs-5' id='exampleModal-forgotpwdLabel'>
                {dialogHeader}
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' id='fixedModelHeight'>
              <div className='modal-content p-2' id='fixedModelHeight1'>
                {showDialogName === 'signup' && (
                  <Main exitStep={() => setShowDialogName('')} />
                )}
                {showDialogName === 'forgotpassword' && (
                  <ForgotPassword
                    sendDataToLogin={handleDataFromForgot}
                    showHeaderName={(e: any) => showHideModal(e)}
                  />
                )}
              </div>
            </div>
            {showDialogFooter && (
              <div className='modal-footer custom-modal-footer'>
                <div className='nextbtn col-12'>
                  <button
                    className='flat-button fs-6'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDialogName('')}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
          {/*<div className='modal-content'>
             <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'></h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div> 
            <div className='modal-body' id='fixedModelHeight'>
              <Main exitStep={closeModal} />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary'>
                Save changes
              </button>
            </div> 
          </div>*/}
        </div>
      </div>

      {/* <div
        className={`modal  fade ${forgotpassword ? 'show' : ''}`}
        id='exampleModal-forgotpwd'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='exampleModal-forgotpwdLabel'
        role='dialog'
        aria-hidden='true'
        style={{ display: forgotpassword ? 'block' : 'none' }}
      >
        <div className='modal-dialog  modal-dialog-centered custom-width'>
          <div className='modal-content' id='fixedModelHeight1'>
            <div className='modal-header custom-modal-header'>
              <h1 className='modal-title fs-5' id='exampleModal-forgotpwdLabel'>
                Forgot Password
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' id='fixedModelHeight1'>
              {forgotpassword && (
                <ForgotPassword sendDataToLogin={handleDataFromForgot} />
              )} }
            </div>
            <div className='modal-footer custom-modal-footer'>
              <div className='nextbtn col-12'>
                <button
                  className='flat-button fs-6'
                  data-bs-dismiss='modal'
                  //onClick={exitHandler}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
