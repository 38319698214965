import * as React from 'react';
import Common from '../../Util/Common';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import LeaveTimeLine from '../YearTimeLine/LeaveTimeLine';
interface Props {
  onSave: (response: any) => void;
  onCancel: () => void;
  leaveobj: any;
}

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

export default class LeaveCancellation extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private sText: TextBoxComponent | any = undefined;
  private issuedDays: any = [];
  private cancelDays: any = [];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    years: [],
    applieddays: [],
  };


  componentDidMount(): void {
    if (this.state.years.length <= 0) {
      let year: any = [];
      if (year.filter((x: any) => x == new Date(this.props.leaveobj.approvedStartDate).getFullYear()).length <= 0) {
        year.push(new Date(this.props.leaveobj.approvedStartDate).getFullYear())
      }

      if (year.filter((x: any) => x == new Date(this.props.leaveobj.approvedEndDate).getFullYear()).length <= 0) {
        year.push(new Date(this.props.leaveobj.approvedEndDate).getFullYear())
      }
      this.setState({ years: year });
    }

    if (this.state.applieddays.length <= 0) {
      let mDays: any = [];
      this.props.leaveobj.leaveAppliedDays.forEach((x: any) => {
        if (this.props.leaveobj.leaveCancelDays && this.props.leaveobj.leaveCancelDays.length > 0) {
          if (this.props.leaveobj.leaveCancelDays.filter((y: any) => y.cancelDate == x.appliedDate).length <= 0) {
            mDays.push(new Date(x.appliedDate).getTime())
          }
        }
        else {
          mDays.push(new Date(x.appliedDate).getTime())
        }
      });
      this.issuedDays = mDays;
      this.setState({ applieddays: mDays });
    }
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSave = async () => {
    try {
      if (this.sText.value == null) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Sorry, Remark is mandatory information. Please provide remark to continue.</p>',
        });
        return;
      }

      let msg: string = '';
      if (this.cancelDays.length <= 0) {
        msg = 'You are about to cancel the entire leave application. Please click Yes to proceed.'
      } else {
        msg = 'You have cancelled some of the days from the leave application. Please click Yes to proceed.'
      }

      DialogConfirm.showDialog({
        isConfirm: true,
        content: `<p class="dialog-contain">${msg}</p> `,
        okCaption: 'Yes',
        closeCaption: 'No',
        showCloseIcon: true,
        OkEvent: this.confirmCancel.bind(this),
      });

    } catch (error) {
      this.setState({ error: 'Error saving data. Please try again.' });
    }
  }

  confirmCancel = async () => {
    let cancelledDays: any = [];
    let lvDaysData: any = [];
    var partialCancel: boolean = false;

    if (this.cancelDays.length <= 0) {
      lvDaysData = this.issuedDays;
      partialCancel = false;
    } else if (this.cancelDays.length > 0) {
      lvDaysData = this.cancelDays;
      partialCancel = true;
    }

    for (let i = 0; i < lvDaysData.length; i++) {
      const gDate = Number(lvDaysData[i]);
      var data = {
        leaveApplicationId: this.props.leaveobj.id,
        cancelDate: Common.formatDate(Common.RemoveTimezoneOffsetFromDate(new Date(gDate)), 'yyyy-MM-dd'),
        dayFraction: 1,
        cancelRemark: this.sText.value,
        employeeId: this.props.leaveobj.employeeId,
        isApprover: true,
        leaveApprovalId: this.user.employee.id,
        isPartialCancel: partialCancel,
        leaveTypeId: this.props.leaveobj.leaveTypesId
      }
      cancelledDays.push(data);
    }

    const response = await this.saveData(cancelledDays);

    this.props.onSave(response);
  }

  saveData = async (data: any) => {
    const response = await Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/cancelleave`,
      data,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `< p class= "dialog-contain" > ${error.response.data.messages.join()}</p > `,
          // });
        }
      })
      .finally(() => { });

    return response;
  }

  onDayClick = (data: any) => {
    if (data !== undefined) {
      let char = data.split('|')[1];
      let dval = data.split('|')[0];

      if (char === 'R') {
        const idx = this.issuedDays.indexOf(Number(dval));
        if (idx > -1) {
          this.issuedDays.splice(idx, 1);
          this.cancelDays.push(dval)
        }
      } else if (char === 'A') {
        const idx = this.cancelDays.indexOf(Number(dval));
        if (idx > -1) {
          this.cancelDays.splice(idx, 1);
          this.issuedDays.push(dval)
        }
      }
    }
  }

  render() {
    return (
      <>
        {this.state.years.length > 0 ?
          <>
            <div className='row col-12'>
              <div className='col-3'>
                <div className='row align-items-center'>
                  <div className='col-12 py-2'>
                    <input
                      className='input-text-border'
                      id='lvemployee'
                      name='lvemployee'
                      type='text'
                      autoComplete='off'
                      style={{ fontSize: '15px', backgroundColor: 'transparent', border: 'none' }}
                      value={(this.props.leaveobj as any)!.employeeName}
                      disabled
                    />
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-12 py-2' style={{ display: 'flex' }}>
                    <div style={{ width: '9%', backgroundColor: (this.props.leaveobj as any)!.leaveType.color, borderRadius: '50%', marginRight: '5px' }}></div>
                    <div style={{ width: '90%' }}>
                      <input
                        className='input-text-border'
                        id='lvleavetype'
                        name='lvleavetype'
                        type='text'
                        autoComplete='off'
                        style={{ fontSize: '15px', backgroundColor: 'transparent', border: 'none' }}
                        value={(this.props.leaveobj as any)!.leaveType.name + ' (' + (this.props.leaveobj as any)!.applicationNumber + ')'}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-12 py-2'>
                    <DateRangePickerComponent
                      allowEdit={false}
                      format={this.user.settings.formats.DateShort}
                      enabled={false}
                      startDate={(this.props.leaveobj as any)!.approvedStartDate}
                      endDate={(this.props.leaveobj as any)!.approvedEndDate}
                      min={(this.props.leaveobj as any)!.approvedStartDate}
                      max={(this.props.leaveobj as any)!.approvedEndDate}
                    ></DateRangePickerComponent>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-12 py-2'>
                    <label style={{ fontSize: '14px', paddingBottom: '7px', fontStyle: 'italic', color: 'steelblue' }}>
                      Unselect from the calender the specific days you wish to cancel or click 'Cancel Leave' to cancel the entire leave form.
                    </label>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-12 py-2'>
                    <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                      Cancel Remark
                    </label>
                    <TextBoxComponent
                      id='lvApprovalremark'
                      multiline={true}
                      ref={(scope) => { this.sText = scope; }}
                    ></TextBoxComponent>
                  </div>
                </div>
              </div>
              <div className='col-9' style={{ overflow: 'auto' }}>
                <LeaveTimeLine
                  _years={this.state.years}
                  _employee={this.props.leaveobj.employee}
                  _firstDayOfWeek={1}
                  _showWeekSeparators={false}
                  _forceFullWeeks={false}
                  _cmenuitems={null}
                  _fromdate={this.props.leaveobj.approvedStartDate}
                  _todate={this.props.leaveobj.approvedEndDate}
                  _applicationid={this.props.leaveobj.id}
                  _leaverange={this.state.applieddays}
                  _leavecolor={(this.props.leaveobj as any)!.leaveType.color}
                  onDayClick={this.onDayClick}
                />
              </div>
            </div>
            <div className='row col-12' style={{ textAlign: 'right', display: 'flex', float: 'right', paddingTop: '30px' }}>
              <div>
                <button
                  id='btnIAccept'
                  className='flat-button'
                  onClick={this.handleSave.bind(this)}>
                  Cancel Leave
                </button>
                <button
                  id='btnICancel'
                  className='flat-button'
                  onClick={this.handleCancel.bind(this)}>
                  Exit
                </button>
              </div>
            </div>
          </>
          : null}
      </>
    );
  }
}