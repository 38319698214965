// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import './Bank.css';
import Common from '../../Util/Common';
// import {
//   ColumnsDirective,
//   EditSettingsModel,
//   GridComponent,
// } from '@syncfusion/ej2-react-grids';
import {
  ContextMenuComponent,
  MenuItemModel,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import { Dialog, DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Toolbar,
  TreeGridComponent,
  Inject,
} from '@syncfusion/ej2-react-treegrid';
import DialogConfirm from '../Dialogbox/DialogConfirm';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_PAYROLLSERVICE } = process.env;

interface Props {}

export default class Bank extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private grdBank: TreeGridComponent | any = undefined;
  private treemenuObj: ContextMenuComponent | any = null;
  private treeObj: TreeViewComponent | any = null;
  private selectednode: any = null;
  private selectedmenuid: string = '';
  private isgroupselected: boolean = false;
  private txtCode: any = null;
  private txtName: any = null;
  private txtWebsite: any = null;
  private txtSwiftCode: any = null;
  private txtContactPerson: any = null;
  private txtContactNo: any = null;
  private txtSortCode: any = null;

  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Row',
    newRowPosition: 'Below',
    allowEditOnDblClick: true,
    showDeleteConfirmDialog: true,
  };

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonBank: undefined,
    treeviewfields: undefined,
    showDialog: false,
    addnew: true,
    delkey: '',
    code: '',
    name: '',
    groupWebsite: '',
    groupSwifCode: '',
    contactPerson: '',
    contactNo: '',
    sortCode: '',
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    /* Open contextmenu on three vertical dots */
    document
      .querySelector('#bank-moreoptions')
      ?.removeEventListener('click', (e: any) =>
        this.treemenuObj.open(e.y, e.x)
      );
    document
      .querySelector('#bank-moreoptions')
      ?.addEventListener('click', (e: any) => this.treemenuObj.open(e.y, e.x));

    if (!this.state.jsonBank) {
      this.GetBank();
    }
  }

  public GetBank() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/list`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonBank: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public DialogContent() {
    return (
      <>
        <div className='row align-items-center'>
          <div
            className={`col-md-${
              this.isgroupselected === true ? '12' : '6'
            } py-2`}
          >
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Code
            </label>
            <input
              className='input-text-border'
              id='code'
              name='code'
              type='text'
              placeholder={'Code'}
              autoComplete='off'
              style={{ fontSize: '15px' }}
              ref={(obj: any) => (this.txtCode = obj)}
              value={this.state.code}
              required={true}
              onChange={(e: any) => {
                this.setState({ code: e.target.value });
                setTimeout(() => {
                  this.txtCode.focus();
                }, 0);
              }}
            />
          </div>
          <div
            className={`col-md-${
              this.isgroupselected === true ? '12' : '6'
            } py-2`}
          >
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Name
            </label>
            <input
              className='input-text-border'
              id='name'
              name='name'
              key={Math.random()}
              type='text'
              placeholder={'Name'}
              autoComplete='off'
              style={{ fontSize: '15px' }}
              ref={(obj: any) => (this.txtName = obj)}
              value={this.state.name}
              required={true}
              onChange={(e: any) => {
                this.setState({ name: e.target.value });
                setTimeout(() => {
                  this.txtName.focus();
                }, 0);
              }}
            />
          </div>
        </div>
        {this.isgroupselected ? (
          <div className='row align-items-center'>
            <div className='col-md-12 py-2 d-none'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Website
              </label>
              <input
                className='input-text-border'
                id='groupWebsite'
                name='groupWebsite'
                type='text'
                placeholder={'Website'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtWebsite = obj)}
                value={this.state.groupWebsite}
                onChange={(e: any) => {
                  this.setState({ groupWebsite: e.target.value });
                  setTimeout(() => {
                    this.txtWebsite.focus();
                  }, 0);
                }}
              />
            </div>
            <div className='col-md-12 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Swift Code
              </label>
              <input
                className='input-text-border'
                id='groupWSwiftCode'
                name='groupWSwiftCode'
                type='text'
                placeholder={'Swift Code'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtSwiftCode = obj)}
                value={this.state.groupSwifCode}
                onChange={(e: any) => {
                  this.setState({ groupSwifCode: e.target.value });
                  setTimeout(() => {
                    this.txtSwiftCode.focus();
                  }, 0);
                }}
              />
            </div>
          </div>
        ) : (
          <div className='row align-items-center'>
            <div className='col-md-6 py-2 d-none'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Contact Person
              </label>
              <input
                className='input-text-border'
                id='contactPerson'
                name='contactPerson'
                type='text'
                placeholder={'Contact Person'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtContactPerson = obj)}
                value={this.state.contactPerson}
                onChange={(e: any) => {
                  this.setState({ contactPerson: e.target.value });
                  setTimeout(() => {
                    this.txtContactPerson.focus();
                  }, 0);
                }}
              />
            </div>
            <div className='col-md-6 py-2 d-none'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Contact No.
              </label>
              <input
                className='input-text-border'
                id='contactNo'
                name='contactNo'
                type='text'
                placeholder={'Contact No.'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtContactNo = obj)}
                value={this.state.contactNo}
                onChange={(e: any) => {
                  this.setState({ contactNo: e.target.value });
                  setTimeout(() => {
                    this.txtContactNo.focus();
                  }, 0);
                }}
              />
            </div>
            <div className='col-md-6 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Sort Code
              </label>
              <input
                className='input-text-border'
                id='sortCode'
                name='sortCode'
                type='text'
                placeholder={'Sort Code'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtSortCode = obj)}
                value={this.state.sortCode}
                onChange={(e: any) => {
                  this.setState({ sortCode: e.target.value });
                  setTimeout(() => {
                    this.txtSortCode.focus();
                  }, 0);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false });
        } else {
          //this.setSelectedDropdownTreeValue.bind(this);
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        if (!this.state.code) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter code</p>',
          });
          return;
        } else if (!this.state.name) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter name</p>',
          });
          return;
        }

        let payload: any = null;
        payload = {
          id: null,
          code: this.state.code,
          name: this.state.name,
          groupWebsite: this.isgroupselected ? this.state.groupWebsite : null,
          groupSwiftCode: this.isgroupselected
            ? this.state.groupSwifCode
            : null,
          isgroup: this.isgroupselected,
          bankId:
            this.grdBank.getSelectedRecords().length > 0
              ? this.grdBank.getSelectedRecords()[0].parentItem
                ? this.grdBank.getSelectedRecords()[0].parentItem.id
                : this.grdBank.getSelectedRecords()[0].id
              : null,

          address1: this.isgroupselected ? null : '',
          address2: this.isgroupselected ? null : '',
          countryId: this.isgroupselected ? null : null,
          stateId: this.isgroupselected ? null : null,
          cityId: this.isgroupselected ? null : null,
          pincode: this.isgroupselected ? null : '',
          contactPerson: this.isgroupselected ? null : this.state.contactPerson,
          contactNo: this.isgroupselected ? null : this.state.contactNo,
          sortCode: this.isgroupselected ? null : this.state.sortCode,
          isClearing: this.isgroupselected ? null : true,
        };

        this.SaveBank(payload);
      },
    },
  ];

  public SaveBank(payload: any, action: string = 'add') {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/add`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let obj: any = response;

        if (!response.response) {
          if (action === 'add') {
            let pidx: number = (this.grdBank.dataSource as any).findIndex(
              (x: any) => x.id === response.id
            );

            if (pidx < 0) {
              (this.grdBank.dataSource as any).push(response);
            } else {
              (this.grdBank.dataSource as any)[pidx] = response;
            }
          } else if (action === 'edit') {
            (this.grdBank.dataSource as any)[
              this.grdBank.dataSource.findIndex(
                (x: any) => x.id === response.id
              )
            ] = response;
          }
          this.grdBank.refresh();
          this.setState({ showDialog: false });
        } else {
        }

        return response;
      })
      .catch((error: any) => {
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        // DialogConfirm.showDialog({
        //   content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
        // });
        // }
      })
      .finally(() => {});
  }

  render() {
    let cnfDlg: Dialog;

    const onToolbarClick = (e: any) => {
      if (e.item.id.slice(-3) === 'add') {
        if (this.grdBank.getSelectedRecords().length <= 0) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Please select bank group to add new bank branch.</p>',
          });
          return;
        }

        this.isgroupselected = false;

        this.setState({
          showDialog: true,
          code: '',
          name: '',
          groupWebsite: '',
          groupSwifCode: '',
          contactPerson: '',
          contactNo: '',
          sortCode: '',
        });
      } else if (e.item.id.slice(-5) === 'clear') {
        this.grdBank.clearSelection();
      } else if (e.item.id.slice(-8) === 'addgroup') {
        this.isgroupselected = true;
        this.grdBank.clearSelection();
        this.setState({
          showDialog: true,
          code: '',
          name: '',
          groupWebsite: '',
          groupSwifCode: '',
          contactPerson: '',
          contactNo: '',
          sortCode: '',
        });
      }
    };

    const templateSwiftSortCode = (e: any) => {
      let itemValue: string = e[e.column.field];
      if (!e.parentItem) {
        itemValue = e[e.column.field];
      } else {
        itemValue = e['sortCode'];
      }

      return <>{itemValue}</>;
    };

    const editSwiftSortCode = (e: any) => {
      if (!e.parentItem) {
        return (
          <TextBoxComponent
            id={e.column.field}
            name={e.column.field}
            value={e[e.column.field]}
            onBlur={onSwiftSortCodeChange}
          ></TextBoxComponent>
        );
      } else {
        return (
          <TextBoxComponent
            id={'sortCode'}
            name={'sortCode'}
            value={e['sortCode']}
            onBlur={onSwiftSortCodeChange}
          ></TextBoxComponent>
        );
      }
    };

    const onSwiftSortCodeChange = (e: any) => {};

    const actionBegin = (e: any) => {
      let payload: any = null;

      if (e.requestType === 'beginEdit') {
      } else if (
        e.requestType === 'save' &&
        (e.action === 'add' || e.action === 'edit')
      ) {
        let fld: string = 'groupSwiftCode';
        if (e.data.parentItem) {
          fld = 'sortCode';
        }
        let objlastinput: any = document.getElementById(fld);
        if (objlastinput) {
          e.data[fld] = objlastinput.value;
        }

        if (!e.data.code) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter code</p>',
          });
          e.cancel = true;
          return;
        }

        if (!e.data.name) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter name</p>',
          });
          e.cancel = true;
          return;
        }

        payload = {
          id: e.data.id,
          code: e.data.code,
          name: e.data.name,
          groupWebsite: !e.data.parentItem ? e.data.groupWebsite : null,
          groupSwiftCode: !e.data.parentItem ? e.data.groupSwiftCode : null,
          isgroup: !e.data.parentItem,
          bankId: e.data.parentItem ? e.data.parentItem.id : null,
          address1: !e.data.parentItem ? null : '',
          address2: !e.data.parentItem ? null : '',
          countryId: !e.data.parentItem ? null : null,
          stateId: !e.data.parentItem ? null : null,
          cityId: !e.data.parentItem ? null : null,
          pincode: !e.data.parentItem ? null : '',
          contactPerson: !e.data.parentItem ? null : e.data.contactPerson,
          contactNo: !e.data.parentItem ? null : e.data.contactNo,
          sortCode: !e.data.parentItem ? null : e.data.sortCode,
          isClearing: !e.data.parentItem ? null : true,
        };
        // } else {
        // }

        //this.selectedData = e.data.taskData;

        e.cancel = true;

        this.SaveBank(payload, e.action);
      } else if (e.requestType === 'delete') {
        let ds: any = [...this.grdBank.dataSource];
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/delete`,
          {
            id: e.data[0].id,
            bankId: e.data[0].parentItem ? e.data[0].parentItem.id : null,
          },
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            if (e.data[0].parentItem) {
              if (e.data[0].parentItem.taskData.subBank.length === 1) {
                let pidx: number = ds.findIndex(
                  (x: any) => x.id === e.data[0].parentItem.id
                );
                ds.splice(pidx, 1);
              } else {
                let pidx: number = ds.findIndex(
                  (x: any) => x.id === e.data[0].parentItem.id
                );
                let cidx: number = ds[pidx].subBank.findIndex(
                  (x: any) => x.id === e.data[0].id
                );
                ds[pidx].subBank.splice(cidx, 1);
              }
            } else {
              let pidx: number = ds.findIndex(
                (x: any) => x.id === e.data[0].id
              );
              ds.splice(pidx, 1);
            }

            this.grdBank.dataSource = ds;
            this.grdBank.refresh();
            return response.data;
          })
          .then((response: any) => {
            return response;
          })
          .catch((error: any) => {
            this.grdBank.dataSource = ds;
            this.grdBank.refresh();
            // console.error(error);
            // if (
            //   error.response &&
            //   error.response.data &&
            //   error.response.data.messages &&
            //   error.response.data.messages.length > 0
            // ) {
            //  DialogConfirm.showDialog({
            //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
            //  });
            // }
          })
          .finally(() => {});
      }
    };

    // const onSearchTextChange = (e: any) => {
    //   let _text: string = e.value;
    //   let predicats = [],
    //     _array: any = [],
    //     _filter: any = [];

    //   if (_text == '') {
    //     changeDataSource(this.state.jsonBank);
    //   } else {
    //     let f1: any = (this.state.jsonBank! as []).filter((x: any) => {
    //       let sm: any = x.subBank.filter(
    //         (s: any) => s.code.includes(_text) || s.name.includes(_text)
    //       );
    //       if (sm.length > 0) {
    //         sm.forEach((n: any) => {
    //           _filter.push({ ...n });
    //         });
    //       }
    //       return sm.length > 0;
    //     });

    //     f1.forEach((x: any) => {
    //       let xx: any = { ...x };
    //       xx.subBank = _filter.filter((y: any) => y.bankId === x.id);
    //       _array.push(xx);
    //     });
    //     changeDataSource(_array);

    //     setTimeout(() => {
    //       this.treeObj.expandAll();
    //     }, 100);
    //   }
    // };

    // const changeDataSource = (data: any) => {
    //   this.treeObj.fields.dataSource = data;
    // };

    return (
      <>
        <div id='divBank' className='py-2'>
          <div className='control-pane'>
            <div className='control-section'>
              <div className='control_wrapper'>
                <div id='banktree' className='banktree'>
                  {/* <div className='three-dots-parent flex-row justify-content-between'>
                    <div>
                      {this.treeObj && (
                        <div
                          className='clearparent d-none'
                          onClick={clearParentclick}
                        >
                          <div className='fa-regular fa-circle-xmark px-2'></div>
                          <span>Clear Selection</span>
                        </div>
                      )}
                    </div>
                    {this.treeObj && (
                      <div className='d-none itemselected'>{`${'1'} item selected`}</div>
                    )}
                    <div id='bank-moreoptions' className='three-dots'></div>
                  </div>
                  <div className='treeviewheader'>
                    <div className='row fw-bold'>
                      <div className='col-md-2'>
                        <label>Code</label>&nbsp;
                      </div>
                      <div className='col-md-6'>
                        <label>Name</label>&nbsp;
                      </div>
                      <div className='col-md-2'>
                        <label>Website</label>&nbsp;
                      </div>
                      <div className='col-md-2'>
                        <label>Swift Code</label>
                        &nbsp;
                      </div>
                    </div>
                  </div> */}
                  {this.state.jsonBank && (
                    <TreeGridComponent
                      id={'grid-banklist'}
                      className='treegridbanklist h-100'
                      dataSource={this.state.jsonBank}
                      height='calc(100vh - 380px)'
                      treeColumnIndex={0}
                      childMapping='subBank'
                      editSettings={this.editSettings}
                      actionBegin={actionBegin}
                      ref={(obj: any) => (this.grdBank = obj)}
                      toolbar={[
                        {
                          text: 'Add Branch',
                          tooltipText: 'Add Branch',
                          prefixIcon: 'e-add font-14',
                          id: `grid-banklist-gridcontrol_add`,
                        },
                        {
                          text: 'Add Bank Group',
                          tooltipText: 'Add Bank Group',
                          prefixIcon: 'e-add-above font-14',
                          id: `grid-banklist-gridcontrol_addgroup`,
                        },
                        {
                          text: 'Edit',
                          tooltipText: 'Edit',
                          prefixIcon: 'e-edit font-14',
                          id: `grid-banklist_gridcontrol_edit`,
                        },
                        {
                          text: 'Delete',
                          tooltipText: 'Delete',
                          prefixIcon: 'e-delete font-14',
                          id: `grid-banklist_gridcontrol_delete`,
                        },
                        {
                          text: 'Cancel',
                          tooltipText: 'Cancel',
                          prefixIcon: 'e-cancel font-14',
                          id: `grid-banklist_gridcontrol_cancel`,
                        },
                      ]}
                      toolbarClick={onToolbarClick}
                    >
                      <ColumnsDirective>
                        <ColumnDirective
                          field='code'
                          headerText='Code'
                          width='70'
                          // editType='dropdownedit'
                          // template={templateDropDown}
                          // editTemplate={editDropDown}
                        />
                        <ColumnDirective
                          field='name'
                          headerText='Name'
                          width='70'
                          // editType='dropdownedit'
                          // template={templateDropDown}
                          // editTemplate={editDropDown}
                        />
                        <ColumnDirective
                          field='groupSwiftCode'
                          headerText='Swift Code/Sort Code'
                          width='70'
                          editType='stringedit'
                          template={templateSwiftSortCode}
                          editTemplate={editSwiftSortCode}
                        />
                      </ColumnsDirective>
                      <Inject services={[Toolbar, Edit]} />
                    </TreeGridComponent>
                  )}
                </div>
                {/*<div
                  id='treeviewsearch-bank'
                  className='control-pane'
                  style={{ border: '1px solid lightgray' }}
                >
                  <div className='control-section tbar-control-section'>
                    <div className='col-md-12 d-flex p-0'>
                      <div style={{ width: '100%' }}>
                        <span
                          className='e-input-group e-control-wrapper e-inherit border-0 px-1'
                          style={{ background: 'none' }}
                        >
                          <TextBoxComponent
                            placeholder='Search Branch'
                            showClearButton={true}
                            onChange={onSearchTextChange}
                            title='Search Branch'
                          ></TextBoxComponent>
                          <span
                            id={`treeview-bank-search`}
                            className='e-icons e-search e-input-group-icon'
                            title='Search'
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  </div>*/}
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-bankchange'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            close={() => this.setState({ showDialog: false })}
            content={this.DialogContent.bind(this)}
            isModal={true}
            header={`Add Bank ${
              this.isgroupselected === false ? 'Branch' : 'Group'
            }`}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
            allowDragging={true}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
