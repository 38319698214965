// * -----------------------------------------------------------------------------------------------------------------1.Meeting

import React, { Component } from 'react';
import Common from '../../Util/Common';
import { Dialog, DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  MultiSelectComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { showDialog } from '@syncfusion/ej2-react-spreadsheet';

interface props {
  id: string;
  selectorData: any;
  allocationtData: any;
  jfieldsreportto?: any;
  //   inoffcanvas?: boolean;
  //   showallfields?: boolean;
  //   mandatoryfields?: string[];
  //   refreshContract?: any;
  //   contractTitle: string;
  /**
   * expected value : 'contract' | 'document'
   *
   * @default 'contract'
   */
  showDataFrom?: string;
}
const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

// * -----------------------------------------------------------------------------------------------------------------1.Acknowledge
class AllocationDetail extends React.Component<props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  //private id: string = '';
  private allocation_id: string = '';
  private allocation_hasReportingTo: boolean = false;

  private treeSettings: TreeSettingsModel = {
    expandOn: 'Click',
    autoCheck: false,
  };
  private dblclickedctrlid: string = '';
  private ddlTree: DropDownTreeComponent | any = null;
  private filteredemp: any = [];
  private mSelect: MultiSelectComponent | any;
  private mDropdwn: DropDownListComponent | any;
  private jobempreporting: any = [];
  constructor(props: props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.InitializeData();
  }

  state = {
    fields: undefined,
    employeelist: [],
    showDialog: false,
    reportedjobname: '',
  };

  componentDidMount(): void {
    if (this.state.employeelist.length <= 0) {
      this.fetchEmployee();
    }
  }

  public InitializeData() {
    //this.id = `#allocationlisttree-${this.props.selectorData.id}`;
    this.allocation_id = this.props.selectorData
      ? this.props.selectorData.id.toLowerCase()
      : '';

    switch (this.allocation_id) {
      case 'branch':
        break;

      case 'department':
        break;

      case 'job':
      case 'position':
        this.allocation_hasReportingTo = true;
        break;

      case 'grade':
      case 'level':
        break;

      case 'costcenter':
        break;

      case 'class':
        break;

      case 'section':
        break;

      case 'unit':
        break;

      case 'team':
        break;

      default:
        break;
    }
  }

  handleRemoveClick = (e: any) => {
    DialogConfirm.showDialog({
      isConfirm: true,
      content:
        '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove reporting job mapping?</p></div>',
      okCaption: 'Yes',
      closeCaption: 'Cancel',
      // btncssClass : 'flat-button',
      OkEvent: this.confirmAction.bind(this),
    });
  };

  fetchEmployee() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.filteredemp = response;
        this.setState({ employeelist: response });
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {});
  }

  confirmAction = () => {
    let payload: any = {
      id: this.props.allocationtData.id,
      code: this.props.allocationtData.code,
      name: this.props.allocationtData.name,
      reportToJobId: null,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addjob`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.props.allocationtData.reportToJobId = null;
        this.props.allocationtData.reportToJob = null;
        this.setState({ update: true });
      })
      .catch((error: any) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          DialogConfirm.showDialog({
            content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
          });
        }
      })
      .finally(() => {});
  };

  handleChange(e: any) {
    if (e) {
      if (
        this.hasCyclicReporting(
          this.props.jfieldsreportto.dataSource,
          this.props.allocationtData.id,
          e.itemData.id
        )
      ) {
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, Selected reporting job creates a cyclic reporting structure.</p>',
        });
        return;
      }
      this.filteredemp = this.state.employeelist.filter(
        (x: any) => x.currentJobName == e.itemData.name
      );
      this.mSelect.dataSource = this.filteredemp;
      let eData: any = this.props.jfieldsreportto.dataSource.filter(
        (x: any) => x.name == e.itemData.name
      );
      if (eData && eData.length > 0 && eData[0].primaryReportings) {
        eData[0].primaryReportings.forEach((e: any) => {
          this.jobempreporting.push(e.employeeId);
        });
        this.mSelect.value = this.jobempreporting;
      }
    }
  }

  handleSave() {
    if (this.mDropdwn.value == null) {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Sorry, Please select Job to continue.</p>',
      });
      return;
    }

    if (this.mSelect.value == null) {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Sorry, Please select Employee(s) to continue.</p>',
      });
      return;
    }

    if (
      this.hasCyclicReporting(
        this.props.jfieldsreportto.dataSource,
        this.props.allocationtData.id,
        this.mDropdwn.value
      )
    ) {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Sorry, Selected reporting job creates a cyclic reporting structure.</p>',
      });
      return;
    }

    let pReporting: any = [];
    for (let i = 0; i < this.mSelect.value.length; i++) {
      const item = this.mSelect.value[i];
      pReporting.push({
        jobId: this.mDropdwn.value,
        employeeId: item,
        isActive: true,
      });
    }

    let payload: any = {
      id: this.props.allocationtData.id,
      code: this.props.allocationtData.code,
      name: this.props.allocationtData.name,
      reportToJobId: this.mDropdwn.value,
      primaryReportings: pReporting,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addjob`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.props.allocationtData.reportToJobId = response.reportToJobId;
        this.props.allocationtData.reportToJob = response.reportToJob;

        this.setState({
          update: true,
          showDialog: false,
          reportedjobname: '',
          empids: [],
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  generateContent = () => {
    if (
      this.state.employeelist.filter(
        (x: any) => x.currentJobName == this.state.reportedjobname
      ).length > 0
    ) {
      this.filteredemp = this.state.employeelist.filter(
        (x: any) => x.currentJobName == this.state.reportedjobname
      );
    }

    let eData: any = this.props.jfieldsreportto.dataSource.filter(
      (x: any) => x.name == this.state.reportedjobname
    );
    let empids: any = [];
    if (eData && eData.length > 0 && eData[0].primaryReportings) {
      eData[0].primaryReportings.forEach((e: any) => {
        empids.push(e.employeeId);
      });
    }
    let _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Job
            </label>
            <DropDownListComponent
              id='job'
              dataSource={this.props.jfieldsreportto.dataSource}
              filterBarPlaceholder='Search a Job'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Job'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
              select={this.handleChange.bind(this)}
              ref={(s) => {
                this.mDropdwn = s;
              }}
              value={eData.length > 0 ? eData[0].jobId : undefined}
              text={eData.length > 0 ? eData[0].name : ''}
              enabled={eData.length > 0 ? false : true}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Primary Reporting
            </label>
            <MultiSelectComponent
              id='employee'
              dataSource={this.filteredemp}
              filterBarPlaceholder='Search a Employee'
              allowFiltering={true}
              filterType='Contains'
              ref={(scope) => {
                this.mSelect = scope;
              }}
              fields={{ value: 'id', text: 'personName' }}
              placeholder='Select Employee'
              popupHeight='220px'
              maximumSelectionLength={2}
              mode='Box'
              style={{ fontSize: '15px' }}
              value={empids}
              enabled={empids.length > 0 ? false : true}
            />
          </div>
        </div>
        <div
          className='row'
          style={{
            textAlign: 'right',
            display: 'flex',
            float: 'right',
            paddingTop: '10px',
          }}
        >
          <div>
            <button
              id='btnIAccept'
              className='flat-button'
              onClick={this.handleSave.bind(this)}
            >
              Submit
            </button>
            <button
              id='btnICancel'
              className='flat-button'
              onClick={() => this.setState({ showDialog: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
    return _content;
  };

  handleLabelDblClick = (e: any) => {
    if (e.target.id.toString().includes('_orgnl') === false) {
      return;
    }

    this.setState({
      showDialog: true,
      reportedjobname: e.currentTarget.textContent,
    });

    /* Orginal Code */
    // if (e.target.id.toString().includes('_orgnl') === false) {
    //   return;
    // }

    // let nonlblelmnt: any = document.getElementById(
    //   e.target.id.toString().replace('_orgnl', '')
    // );
    // if (nonlblelmnt) {
    //   if (document.getElementById('remove')) {
    //     document.getElementById('remove')!.classList.add('d-none');
    //   }
    //   document.getElementById(e.target.id)!.classList.add('d-none');
    //   nonlblelmnt.parentElement.parentElement.classList.remove('d-none');
    //   this.dblclickedctrlid = nonlblelmnt.id;

    //   if (e.target.attributes['itemtype'].value == 'dropdowntree') {
    //     let obj: any = document.getElementById(nonlblelmnt.id) as Element;
    //     if (obj !== null) {
    //       let ddtree: DropDownTreeComponent = obj
    //         .ej2_instances[0] as DropDownTreeComponent;
    //       if (ddtree) {
    //         ddtree.showPopup();
    //         if (ddtree.value && ddtree.value.length > 0) {
    //           ddtree.ensureVisible(ddtree.value[0]);
    //         }
    //       }
    //     }
    //   } else if (e.target.attributes['itemtype'].value == 'dropdownlist') {
    //     let obj: any = document.getElementById(nonlblelmnt.id) as Element;
    //     if (obj !== null) {
    //       let ddlist: DropDownListComponent = obj
    //         .ej2_instances[0] as DropDownListComponent;
    //       if (ddlist) {
    //         ddlist.showPopup();
    //       }
    //     }
    //   } else if (e.target.attributes['itemtype'].value == 'datepicker') {
    //     let obj: any = document.getElementById(nonlblelmnt.id) as Element;
    //     if (obj !== null) {
    //       let dtpicker: DatePickerComponent = obj
    //         .ej2_instances[0] as DatePickerComponent;
    //       if (dtpicker) {
    //         dtpicker.show();
    //       }
    //     }
    //   }
    // }
  };

  private hasCyclicReporting(
    jobs: any[],
    selectedJobId: string,
    reportingJobId: string
  ): boolean {
    if (selectedJobId === reportingJobId) {
      return true;
    }

    // Map to store visited jobs during the check
    const visitedMap = new Map<string, boolean>();

    // Function to recursively check for cycles
    function checkCycle(currentJobId: string, targetJobId: string): boolean {
      // If we've already visited this job in the current path, cycle detected
      if (visitedMap.has(currentJobId)) {
        return false; // Return false to indicate cycle detected
      }

      // Mark the current job as visited
      visitedMap.set(currentJobId, true);

      // Get the job object for the current job ID
      const currentJob = jobs.find((job) => job.id === currentJobId);

      // If the current job does not exist or does not have a reporting job, no cycle
      if (!currentJob || !currentJob.reportToJobId) {
        return false;
      }

      // If the current job's reporting job is the target job, cycle detected
      if (currentJob.reportToJobId === targetJobId) {
        return true;
      }

      // Recursively check the reporting job
      return checkCycle(currentJob.reportToJobId, targetJobId);
    }

    // Check for cycle starting from the selected job to the reporting job
    return checkCycle(reportingJobId, selectedJobId);
  }

  render() {
    let cnfDlg: Dialog;

    const handleDropDownTreeChange = (e: any) => {
      if (!e.item.id) return;

      if (e.item.id) {
        let obj: any = document
          .querySelector(`#${e.item.id}`)
          ?.closest('.e-popup-close');
        if (!obj) return;

        let ctrl: any = document.querySelector(
          `#${obj.id.replace('_popup', '')}`
        );
        this.ddlTree = ctrl.ej2_instances[0] as DropDownTreeComponent;

        if (this.ddlTree === undefined) {
          return;
        }

        if (
          this.hasCyclicReporting(
            this.props.jfieldsreportto.dataSource,
            this.props.allocationtData.id,
            this.ddlTree.value[0]
          )
        ) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, Selected reporting job creates a cyclic reporting structure.</p>',
          });
          return;
        }

        let payload: any = {
          id: this.props.allocationtData.id,
          code: this.props.allocationtData.code,
          name: this.props.allocationtData.name,
          reportToJobId: this.ddlTree.value[0],
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addjob__1`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            this.props.allocationtData.reportToJobId = response.reportToJobId;
            this.props.allocationtData.reportToJob = response.reportToJob;

            let ctrl: any = document.getElementById(
              this.ddlTree.element.id + '_orgnl'
            );
            if (ctrl) {
              ctrl.classList.remove('lablecolor-none');
              let lblelmnt: any = document.getElementById(
                this.ddlTree.element.id + '_orgnl'
              );
              if (lblelmnt) {
                document
                  .getElementById(this.ddlTree.element.id)!
                  .parentElement!.parentElement!.classList.add('d-none');
                lblelmnt.classList.remove('d-none');
                if (document.getElementById('remove')) {
                  document.getElementById('remove')!.classList.remove('d-none');
                }
              }
            } else {
              let ctrl: any = document.getElementById(
                this.ddlTree.element.id + '_orgnl'
              );
              if (ctrl) {
                ctrl.classList.add('lablecolor-none');
              }
            }

            this.setState({
              update: true,
            });
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});

        //this.ResetSelectedAllocationVariables(this.ddlTree.element.id);

        // if (this.allocfields!.dataSource[0]['id'] === Common.blankguid) {
        //   this.newitemnode = this.allocfields!.dataSource.shift();
        // }

        // this.allocfields!.selectable = '';

        // if (e.itemData.id == Common.blankguid) {
        //   this.setState({
        //     allocationlistdialog: true,
        //     allocationfields: this.allocfields,
        //   });
        // } else {
        //   if (this.allocation) {
        //     if (!this.props.contractData[this.allocation]) {
        //       this.props.contractData[this.allocation] = {};
        //     }
        //     if (this.props.contractData[this.allocation]) {
        //       this.props.contractData[this.allocation]['id'] = e.itemData.id;
        //       this.props.contractData[this.allocation]['name'] = e.itemData.text;
        //       this.props.contractData[this.allocation + 'Id'] = e.itemData.id;

        //       let ctrl: any = document.getElementById(
        //         this.ddlTree.element.id + '_orgnl'
        //       );
        //       if (ctrl) {
        //         ctrl.classList.remove('lablecolor-none');

        //         let lblelmnt: any = document.getElementById(
        //           this.ddlTree.element.id + '_orgnl'
        //         );
        //         if (lblelmnt) {
        //           document
        //             .getElementById(this.ddlTree.element.id)!
        //             .parentElement!.parentElement!.classList.add('d-none');
        //           lblelmnt.classList.remove('d-none');
        //         }
        //       }
        //     } else {
        //       let ctrl: any = document.getElementById(
        //         this.ddlTree.element.id + '_orgnl'
        //       );
        //       if (ctrl) {
        //         ctrl.classList.add('lablecolor-none');
        //       }
        //     }

        //     //this.contractchanged = true;
        //     this.setState({
        //       refreshpropsvalue: Math.random(),
        //     });
        //   }
        // }
      }
    };

    const handleDropDownTreeClear = (e: any) => {
      if (e.isInteracted && e.value.length === 0) {
        let lblelmnt: any = document.getElementById(e.element.id + '_orgnl');
        if (lblelmnt) {
          lblelmnt.classList.add('lablecolor-none');
        }

        //this.ResetSelectedAllocationVariables(e.element.id);

        // this.props.contractData[this.allocation] = null;
        // this.props.contractData[this.allocation + 'Id'] = null;
        // this.props.contractData[
        //   `remove${this.dialogHeader.replaceAll(' ', '')}IfNull`
        // ] = true; //RemoveBranchIfNull

        // this.contractchanged = true;

        // this.setState({
        //   refreshpropsvalue: Math.random(),
        // });
      }
    };

    // handleDropDownListChange = (e: any) => {
    //   if (e.item.id) {
    //     let obj: any = document
    //       .querySelector(`[id='${e.item.id}']`)
    //       ?.closest('.e-popup-open');
    //     let ctrl: any = document.querySelector(
    //       `#${obj.id.replace('_popup', '')}`
    //     );
    //     let ddllist: DropDownListComponent = ctrl
    //       .ej2_instances[0] as DropDownListComponent;

    //     if (ddllist === undefined) {
    //       return;
    //     }

    //     let allocation: string = '';
    //     if (ddllist.element.id === 'ddlcontracttype') {
    //       allocation = 'contractType';
    //     }

    //     if (allocation) {
    //       if (!this.props.contractData[allocation]) {
    //         this.props.contractData[allocation] = {};
    //       }
    //       if (this.props.contractData[allocation]) {
    //         this.props.contractData[allocation]['id'] = e.itemData.id;

    //         if (allocation === 'contractType') {
    //           this.props.contractData[allocation]['itemValue'] =
    //             e.itemData.itemValue;
    //           this.props.contractData[allocation]['itemType'] =
    //             e.itemData.itemType;
    //           this.props.contractData['commonDataId'] = e.itemData.id;
    //         } else {
    //           this.props.contractData[allocation + 'Id'] = e.itemData.id;
    //         }

    //         let ctrl: any = document.getElementById(
    //           ddllist.element.id + '_orgnl'
    //         );
    //         if (ctrl) {
    //           ctrl.classList.remove('lablecolor-none');

    //           // let lblelmnt: any = document.getElementById(
    //           //   ddllist.element.id + '_orgnl'
    //           // );
    //           // if (lblelmnt) {
    //           document
    //             .getElementById(ddllist.element.id)!
    //             .parentElement!.parentElement!.classList.add('d-none');
    //           ctrl.classList.remove('d-none');
    //           //}
    //         }
    //       } else {
    //         let ctrl: any = document.getElementById(
    //           ddllist.element.id + '_orgnl'
    //         );
    //         if (ctrl) {
    //           ctrl.classList.add('lablecolor-none');
    //         }
    //       }

    //       this.contractchanged = true;
    //       // this.setState({
    //       //   [allocation + 'id']: e.itemData.id,
    //       // });
    //       this.setState({
    //         refreshpropsvalue: Math.random(),
    //       });
    //     }
    //   }
    // };

    const handleDropDownOnBlur = () => {
      if (this.dblclickedctrlid !== '') {
        let lblelmnt: any = document.getElementById(
          this.dblclickedctrlid + '_orgnl'
        );
        if (lblelmnt) {
          document
            .getElementById(this.dblclickedctrlid)!
            .parentElement!.parentElement!.classList.add('d-none');
          lblelmnt.classList.remove('d-none');
        }
      }
      this.dblclickedctrlid = '';
    };

    return (
      <>
        <div id={this.props.id} className='allocationlistdetail'>
          <div className='row details-header mb-2'>
            <div className='col-12'>Details</div>
          </div>
          <div className='row details-body mx-1'>
            <div className='col-12'>
              <div className='row details-content mt-2'>
                <div className='col-12'>
                  {this.allocation_hasReportingTo === true && (
                    <div className='row'>
                      <div className=''>
                        <label
                          style={{
                            fontSize: '16px',
                            lineHeight: '32px',
                          }}
                        >
                          Reporting To
                        </label>
                        <div
                          className='w-100'
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            id='ddljobreportto_orgnl'
                            typeof='text'
                            itemType='dropdowntree'
                            className={`labelcolor-active ${
                              this.props.allocationtData &&
                                this.props.allocationtData.reportToJob
                                ? ''
                                : 'labelcolor-none'
                            }`}
                            onClick={this.handleLabelDblClick}
                          >
                            {this.props.allocationtData &&
                              this.props.allocationtData['reportToJob']
                              ? this.props.allocationtData['reportToJob'][
                              'name'
                              ]
                              : 'None'}
                          </label>
                          {this.props.allocationtData &&
                            this.props.allocationtData['reportToJob'] ? (
                            <label
                              id='remove'
                              style={{
                                color: '#fc4c02',
                                cursor: 'pointer',
                              }}
                              onClick={this.handleRemoveClick}
                            >
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </label>
                          ) : (
                            ''
                          )}

                          {this.props.allocationtData &&
                            this.props.allocationtData.hasOwnProperty(
                              'reportToJob'
                            ) ? (
                            <div className='d-none dropdown-tree'>
                              <DropDownTreeComponent
                                id='ddljobreportto'
                                name='ddljobreportto'
                                treeSettings={this.treeSettings}
                                fields={this.props.jfieldsreportto}
                                filterBarPlaceholder='Search a Job'
                                showClearButton={true}
                                allowMultiSelection={true}
                                allowFiltering={true}
                                filterType='Contains'
                                placeholder='Select Job'
                                popupHeight='220px'
                                style={{ fontSize: '15px' }}
                                select={handleDropDownTreeChange}
                                change={handleDropDownTreeClear}
                                blur={handleDropDownOnBlur}
                                mode='Box'
                              ></DropDownTreeComponent>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-6'></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog ? (
            <DialogComponent
              id='dlgEditApplication'
              showCloseIcon={true}
              header={'Reporting To'}
              width={'25vw'}
              visible={this.state.showDialog}
              close={() => this.setState({ showDialog: false })}
              content={this.generateContent.bind(this)}
              isModal={true}
              buttons={[{}]}
              statelessTemplates={[]}
            ></DialogComponent>
        ) : null}
      </>
    );
  }
}

export default AllocationDetail;
