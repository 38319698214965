import React from 'react';
import './RightPanel.css';
import Common from '../../../Util/Common';
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelModel,
  PanelsDirective,
} from '@syncfusion/ej2-react-layouts';
import Reminder from './Reminder';
import TeamMember from './TeamMember';
import UpcomingEvent from './UpcomingEvent';
import { cssClass } from '@syncfusion/ej2-react-lists';

interface Props {
  rootmenu: any;
  selectedmenu: any;
}

export default class RightPanel extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {};

  componentDidMount() {}

  render() {
    const UpdateCount = (count: any) => {
      (document.getElementById('memberCount') as any).innerText = count;
    };

    let panels: object[] = [
      {
        id: 'reminders',
        sizeX: 1,
        sizeY: 1,
        row: 0,
        col: 0,
        header:
          '<div class = "fadeUp">Reminders<i class="ps-2 fa-regular fa-bells"></i></div>',
        content: () => <Reminder />,
      },
      {
        id: 'upcomingevents',
        sizeX: 1,
        sizeY: 1,
        row: 1,
        col: 0,
        cssClass: 'stickydivprev',
        header:
          '<div class="fadeUp">Upcoming Events<i class="ps-2 fa-regular fa-user-clock" style="font-size:16px;"></i></div>',
        content: () => <UpcomingEvent />,
      },
      {
        id: 'teammembers',
        sizeX: 1,
        sizeY: 1,
        row: 2,
        col: 0,
        cssClass: 'stickydiv',
        header: `<div class="fadeUp" id= "team-member">Team Members <span class="e-badge e-badge-primary spancount" style ="color: #fefefe; background-color: #FC4C02"> <label id = "memberCount"></label> </span> <i class="ps-2 fa-regular fa-users"></i></div>`,
        content: () => <TeamMember UpdateCount={UpdateCount} />,
      },
    ];

    return (
      <>
        <div id='dashboardrightpanel' className='control-pane'>
          <DashboardLayoutComponent
            id='rightpanel_dashboard'
            columns={1}
            panels={panels}
            cellSpacing={[20, 20]}
            allowDragging={false}
          >
            {/* <PanelsDirective>
              <PanelDirective
                sizeX={1}
                sizeY={1}
                row={0}
                col={0}
                content={'reminder'} //{pieTemplate}
                header='<div>Reminder</div>'
              />
              <PanelDirective
                sizeX={1}
                sizeY={1}
                row={1}
                col={0}
                content={'events'} //{columnTemplate}
                header='<div>Events</div>'
              />
            </PanelsDirective> */}
          </DashboardLayoutComponent>
          {/* <div className='sample_container card_sample control-section input-content-wrapper'>
            <div className='e-card e-custom-card'>
              <div className='e-card-header'>
                <div className='e-card-header-caption center'>
                  <div className='e-card-header-title name'></div>
                  <div className='e-card-sub-title'></div>
                </div>
              </div>
              <div className='e-card-content'>
                <div className='row'>
                  <div className='col-12'></div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
