import React from 'react';
import './Reminder.css';
import Common from '../../../Util/Common';
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import LeaveApproval from '../../Leave/LeaveApproval';
import DialogConfirm from '../../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

interface AccordionDataItem {
  id: string;
  name: string;
  badge: string;
  icon: string;
  data?: any;
}

interface Props {}

export default class Reminder extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private acdnItems: AccordionDataItem[] = [];
  private acrdn: AccordionComponent | any = undefined;
  private leaveData: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    accrReminder: undefined,
    leaveApprovalDialog: false,
    leaveobject: undefined,
    showViewAll: false,
  };

  componentDidMount() {
    if (!this.state.accrReminder) {
      this.GetPendingLeave();
    }
  }

  private GetPendingLeave() {
    let payload: any = {
      statusId: 2,
      employeeId: this.user.isManager ? null : this.user.employee.id,
    };
    this.acdnItems = [];

    Common.ApiCallAsync(
      'POST',
      // `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getall`,
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getall${
        !this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let data: any = response.data;
        let viewAll: boolean = response.data.length > 1;
        if (viewAll) {
          document
            .querySelector('.dashboard-reminder .sample_container')
            ?.classList.add('overflowhidden');
        }
        if (data.length > 0) {
          if (this.user.employee) {
            let _data: any = data
              .flatMap((i: any) => i.leaveApproval)
              .flatMap((a: any) => a.leaveApprovers);

            let myapprovals: any = _data.filter(
              (x: any) =>
                x.employeeId === this.user.employee.id &&
                x.statusId === this._structure.LeaveApplicationStatus.Pending.Id
            );

            if (myapprovals.length > 0) {
              let myleave: any = [];

              myapprovals.forEach((m: any) => {
                let level: number = m.level;
                let pending: any = _data.filter(
                  (x: any) =>
                    x.leaveApplicationId === m.leaveApplicationId &&
                    x.level <= level
                );

                let a: any = pending.filter((p: any) => p.level < level);
                if (a.length > 0) {
                } else {
                  pending = data.filter(
                    (p: any) =>
                      p.leaveApproval.length > 0 &&
                      p.leaveApproval[0].leaveApprovers.filter(
                        (f: any) =>
                          f.employeeId === this.user.employee.id &&
                          f.leaveApplicationId === m.leaveApplicationId &&
                          f.level === level
                      ).length > 0
                  );
                  if (pending.length > 0) {
                    myleave.push(pending[0]);
                  }
                }
              });

              let idx: number = -1;
              if (myleave.length > 0) {
                if (
                  this.state.accrReminder &&
                  (this.state.accrReminder as any).length > 0
                ) {
                  idx = (this.state.accrReminder as any).findIndex(
                    (x: any) => x.id === 'leave'
                  );

                  if (idx >= 0) {
                    (this.state.accrReminder as any)[idx].data = myleave;
                    (this.state.accrReminder as any)[idx].badge =
                      myleave.length;
                    this.acdnItems = this.state.accrReminder;
                  }
                }

                if (idx < 0) {
                  this.acdnItems.push({
                    id: 'leave',
                    name: 'Leave',
                    badge: `${myleave.length}`,
                    icon: '',
                    data: myleave,
                  });
                }
              }
            }
          }
        }

        let lvidx: number = this.acdnItems.findIndex(
          (x: any) => x.id === 'leave'
        );

        if (lvidx >= 0) {
          this.leaveData = this.acdnItems[lvidx].data;

          if (this.acdnItems[lvidx].data.length > 1) {
            //this.acdnItems[lvidx].data = this.acdnItems[lvidx].data.slice(0, 1);
          }
        } else {
          this.leaveData = null;
        }

        this.setState({
          accrReminder: this.acdnItems,
          leaveApprovalDialog: false,
          showViewAll: viewAll,
        });
        if (this.acdnItems.length <= 0) {
          if (this.acrdn.items.length > 0) {
            let idx: any = this.acrdn.items.findIndex(
              (x: any) => x.id === 'leave'
            );

            if (idx >= 0) {
              this.acrdn.removeItem(idx);
            }
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  /*private GetPendingLeave() {
    let payload: any = {
      maxLeveApprover: 2,
      approversWise: true,
      statusId: 2
    };
    this.acdnItems = [];

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getall`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let data: any = response.data;

        let currlevel = 0;
        if (data.flatMap((item: any) => item.leaveApproval.flatMap((approval: any) => approval.leaveApprovers)).filter((x: any) => x.employeeId == this.user.employee.id).length > 0) {
          currlevel = data.flatMap((item: any) => item.leaveApproval.flatMap((approval: any) => approval.leaveApprovers)).filter((x: any) => x.employeeId == this.user.employee.id)[0].level
        }

        if (currlevel > 1) {
          data = data.filter((x: any) => x.lastApprStatusId === this._structure.LeaveApplicationStatus.Approved.Id);
        }

        let unique = data.reduce(function (a: any, d: any) {
          if (a.filter((x: any) => x.id === d.id).length <= 0) {
            a.push(d);
          }
          return a;
        }, []);

        if (unique.length > 0) {
          let idx: number = -1;
          if (unique.length > 0) {
            if (
              this.state.accrReminder &&
              (this.state.accrReminder as any).length > 0
            ) {
              idx = (this.state.accrReminder as any).findIndex(
                (x: any) => x.id === 'leave'
              );

              if (idx >= 0) {
                (this.state.accrReminder as any)[idx].data = unique;
                (this.state.accrReminder as any)[idx].badge =
                  unique.length;
                this.acdnItems = this.state.accrReminder;
              }
            }

            if (idx < 0) {
              this.acdnItems.push({
                id: 'leave',
                name: 'Leave',
                badge: `${unique.length}`,
                icon: 'fa-solid fa-calendar-check',
                data: unique,
              });
            }
          }
        }

        let lvidx: number = this.acdnItems.findIndex(
          (x: any) => x.id === 'leave'
        );

        if (lvidx >= 0) {
          this.leaveData = this.acdnItems[lvidx].data;

          if (this.acdnItems[lvidx].data.length > 1) {
            this.acdnItems[lvidx].data = this.acdnItems[lvidx].data.slice(0, 1);
          }
        } else {
          this.leaveData = null;
        }

        this.setState({
          accrReminder: this.acdnItems,
          leaveApprovalDialog: false,
        });
        if (this.acdnItems.length <= 0) {
          if (this.acrdn.items.length > 0) {
            let idx: any = this.acrdn.items.findIndex(
              (x: any) => x.id === 'leave'
            );

            if (idx >= 0) {
              this.acrdn.removeItem(idx);
            }
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }
*/

  getDistinctData = (data: any) => {
    const employeeMap: any = {};
    const filteredData = data.filter((obj: any) => {
      const employeeId = obj.employeeId;
      if (!employeeMap[employeeId]) {
        employeeMap[employeeId] = true;
        return true;
      }
      return false;
    });
    return filteredData;
  };

  private ApproveRejectLeave = (statusid: any, leavedata: any, remark: any) => {
    if (!remark) {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Please enter remark for leave rejection.</p>',
      });
      return;
    }

    let allappr: any = leavedata.leaveApproval.flatMap(
      (a: any) => a.leaveApprovers
    );
    let myappr: any = allappr.filter(
      (x: any) => x.employeeId === this.user.employee.id
    );

    let final = false;
    let mylevel = 0;
    let payload: any;

    if (myappr.length > 0) {
      final = myappr[0].isFinal;
      mylevel = myappr[0].level;

      if (statusid == this._structure.LeaveApplicationStatus.Approved.Id) {
        let dateRange: any = [];
        let sRange = Common.getDatesInRange(
          new Date(leavedata.startDate),
          new Date(leavedata.endDate)
        );
        for (let i = 0; i < sRange.length; i++) {
          dateRange.push({
            leaveApplicationId: myappr[0].leaveApplicationId,
            appliedDate: Common.formatDate(
              Common.RemoveTimezoneOffsetFromDate(new Date(sRange[i])),
              'yyyy-MM-dd'
            ),
            dayFraction: 1,
            leaveApprovalId: this.user.employee.id,
          });
        }

        payload = {
          leaveApplicationId: myappr[0].leaveApplicationId,
          employeeId: this.user.employee.id,
          approvalDate: new Date(),
          approvalRemark: remark,
          approvedStartDate: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(new Date(leavedata.startDate)),
            'yyyy-MM-dd'
          ),
          approvedEndDate: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(new Date(leavedata.endDate)),
            'yyyy-MM-dd'
          ),
          approvedDays: sRange.length,
          statusId: statusid,
          isFinal: final,
          leaveAppliedDays: dateRange,
          level: mylevel,
        };
      } else if (statusid == this._structure.LeaveApplicationStatus.Reject.Id) {
        payload = {
          leaveApplicationId: myappr[0].leaveApplicationId,
          employeeId: this.user.employee.id,
          approvalDate: new Date(),
          approvalRemark: remark,
          approvedStartDate: null,
          approvedEndDate: null,
          approvedDays: 0,
          statusId: statusid,
          isFinal: true,
          leaveAppliedDays: null,
          level: mylevel,
        };
      }

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/approveleave`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          this.GetPendingLeave();
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  handleApprSave = (data: any) => {
    if (data) {
      this.GetPendingLeave();
      setTimeout(() => {
        let dlg: any = document.querySelectorAll(
          '#reminder-dlgApplicationApproval .e-dlg-closeicon-btn'
        );
        if (dlg.length > 0) {
          dlg.forEach((d: any) => d.click());
        }
      }, 1000);
    }
  };

  handleApprCancel = () => {
    this.setState({ leaveApprovalDialog: false });
    setTimeout(() => {
      let dlg: any = document.querySelectorAll(
        '#reminder-dlgApplicationApproval .e-dlg-closeicon-btn'
      );
      if (dlg.length > 0) {
        dlg.forEach((d: any) => d.click());
      }
    }, 0);
  };

  render() {
    const onViewAllClick = (e: any) => {
      // let id: string = e.target.dataset.id;
      // if (!id) {
      //   id = e.target.parentElement.dataset.id;
      // }
      // if (id === 'leave') {
      //   let idx: number = (this.state.accrReminder as any).findIndex(
      //     (x: any) => x.id === 'leave'
      //   );
      //   if (idx >= 0) {
      //     let accr: any = this.state.accrReminder;
      //     accr[idx].data = this.leaveData;
      //     this.setState({ accrReminder: accr });
      //   }
      // }
      if (this.state.showViewAll) {
        document
          .querySelector('.dashboard-reminder .sample_container')
          ?.classList.remove('overflowhidden');
      } else {
        document.querySelector(
          '.dashboard-reminder .sample_container .e-acrdn-content'
        )!.scrollTop = 0;
        document
          .querySelector('.dashboard-reminder .sample_container')
          ?.classList.add('overflowhidden');
      }
      this.setState({ showViewAll: !this.state.showViewAll });
    };
    const accordionTemplate = (e: any): JSX.Element => {
      const onApproveClick = (e: any) => {
        let btn: ButtonComponent | any = e.target.ej2_instances[0];
        this.setState({
          leaveApprovalDialog: true,
          leaveobject: btn['data-leave'],
        });
        // this.dialogObj = DialogUtility.confirm({
        //   title: 'Aruti',
        //   content:
        //     '<p>Enter reason for Approval: </p><input id= "inputEleAppr" type="text" name="inputEle" class="e-input" placeholder="Type reason here.." multiline={true} autoComplete="off" />',
        //   okButton: {
        //     text: 'Yes',
        //     click: () =>
        //       this.ApproveRejectLeave(
        //         this._structure.LeaveApplicationStatus.Approved.Id,
        //         btn['data-leave']
        //       ),
        //   },
        //   cancelButton: {},
        // });
      };

      const onDeclineClick = (e: any) => {
        let btn: ButtonComponent | any = e.target.ej2_instances[0];

        DialogConfirm.showDialog({
          isConfirm: true,
          content:
            '<p class="dialog-contain">Enter reason for rejection:</p> <input  id= "inputEleDecl" type="text" name="inputEle" class="e-input bottom-rejecion" placeholder="Type reason here.." multiline={true} autoComplete="off" />',
          okCaption: 'Decline',
          OkEvent: () => {
            const remark = (
              document.getElementById('inputEleDecl') as HTMLInputElement
            ).value;

              this.ApproveRejectLeave(
                this._structure.LeaveApplicationStatus.Reject.Id,
              btn['data-leave'],
              remark
            );
          },
        });
      };

      // const onViewAllClick = (e: any) => {
      //   let id: string = e.target.dataset.id;
      //   if (!id) {
      //     id = e.target.parentElement.dataset.id;
      //   }

      //   if (id === 'leave') {
      //     let idx: number = (this.state.accrReminder as any).findIndex(
      //       (x: any) => x.id === 'leave'
      //     );

      //     if (idx >= 0) {
      //       let accr: any = this.state.accrReminder;

      //       accr[idx].data = this.leaveData;
      //       this.setState({ accrReminder: accr });
      //     }
      //   }
      // };

      const dlgContent = () => {
        return (
          <LeaveApproval
            leaveobject={this.state.leaveobject}
            onSave={this.handleApprSave}
            onCancel={() => this.handleApprCancel()}
            statusid={this._structure.LeaveApplicationStatus.Approved.Id}
          ></LeaveApproval>
        );
      };
      return (
        <>
          <div className='acdnitem' key={Math.random()}>
            {e.data &&
              e.data.map((x: any, idx: any) => {
                return (
                  <div key={idx}>
                    <div key={idx} className='e-card custom-card'>
                      <div className='e-card-content'>
                        <div>
                          {x.leaveType.name} : {x.employee.personName}
                        </div>
                        <div>
                          {Common.formatDate(
                            new Date(x.startDate),
                            this.user.settings.formats.DateShort
                          )}{' '}
                          -{' '}
                          {Common.formatDate(
                            new Date(x.endDate),
                            this.user.settings.formats.DateShort
                          )}
                        </div>
                        <div className='float-right'>
                          {Math.floor(
                            (new Date().getTime() -
                              new Date(x.applyDate).getTime()) /
                              (1000 * 3600 * 24)
                          )}{' '}
                          days ago!
                        </div>
                      </div>
                      <div className='e-card-footer lightborder'>
                        <ButtonComponent
                          style={{
                            boxShadow: 'none',
                            lineHeight: '22px',
                            marginRight: '10px',
                          }}
                          className='main-aruti-btn'
                          data-leave={x}
                          onClick={onApproveClick}
                        >
                          Approve
                        </ButtonComponent>
                        <ButtonComponent
                          style={{
                            boxShadow: 'none',
                            lineHeight: '22px',
                            marginRight: '10px',
                          }}
                          cssClass='main-aruti-btn'
                          data-leave={x}
                          onClick={onDeclineClick}
                        >
                          Decline
                        </ButtonComponent>
                      </div>
                    </div>
                    {/* {e.id === 'leave' &&
                      this.leaveData.length > 1 &&
                      e.data.length !== this.leaveData.length &&
                      e.data.length === idx + 1 ? (
                      <div
                        className='reminder-viewall'
                        data-id={e.id}
                        onClick={onViewAllClick}
                      >
                        Show All
                        <i className='ps-1 fa-solid fa-chevron-down'></i>
                      </div>
                    ) : null} */}
                  </div>
                );
              })}
          </div>

          {this.state.leaveApprovalDialog === true ? (
            <DialogComponent
              id='reminder-dlgApplicationApproval'
              className='dlgClassRes'
              showCloseIcon={true}
              header={'Approve Application'}
              width={'40vw'}
              visible={this.state.leaveApprovalDialog}
              close={() => this.setState({ leaveApprovalDialog: false })}
              content={dlgContent}
              isModal={true}
              buttons={[{}]}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </>
      );
    };
    const animation: any = {
      expand: { effect: '', duration: 0, easing: '' },
      collapse: { effect: '', duration: 0, easing: '' },
    };
    return (
      <div className='dashboard-reminder control-pane h-100'>
        <div className='control-section badge-samples h-100'>
          <div className='sample_container  badge-accordion overflow-auto'>
            <AccordionComponent
              id='accordion-reminder'
              ref={(obj: any) => (this.acrdn = obj)}
              animation={animation}
            >
              <AccordionItemsDirective>
                {this.state.accrReminder &&
                  (this.state.accrReminder as any).map(
                    (item: any, index: any) => (
                      <AccordionItemDirective
                        key={index}
                        id={item.id}
                        header={() => (
                          <div>
                            <i
                              style={{ marginRight: '7px', fontSize: '18px' }}
                              className={`fa-solid fa-calendar-lines-pen`}
                            ></i>
                            <span>{item.name}</span>
                            <span className='e-badge e-badge-primary ms-2'>
                              {item.badge}
                            </span>
                          </div>
                        )}
                        iconCss={` ${item.icon} font-awsm`}
                        // expanded={index === 0}
                        expanded={true}
                        content={() => accordionTemplate(item)}
                      />
                    )
                  )}
              </AccordionItemsDirective>
            </AccordionComponent>
          </div>
          {this.leaveData && this.leaveData.length > 1 && (
            <div className='reminder-viewall' onClick={onViewAllClick}>
              See {this.state.showViewAll ? 'more' : 'less'}
              <i
                style={{ margin: '2px 10px', display: 'inline-block' }}
                className={`ps-1 fa-solid fa-chevron-${
                  this.state.showViewAll ? 'down' : 'up'
                }`}
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  }
}
