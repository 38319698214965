import * as React from 'react';
import Common from '../../../Util/Common';
import './PayrollVarianceReport.css';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  DetailRow,
  Page,
  Sort,
  FilterSettingsModel,
  Filter,
  Toolbar,
  PdfExport,
  ExcelExport,
} from '@syncfusion/ej2-react-grids';
import PeriodSelector from '../../PeriodSelector/PeriodSelector';
import {
  PivotViewComponent,
  IDataOptions,
  FieldList,
  Toolbar as PivotToolbar,
  ExcelExport as PivotExcelExport,
  PDFExport as PivotPDFExport,
  PivotChart,
  IDataSet,
  ConditionalFormatting,
} from '@syncfusion/ej2-react-pivotview';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {}
export default class PayrollVarianceReport extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private pivotObj: PivotViewComponent | any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { dataFlat: undefined, fromMonth: undefined, toMonth: undefined };

  private chartOnLoad(args: any) {
    let selectedTheme = window.location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, 'Dark')
      .replace(/contrast/i, 'Contrast')
      .replace(/-highContrast/i, 'HighContrast');
  }

  private GenerateReport(
    fromperiodid: string,
    toperiodid: string,
    isignorezerovariance: boolean
  ) {
    Common.ShowSpinner();

    let payload: any = {
      FromPeriodId: fromperiodid,
      ToPeriodId: toperiodid,
      IsIgnoreZeroVariance: isignorezerovariance,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrollvariancereport/generate`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let jdataFlat: any = undefined;
        jdataFlat = Common.flattenJSON(data, {}, '', null, [], [], '_');
        if (this.pivotObj) {
          this.pivotObj.dataSourceSettings.dataSource = jdataFlat;
          this.pivotObj.refresh();
        } else {
          this.setState({ dataFlat: jdataFlat });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  render() {
    const handleCheckChange = (checked: any) => {};
    const handleChange = (e: any) => {
      let fromperiod: any = document.getElementById(
        'frompayrollvariancereportperiod-selector'
      );
      let toperiod: any = document.getElementById(
        'topayrollvariancereportperiod-selector'
      );

      let isignorezerovariance: any = document.getElementById(
        'chkisignorezerovariance'
      );

      if (fromperiod == undefined) {
        return;
      }

      if (toperiod == undefined) {
        return;
      }

      this.setState({
        fromMonth: Common.formatDate(
          new Date(fromperiod.dataset.startdate),
          'MMM yy'
        ),
        toMonth: Common.formatDate(
          new Date(toperiod.dataset.startdate),
          'MMM yy'
        ),
      });
      this.GenerateReport(
        fromperiod.dataset.periodid,
        toperiod.dataset.periodid,
        isignorezerovariance.checked
      );
    };
    const handleAsOnDateChange = (e: any) => {};
    const handleDtpCheckChange = (e: any) => {};

    const dataSourceSettings: IDataOptions = {
      enableSorting: true,
      columns: [
        {
          name: 'Months',
          caption: this.state.fromMonth + ' - ' + this.state.toMonth,
        },
      ],
      valueSortSettings: { headerDelimiter: ' - ' },
      values: [
        {
          name: 'FromPeriodAmount',
          caption: this.state.fromMonth,
          expandAll: true,
        },
        {
          name: 'ToPeriodAmount',
          caption: this.state.toMonth,
          expandAll: true,
        },
        { name: 'Variance', caption: 'Variance', expandAll: true },
      ],
      dataSource: this.state.dataFlat,
      rows: [
        { name: 'HeadType', expandAll: true },
        { name: 'HeadName' },
        { name: 'EmployeeName' },
      ],

      formatSettings: [
        {
          name: 'FromPeriodAmount',
          format: this.user.settings.formats.Decimal
            ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
            : 'N2',
        },
        {
          name: 'ToPeriodAmount',
          format: this.user.settings.formats.Decimal
            ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
            : 'N2',
        },
        {
          name: 'Variance',
          format: this.user.settings.formats.Decimal
            ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
            : 'N2',
        },
      ],

      expandAll: false,
      filters: [],
      showGrandTotals: false,
      showColumnGrandTotals: false,
      conditionalFormatSettings: [
        {
          measure: 'Variance',
          value1: 0,
          conditions: 'GreaterThan',

          style: {
            backgroundColor: '#FFEBE9',
            color: '#FF3740',
            // fontFamily: 'Tahoma',
            // fontSize: '12px',
          },
        },
        {
          value1: 0,

          measure: 'Variance',
          conditions: 'LessThan',
          style: {
            backgroundColor: '#DFFFE2',
            color: '#00A653',
            // fontFamily: 'Tahoma',
            // fontSize: '12px',
          },
        },
      ],
    };

    return (
      <>
        <div>
          <PivotViewComponent
            id='pivotpayrollvariancereport'
            className='position-relative'
            dataSourceSettings={dataSourceSettings}
            width={'100%'}
            height={'700'}
            showToolbar={true}
            toolbar={[
              'Grid',
              'Chart',
              'Export',
              { template: '#frompayrollvariancereport-periodctrls' },
              { template: '#topayrollvariancereport-periodctrls' },
              { template: '#divIsIgnoreZeroVariance' },
              // 'FieldList',
            ]}
            showTooltip={false}
            allowPdfExport={true}
            allowExcelExport={true}
            allowDrillThrough={true}
            showFieldList={true}
            allowConditionalFormatting={true}
            displayOption={{ view: 'Both' }}
            gridSettings={{
              columnWidth: 100,
              allowSelection: true,
              selectionSettings: {
                mode: 'Cell',
                type: 'Single',
                cellSelectionMode: 'BoxWithBorder',
              },
            }}
            // toolbarClick={this.toolbarClick.bind(this)}
            // chartSettings={{
            //   title: 'Employee Payroll Variance',
            //   load: this.chartOnLoad.bind(this),
            // }}
            ref={(pivotview: any) => {
              this.pivotObj = pivotview;
            }}
          >
            <Inject
              services={[
                FieldList,
                //CalculatedField,
                PivotToolbar,
                PivotPDFExport,
                PivotExcelExport,
                ConditionalFormatting,
                //NumberFormatting,
                PivotChart,
              ]}
            />
          </PivotViewComponent>
        </div>
        <div>
          <div id='frompayrollvariancereport-periodctrls'>
            <PeriodSelector
              handleInputChange={handleChange}
              id='frompayrollvariancereportperiod-selector'
              _jObject={[]}
              showEmployeePeriodsOnly={false}
              showLastPeriod={true}
              alttext={'No Period Defined.'}
              showNewPeriodOption={false}
              showAsOnDateIfNoPeriod={true}
              showcheckbox={true}
              checked={true}
              handleCheckChange={handleCheckChange}
              handleAsOnDateChange={handleAsOnDateChange}
              handleDtpCheckChange={handleDtpCheckChange}
            />
          </div>
        </div>
        <div>
          <div id='topayrollvariancereport-periodctrls'>
            <PeriodSelector
              handleInputChange={handleChange}
              id='topayrollvariancereportperiod-selector'
              _jObject={[]}
              showEmployeePeriodsOnly={false}
              showLastPeriod={true}
              alttext={'No Period Defined.'}
              showNewPeriodOption={false}
              showAsOnDateIfNoPeriod={true}
              showcheckbox={true}
              checked={true}
              handleCheckChange={handleCheckChange}
              handleAsOnDateChange={handleAsOnDateChange}
              handleDtpCheckChange={handleDtpCheckChange}
            />
          </div>
        </div>
        <div>
          <div id='divIsIgnoreZeroVariance'>
            <CheckBoxComponent
              id='chkisignorezerovariance'
              checked={true}
              label={'Ignore Zero Variance'}
              change={handleChange.bind(this)}
            ></CheckBoxComponent>
          </div>
        </div>
      </>
    );
  }
}
