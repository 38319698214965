import * as React from 'react';
import Common from '../../Util/Common';

import { Dialog, DialogComponent } from '@syncfusion/ej2-react-popups';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './Email.css';
const {
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_API,
} = process.env;

export default class Email extends React.Component<{}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  componentDidMount() {
    this.getEmailSetupDatails();
  }

  state = {
    id: undefined,
    sender_name: undefined,
    email_address: undefined,
    mail_server: undefined,
    server_port: undefined,
    user_name: undefined,
    password: undefined,
    subject: undefined,
    body: undefined,
    isLoading: undefined,
  };

  getEmailSetupDatails = () => {
    try {
      let payload: any = {
        Id: this.state.id,
      };
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/emailsetup/get`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      ).then((response: any) => {
        this.setState({
          id: response.data.id,
          sender_name: response.data.senderName,
          email_address: response.data.emailAddress,
          mail_server: response.data.mailServer,
          server_port: response.data.port,
          user_name: response.data.userName,
          password: response.data.password,
          subject: response.data.subject,
          body: response.data.body,
        });
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleAddDetails = () => {
    try {
      let payload: any = {
        Id: this.state.id,
        SenderName: (document.getElementById('sender_name') as any).value,
        EmailAddress: (document.getElementById('email_address') as any).value,
        MailServer: (document.getElementById('mail_server') as any).value,
        Port: (document.getElementById('server_port') as any).value,
        UserName: (document.getElementById('user_name') as any).value,
        Password: (document.getElementById('password') as any).value,
        Subject: (document.getElementById('subject') as any).value,
        Body: (document.getElementById('body') as any).value,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/emailsetup/add`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      ).then((response: any) => {
        console.log(response.data);
        if (response.data !== null) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Email Setup Save Successfully!!!</p>',
          });
        }
        return response.data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleTestEmail = () => {
    try {
      let payload: any = {
        Id: this.state.id,
        SenderName: (document.getElementById('sender_name') as any).value,
        EmailAddress: (document.getElementById('email_address') as any).value,
        MailServer: (document.getElementById('mail_server') as any).value,
        Port: (document.getElementById('server_port') as any).value,
        UserName: (document.getElementById('user_name') as any).value,
        Password: (document.getElementById('password') as any).value,
        Subject: (document.getElementById('subject') as any).value,
        Body: (document.getElementById('body') as any).value,
      };

      this.setState({ isLoading: true });
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/emailsetup/sendemail`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          console.log(response.data);
          if (Boolean(response.data) === true) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Mail sent successfully!</p>',
            });
          } else {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Mail sending failure!</p>',
            });
          }
          return response.data;
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return this.state.isLoading ? (
      <>
        {Common.SetStatusBar('Loading.....')}
        {Common.ShowSpinner()}
      </>
    ) : (
      <>
        {Common.HideSpinner()}
        <div className='email-container'>
          <div className='col-12 email-header'>
            <label>Company Email Setup</label>
          </div>

          <div className='email-content'>
            <div className='email-sub'>
              <div className='email-subrow'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>Sender Name:</label>
                </div>
                <div className='email-input  sub-input-margin'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='sender_name'
                    name='sender_name'
                    type='text'
                    placeholder='Enter Sender Name'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.sender_name}
                  />
                </div>
              </div>
              <div className='email-subrow2'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>Email Address:</label>
                </div>
                <div className='email-input'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='email_address'
                    name='email_address'
                    type='text'
                    placeholder='Enter Email Address'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.email_address}
                  />
                </div>
              </div>
            </div>

            <div className='email-sub'>
              <div className='email-subrow'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>
                    Mail Server (SMTP)/IP:
                  </label>
                </div>
                <div className='email-input sub-input-margin'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='mail_server'
                    name='mail_server'
                    type='text'
                    placeholder='Enter Mail Server (SMTP)/IP'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.mail_server}
                  />
                </div>
              </div>
              <div className='email-subrow2'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>Mail Server Port:</label>
                </div>
                <div className='email-input'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='server_port'
                    name='server_port'
                    type='text'
                    placeholder='Enter Mail Server Port'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.server_port}
                  />
                </div>
              </div>
            </div>

            <div className='email-sub'>
              <div className='email-subrow'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>User Name:</label>
                </div>
                <div className='email-input  sub-input-margin'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='user_name'
                    name='user_name'
                    type='text'
                    placeholder='Enter User Name'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.user_name}
                  />
                </div>
              </div>
              <div className='email-subrow2'>
                <div className='email-label'>
                  <label style={{ fontSize: '14px' }}>Password:</label>
                </div>
                <div className='email-input'>
                  <TextBoxComponent
                    className='input-text-border'
                    id='password'
                    name='password'
                    type='password'
                    placeholder='Enter Password'
                    autocomplete='off'
                    style={{ fontSize: '14px' }}
                    value={this.state.password}
                  />
                </div>
              </div>
            </div>

            <div className='email-row'>
              <div className='email-label'>
                <label style={{ fontSize: '14px' }}>Subject:</label>
              </div>
              <div className='email-input'>
                <TextBoxComponent
                  className='input-text-border'
                  id='subject'
                  name='subject'
                  type='text'
                  placeholder='Enter Subject'
                  autocomplete='off'
                  style={{ fontSize: '14px' }}
                  value={this.state.subject}
                />
              </div>
            </div>

            <div className='email-row'>
              <div className='email-label'>
                <label style={{ fontSize: '14px' }}>Body:</label>
              </div>
              <div className='email-input textarea-width'>
                <TextBoxComponent
                  id='body'
                  name='body'
                  placeholder='Enter Body'
                  autocomplete='off'
                  style={{ fontSize: '14px' }}
                  multiline={true}
                  value={this.state.body}
                />
              </div>
            </div>
          </div>

          <div className='email-footer'>
            <button
              className='flat-button'
              id='btnCancel'
              style={{
                marginRight: '15px',
              }}
              //   onClick={this.btnHandleClick.bind(this)}
            >
              Cancel
            </button>
            <button
              className='flat-button'
              id='btnAccept'
              style={{
                marginRight: '10px',
              }}
              onClick={this.handleAddDetails.bind(this)}
            >
              Accept
            </button>
            <button
              className='flat-button'
              id='btnTestEmail'
              style={{
                marginRight: '10px',
              }}
              onClick={this.handleTestEmail.bind(this)}
              disabled={this.state.isLoading}
            >
              Test Email
            </button>
          </div>
        </div>
      </>
    );
  }
}
