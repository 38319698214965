import * as React from 'react';
import Common from '../../Util/Common';
import './UserList.css';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  EditSettingsModel,
  Inject,
  Toolbar,
  Edit,
  DialogEditEventArgs,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import User from './User';
import { Dialog } from '@syncfusion/ej2-react-popups';
import SelectorSearch from '../Layout/SelectorSearch';
import { userlist_contextmenu } from '../../dummydata/xml_data_employee';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import DialogConfirm from '../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE } = process.env;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface Props {
  jsonDataViewer: any;
}

export default class UserList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private path: string = '';
  public toolbarOptions: any = [
    'Add',
    'Edit',
    'Delete',
    {
      id: 'userlist-grid_bulkinvite',
      text: 'Bulk Invite',
      tooltipText: 'Bulk Invite',
      prefixIcon: 'fa-solid fa-user-xmark font-14 fontawesome',
      visible: false,
      click: this.bulkInviteClick.bind(this),
    },
  ];
  private grdUser: GridComponent | any = undefined;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private child: User | any;

  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Dialog',
    allowEditOnDblClick: true,
    showDeleteConfirmDialog: true,
    template: (props: any) => {
      return <User {...props} {...this.state} ref={this.child} />;
    },
  };

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.path = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port) {
      this.path += ':' + window.location.port;
    }
    var _result: any;
    parser.parseString(userlist_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });

    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    this.child = React.createRef();
  }

  state = {
    jsonUser: undefined,
    jsonPrivilegeItem: undefined,
    jsonRole: undefined,
    roleName: '',
  };

  componentDidMount() {
    if (!this.state.jsonUser) {
      this.GetUser();
    }

    if (!this.state.jsonPrivilegeItem) {
      this.GetPrivilegeItem();
    }

    if (!this.state.jsonRole) {
      this.GetRole();
    }
  }

  public GetUser() {
    let payload: any = {
      id: null,
      roleId: null,
    };
    if (this.props.jsonDataViewer.payload) {
      if (this.props.jsonDataViewer.name === 'Employee') {
        payload = { isManager: false };
      } else {
        payload = JSON.parse(this.props.jsonDataViewer.payload);
        payload['isManager'] = true;
      }
    }

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users/getusers`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonUser: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetPrivilegeItem() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users/privilegeitem/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonPrivilegeItem: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetRole() {
    Common.ApiCallAsync(
      'GET',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/roles`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonRole: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  private actionBegin = (e: any) => {
    if (
      e.requestType === 'save' &&
      (e.action === 'add' || e.action === 'edit')
    ) {
      let data: any = this.child.current.GetState();
      let isAdd: boolean = e.data.isAdd;
      e.data = data;
      e.data.isAdd = isAdd;

      if (!e.data.firstName) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter first name.</p>',
        });
        return;
      } else if (!e.data.lastName) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter last name.</p>',
        });
        return;
      } else if (!e.data.userName) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter user name.</p>',
        });
        return;
      } else if (e.data.userName.includes(' ')) {
        e.cancel = true;

        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, user name must not contain a space character.</p>',
        });
        return;
      } else if (!e.data.email) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter email.</p>',
        });
        return;
      } else if (e.data.isAdd && !e.data.password) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter password.</p>',
        });
        return;
      } else if (e.data.isAdd && e.data.password.includes(' ')) {
        e.cancel = true;

        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, password must not contain a space character.</p>',
        });
        return;
      } else if (e.data.isAdd && e.data.password.length < 6) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, Password must be at least 6 characters.</p>',
        });
        return;
      } else if (e.data.isAdd && !e.data.confirmPassword) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Please enter confirm password.</p>',
        });
        return;
      } else if (e.data.isAdd && e.data.password !== e.data.confirmPassword) {
        e.cancel = true;

        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, Password and confirm password must be same.</p>',
        });
        return;
      } else if (!e.data.roleId) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select role.</p>',
        });
        return;
      }

      let payload: any = {
        id: e.data.isAdd ? null : e.data.id,
        firstName: e.data.firstName,
        lastName: e.data.lastName,
        email: e.data.email,
        userName: e.data.userName,
        password: e.data.password,
        confirmPassword: e.data.confirmPassword,
        phoneNumber: '',
        isManager: e.data.isManager,
        roleName: (this.state.jsonRole as any).filter(
          (x: any) => x.id === e.data.roleId
        )[0].name,
        referer: this.path,
      };

      if (e.data.isManager && payload.roleName === 'Employee') {
        e.cancel = true;

        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, You cannot assign Employee role to Manager.</p>',
        });
        return;
      }

      e.cancel = true;

      this.SaveData(
        payload,
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users`,
        e.action
      )
        .then((response: any) => {
          if (!response.response) {
            e.cancel = false;
            e.type = 'actionComplete';
            e.requestType = '';
            // if (e.action === 'add') {
            //   (this.grdUser.dataSource as any).unshift(payload);
            // } else if (e.action === 'edit') {
            //   (this.grdUser.dataSource as any)[
            //     this.grdUser.dataSource.findIndex(
            //       (x: any) => x.id === payload.id
            //     )
            //   ] = payload;
            // }
            this.grdUser.refresh();
            this.GetUser();

            let dlgs: any = document.querySelectorAll(
              `#${this.grdUser.id}_dialogEdit_wrapper`
            );

            if (dlgs && dlgs.length > 0) {
              dlgs.forEach((dlg: any) => {
                dlg.parentElement!.style.display = 'none';
              });
            }
          } else {
          }
        })
        .catch((error: any) => {
          // if (~error.response && !error.response.config.data.id) {
          //   e.cancel = true;
          //   (this.state.jsonEmpBank as any).splice(0, 1);
          //   this.grdBank.refresh();
          // }

          return;
        });
    } else if (e.requestType === 'delete') {
      this.SaveData(
        { id: e.data[0].id },
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users/delete`,
        e.action
      )
        .then((response: any) => {
          if (!response.response) {
            this.GetUser();
          } else {
          }
        })
        .catch((error: any) => {
          return;
        });
    }
  };

  private async SaveData(payload: any, url: string, action: string = 'add') {
    let finalresponse: any = null;
    // let ds: any = [...this.grdBank.dataSource];

    await Common.ApiCallAsync(
      'POST',
      url,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
      })
      .catch((error: any) => {
        // if (action === 'delete') {
        //   this.grdBank.dataSource = ds;
        //   this.grdBank.refresh();
        // }
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        // DialogConfirm.showDialog({
        //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,

        // });
        //   finalresponse = error;
        // }
        finalresponse = error;
      })
      .finally(() => {});

    if (finalresponse) {
      return finalresponse;
    }
  }

  private actionComplete = (args: DialogEditEventArgs): void => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog: Dialog = args.dialog as Dialog;
      let header: string = '';
      if (args.requestType === 'add') {
        header = 'Add New ';
        this.setState({ roleName: '' });
      } else {
        header = 'Edit ';

        this.setState({ roleName: (args.rowData as any).roleName });
      }
      dialog.header = header + 'User';

      this.SetDialogCSS(dialog);
    }
  };

  public bulkInviteClick(e: any) {
    if (this.grdUser.getSelectedRecords().length <= 0) {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Please select atleast one user to send bulk invitation.</p>',
      });
      return;
    }

    this.SendInvitation(
      this.grdUser
        .getSelectedRecords()
        .map((x: any) => x.email)
        .toString()
    );
  }

  public SendInvitation(strEmail: string) {
    Common.ShowSpinner();
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/users/resendconfirmationemail`,
      {
        email: strEmail,
        referer: this.path,
      },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        Common.HideSpinner();
        if (response.toString().trim() !== '') {
          DialogConfirm.showDialog({
            content: response,
          });
        } else {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Invitation sent successfully!</p>',
          });
          this.grdUser.refresh();
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  render() {
    const onEmailNotConfirmedClick = (e: any) => {
      let itm: any =
        this.state.jsonUser![
          (this.state.jsonUser as any).findIndex(
            (x: any) => x.id === e.target.closest('.userid').dataset.id
          )
        ];

      if (itm) {
        this.SendInvitation(itm.email);
      }
    };

    const columnTemplate = (e: any) => {
      return (
        <>
          <div
            className='d-flex userid'
            style={{ flexDirection: 'column' }}
            data-id={e.id}
          >
            <div className='d-flex row'>
              <label
                className='col-6'
                style={{ fontSize: '11px', color: 'darkgray' }}
                title='User Id'
              >
                {e.userName}
              </label>
              <label
                className='col-3'
                style={{ fontSize: '11px', color: 'darkgray' }}
                title='Role'
              >
                {e.roleName}
              </label>
              <label
                className='col-3'
                style={{ fontSize: '11px', color: 'darkgray' }}
                title='Manager/Employee'
              >
                {e.isManager ? 'Manager' : 'Employee'}
              </label>
            </div>

            <label
              style={{ fontSize: '13px', color: 'black' }}
              title='User Name'
            >
              {e.firstName} {e.lastName}
            </label>
            <div className='d-flex justify-content-between'>
              <label
                style={{ fontSize: '11px', color: 'darkgray' }}
                title='Email'
              >
                {e.email}
              </label>
              {e.emailConfirmed ? (
                ''
              ) : (
                <i
                  className='font-13 fa-regular fa-envelope cursor-pointer'
                  style={{ color: 'red' }}
                  title='Send Invitation'
                  onClick={onEmailNotConfirmedClick}
                ></i>
              )}
            </div>
          </div>
        </>
      );
    };

    const onCntxmenuClick = (e: any) => {
      this.cMenu?.open(e.clientY, e.clientX);
    };

    const handleMenuClick = (e: any) => {
      if (e.item && e.item.items.length <= 0) {
        if (e.item.parentObj.id === '100') {
          e.item.parentObj.items.forEach((x: any) => {
            if (!x.separator) {
              x.iconCss = 'noicon';
            }
          });
        }
        switch (e.item.id) {
          case '101':
            e.item.iconCss = 'fa-solid fa-check';
            this.grdUser.filterByColumn('emailConfirmed', 'equal', false);
            this.grdUser.showColumns(['checkbox']);
            //this.grdUser.toolbar[3].visible = true;
            document
              .getElementById('userlist-grid_bulkinvite')
              ?.parentElement?.classList.remove('e-hidden');

            break;

          case '103':
            e.item.iconCss = 'fa-solid fa-check';
            this.grdUser.clearFiltering();
            this.grdUser.hideColumns(['checkbox']);
            //this.grdUser.toolbar[3].visible = false;
            document
              .getElementById('userlist-grid_bulkinvite')
              ?.parentElement?.classList.add('e-hidden');
            break;

          default:
            break;
        }
      }
    };

    return (
      <>
        <div className='control-pane divuserlist'>
          <div className='control-section'>
            <label
              className='ps-1 pb-1'
              style={{ fontSize: '20px', fontWeight: '500' }}
            >
              {this.props.jsonDataViewer ? this.props.jsonDataViewer.name : ''}
            </label>
            <div className='row'>
              <div className='col-md-5 position-relative'>
                <div className='three-dots-parent' onClick={onCntxmenuClick}>
                  <div id='userlist-moreoptions' className='three-dots'></div>
                </div>
                <GridComponent
                  id='userlist-grid'
                  className='userlist-grid'
                  dataSource={this.state.jsonUser}
                  height={'calc(100vh - 292px)'}
                  editSettings={this.editSettings}
                  toolbar={this.toolbarOptions}
                  actionBegin={this.actionBegin.bind(this)}
                  actionComplete={this.actionComplete.bind(this)}
                  ref={(obj) => (this.grdUser = obj)}
                  allowFiltering={true}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      headerText='checkbox'
                      field='emailConfirmed1'
                      type='checkbox'
                      width={40}
                      visible={false}
                    />
                    <ColumnDirective
                      headerText='User'
                      field='userName'
                      template={columnTemplate}
                    />
                    <ColumnDirective
                      headerText='User'
                      field='email'
                      visible={false}
                    />
                    <ColumnDirective
                      headerText='User'
                      field='firstName'
                      visible={false}
                    />
                    <ColumnDirective
                      headerText='User'
                      field='lastName'
                      visible={false}
                    />
                    <ColumnDirective
                      headerText='Email Confirmed'
                      field='emailConfirmed'
                      visible={false}
                    />
                  </ColumnsDirective>
                  <Inject services={[Toolbar, Edit]} />
                </GridComponent>
                <SelectorSearch
                  grid_id={'userlist-grid'}
                  showMoreOptions={false}
                />
              </div>
              <div className='col-md-5 d-none'>
                <TreeGridComponent
                  id='userlist-treegrid'
                  className='userlist-treegrid'
                  dataSource={this.state.jsonPrivilegeItem}
                  height={'calc(100vh - 188px)'}
                  treeColumnIndex={0}
                  childMapping='subMenuJsonList'
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      key='id'
                      field='name'
                      headerText='Name'
                    ></ColumnDirective>
                  </ColumnsDirective>
                </TreeGridComponent>
              </div>
            </div>
          </div>
        </div>
        <ContextMenuComponent
          id={Math.random().toString() + 'contextmenu'}
          ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
          items={this.cntxitems}
          select={handleMenuClick}
          target='#userlist-moreoptions'
        />
      </>
    );
  }
}
