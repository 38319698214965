import * as React from 'react';
import Common from '../Util/Common';
import './Attendance.css';
// import './NewAttendance.css';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  DatePickerComponent,
  TimePickerComponent,
  Inject as TimeInject,
  MaskedDateTime
} from '@syncfusion/ej2-react-calendars';
import {
  Edit,
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Toolbar,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  Aggregate,
} from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import DialogConfirm from './Dialogbox/DialogConfirm';
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';

const { REACT_APP_ENDPOINT_ATTENDANCESERVICE, REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  selectorData: any;
}

class Attendance extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  private TimeRangeref: GridComponent | null = null;
  private TimeGrid: GridComponent | null = null;
  private startTimeRef: TimePickerComponent | null = null;
  private endTimeRef: TimePickerComponent | null = null;
  private newSummaryData: any = [];
  private emp_Data: any = [];
  private modifiedArray: any = [];
  private BreakTime: any | null = null;
  private TotalBreakTime: any | null = null;
  private OverTime: any | null = null;
  private TotalOverTime: any | null = null;
  private WorkTime: any | null = null;
  private TotalWorkTime: any | null = null;
  private ShortTime: any | null = null;
  private TotalShortTime: any | null = null;
  private currentRId: number = 0;
  private GridId: any = 'Timesheet';
  private menuItems: MenuItemModel[];
  private menuObj: ContextMenuComponent | any = null;
  private clickedRow: any = undefined;
  private clickedRowId: any = undefined;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.menuItems = [
      { id: '101', text: 'New', iconCss: 'e-icons e-plus' },
      { id: '102', text: 'Edit', iconCss: 'e-icons e-edit' },
      { id: '103', text: 'Delete', iconCss: 'e-icons e-trash' },
    ];

  }

  state = {
    Attendance: [],
    DeleteEmpData: [],
    _startDate: undefined,
    _endDate: undefined,
    selectedDate: undefined,
    showDialog: false,
    _clickedrow: undefined,
    _singleClickedrow: undefined,
    _dlgWidth: '50vw',
    _dlgHeight: '40vh',
    _isEditing: false,
    _dlgInvoker: '',
    _dataInMinutes: false,
    _fulldate: true,
    _holidays: [],
    _leaves: [],
    _year: undefined,
    _datafilled: undefined,
  };

  componentDidMount() {
    let dateStart: any = Common.formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      'yyyy-MM-dd'
    );
    let dateEnd: any = Common.formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      'yyyy-MM-dd'
    );
    this.setState({
      selectedDate: new Date(),
      _startDate: dateStart,
      _endDate: dateEnd,
    });

    let payload: any = {
      startDate: this.state._startDate,
      endDate: this.state._endDate,
    };
    if (this.state.Attendance.length <= 0) {
      this.getAttendance(payload);
    }

    if (this.state._holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear())
    }
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({ _year: new Date().getFullYear(), _holidays: response.data })
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private getAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/getall`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          Attendance: data,
          _datafilled: true
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private updateAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/update`,
      payload,
      Common.getToken() || '',
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.newSummaryData = [];
        let getpayload: any = {
          startDate: this.state._startDate,
          endDate: this.state._endDate,
        };
        this.getAttendance(getpayload);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private addAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/addall`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.newSummaryData = [];
        let getpayload: any = {
          startDate: this.state._startDate,
          endDate: this.state._endDate,
        };
        this.getAttendance(getpayload);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  handleChange = (args: any) => {
    let dateStart: any = Common.formatDate(
      new Date(
        new Date(args.target.value).getFullYear(),
        new Date(args.target.value).getMonth(),
        1
      ),
      'yyyy-MM-dd'
    );
    let dateEnd: any = Common.formatDate(
      new Date(
        new Date(args.target.value).getFullYear(),
        new Date(args.target.value).getMonth() + 1,
        0
      ),
      'yyyy-MM-dd'
    );

    this.setState({
      selectedDate: args.target.value,
      _startDate: dateStart,
      _endDate: dateEnd,
    });
    let payload: any = {
      startDate: this.state._startDate,
      endDate: this.state._endDate,
    };
    this.getAttendance(payload);
    if (this.state._year !== new Date(dateStart).getFullYear()) {
      this.fetchHoliday(new Date(dateStart).getFullYear())
    }
  };

  handleDataDisplay = (args: any) => {
    if (args.itemData.text === 'Minutes') {
      this.setState({ _dataInMinutes: true });
    } else if (args.itemData.text === 'Hours') {
      this.setState({ _dataInMinutes: false });
    }
  };

  handleDateDataDisplay = (args: any) => {
    if (args.itemData.text === 'Date') {
      this.setState({ _fulldate: true });
    } else if (args.itemData.text === 'Day') {
      this.setState({ _fulldate: false });
    }
  };

  private iconTemplate(date: any, data: any) {
    if (date.getDay() !== 0) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <i
              id='TimeAdd'
              onClick={data ? () => { } : this.handleMenuClick.bind(this)}
              className={
                data
                  ? 'e-icons e-plus-small e-disabled'
                  : 'e-icons e-plus-small'
              }
              style={{ cursor: data ? '' : 'pointer' }}
              title='Add'
            ></i>
            <i
              id='TimeEdit'
              onClick={data ? this.handleMenuClick.bind(this) : () => { }}
              className={
                data ? 'e-icons e-edit-2' : 'e-icons e-edit-2 e-disabled'
              }
              style={{ cursor: data ? 'pointer' : '' }}
              title='Edit'
            ></i>
            <i
              id='TimeDelete'
              onClick={data ? this.handleMenuClick.bind(this) : () => { }}
              className={
                data ? 'e-icons e-delete-3' : 'e-icons e-delete-3 e-disabled'
              }
              style={{ cursor: data ? 'pointer' : '' }}
              title='Delete'
            ></i>
          </div>
        </div>
      );
    }
  }

  handleMenuClick(args: any) {
    let _rows = args;
    if (args.target.id === 'TimeAdd') {
      this.doubleclick(_rows);
    } else if (args.target.id === 'TimeEdit') {
      this.doubleclick(args);
    } else if (args.target.id === 'TimeDelete') {
      let _row = _rows.target.parentElement.parentElement.closest('tr');
      if (_row) {
        let payload: any = {
          employeeId: this.props.selectorData.id,
          loginDatetime: Common.formatDate(new Date(Number(_row.id)), 'yyyy-MM-dd')
        }
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/delete`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            let getpayload: any = {
              startDate: this.state._startDate,
              endDate: this.state._endDate,
            };
            this.getAttendance(getpayload);
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => { });

      }
      // this.modifiedArray = this.modifiedArray.filter((item: any) => {
      //   return item.Id !== _row.id;
      // });
      // for (let i = 0; i < _row.cells.length; i++) {
      //   if (i !== 0 && i !== 1 && i !== 2) {
      //     _row.cells[i].innerHTML = '';
      //   }
      // }
    }
  }

  private doubleclick(_data: any) {
    const closestRow = _data.target.closest('tr');
    this.setState({
      _isEditing: true,
      _clickedrow: closestRow,
      showDialog: true,
      _dlgInvoker: 'rowclick',
      _dlgWidth: '35vw',
      _dlgHeight: '40vh',
    });
  }

  private btnHandleClick(args: any) {
    if (args.target.id.toString().toLowerCase() == 'btnaccept') {
      // let _rows: any = this.state._clickedrow;
      // let addPayload: any = [];
      // let updatePayload: any = [];
      // let currRowData = this.modifiedArray.filter(
      //   (item: any) => item.Id == _rows.id
      // );
      // if (currRowData.length > 0) {
      //   currRowData.map((item: any) => {
      //     addPayload.push(
      //       {
      //         employeeId: this.props.selectorData.id,
      //         shiftId: this.props.selectorData.employeeShift[0].shiftId,
      //         logindatetime: item.In,
      //         sourcetype: 1,
      //         baseWorkHours:
      //           this.props.selectorData.employeeShift[0].shift.shiftTran[0]
      //             .workingHour,
      //       },
      //       {
      //         employeeId: this.props.selectorData.id,
      //         shiftId: this.props.selectorData.employeeShift[0].shiftId,
      //         logindatetime: item.Out,
      //         sourcetype: 1,
      //         baseWorkHours:
      //           this.props.selectorData.employeeShift[0].shift.shiftTran[0]
      //             .workingHour,
      //       }
      //     );
      //     updatePayload.push(
      //       {
      //         id: item.Inid,
      //         employeeId: this.props.selectorData.id,
      //         shiftId: this.props.selectorData.employeeShift[0].shiftId,
      //         logindatetime: item.In,
      //         sourcetype: 1,
      //         baseWorkHours:
      //           this.props.selectorData.employeeShift[0].shift.shiftTran[0]
      //             .workingHour,
      //       },
      //       {
      //         id: item.Outid,
      //         employeeId: this.props.selectorData.id,
      //         shiftId: this.props.selectorData.employeeShift[0].shiftId,
      //         logindatetime: item.Out,
      //         sourcetype: 1,
      //         baseWorkHours:
      //           this.props.selectorData.employeeShift[0].shift.shiftTran[0]
      //             .workingHour,
      //       }
      //     );
      //   });
      // }
      // if (_rows.cells[3].innerHTML || _rows.cells[4].innerHTML) {
      //   this.updateAttendance(updatePayload);
      // } else {
      //   this.addAttendance(addPayload);
      // }
      // let inCell = _rows.querySelector('td:nth-child(4)');
      // let outCell = _rows.querySelector('td:nth-child(5)');
      // if (currRowData[0].In) {
      //   inCell.innerHTML = Common.formatDate(
      //     new Date(currRowData[0].In),
      //     'HH:mm'
      //   );
      // } else {
      //   inCell.innerHTML = '';
      // }
      // if (currRowData[currRowData.length - 1].Out) {
      //   outCell.innerHTML = Common.formatDate(
      //     new Date(currRowData[currRowData.length - 1].Out),
      //     'HH:mm'
      //   );
      // } else {
      //   outCell.innerHTML = '';
      // }
    }
    // this.setState({
    //   showDialog: false,
    //   _isEditing: false,
    // });
  }

  private handleStartTimeChange = (args: any) => {
    if (args.value) {

      /* TO BE TESTED  --- START*/
      let nxtctrl = args.target.id.replace('I', 'O')
      if (nxtctrl) {
        if (document.getElementById(nxtctrl)) {
          let minvalue = new Date(args.value).setMinutes(new Date(args.value).getMinutes() + 1);
          (document.getElementById(nxtctrl) as any).ej2_instances[0].min = new Date(minvalue);
        }
      }
      /* TO BE TESTED  --- END*/

      let _row: any = this.state._clickedrow;
      let currDate = new Date(parseFloat(_row.id)).toDateString();
      let currTime = args.value.toTimeString();
      args.value = currDate + ' ' + currTime;
      let startTimeId = args.target.id.split(['-'])[2];
      var newTimeData: any = document.getElementsByClassName(
        startTimeId
      ) as any;
      let Out: any;
      for (let index = 0; index < newTimeData.length; index++) {
        const element = newTimeData[index];
        if (element.id !== args.target.id) {
          Out = element;
        }
      }
      var prevRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId < args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      if (prevRow.length > 0) {
        prevRow = prevRow[prevRow.length - 1];
        if (new Date(prevRow.Out).getTime() >= new Date(args.value).getTime()) {
          DialogConfirm.showDialog({
            showCloseIcon: true,
            closeOnEscape: true,
            content:
              '<p class="dialog-contain">Please enter correct start time.</p> ',
          });
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            this.modifiedArray[itemIndex].In =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
      else {
        var current = this.modifiedArray.filter(
          (item: any) =>
            item.RId == args.target.element.parentElement.parentElement.id &&
            item.Id === args.target.id.split(['-'])[1]
        );
        if (current.length > 0) {
          if (
            new Date(args.value).getTime() >= new Date(current[0].Out).getTime()
          ) {
            DialogConfirm.showDialog({
              showCloseIcon: true,
              closeOnEscape: true,
              content:
                '<p class="dialog-contain">Please enter correct start time.</p> ',
            });
            args.target.element.ej2_instances[0].value = undefined;
          } else {
            let itemIndex = this.modifiedArray.findIndex((item: any) => {
              return (
                item.Id === args.target.id.split('-')[1] &&
                item.RId == args.target.element.parentElement.parentElement.id
              );
            });
            if (itemIndex !== -1) {
              this.modifiedArray[itemIndex].In =
                Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                  .toISOString()
                  .replace('Z', '');
            }
          }
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return item.Id === args.target.id.split('-')[1];
          });
          if (itemIndex !== -1) {
            this.modifiedArray[itemIndex].In =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
    }
  };

  private handleEndTimeChange = (args: any) => {
    if (args.value) {
      let _row: any = this.state._clickedrow;
      let currDate = new Date(parseFloat(_row.id)).toDateString();
      let currTime = args.value.toTimeString();
      args.value = currDate + ' ' + currTime;
      let EndTimeId = args.target.id.split(['-'])[2];
      let newEndTimeData: any = document.getElementsByClassName(
        EndTimeId
      ) as any;
      let In: any;
      for (let index = 0; index < newEndTimeData.length; index++) {
        const element = newEndTimeData[index];
        if (element.id !== args.target.id) {
          In = element;
        }
      }
      var prevRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId < args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      var nextRow = this.modifiedArray.filter(
        (item: any) =>
          item.RId > args.target.element.parentElement.parentElement.id &&
          item.Id == args.target.id.split(['-'])[1]
      );
      if (nextRow.length > 0) {
        if (
          new Date(nextRow[0].In).getTime() <= new Date(args.value).getTime()
        ) {
          DialogConfirm.showDialog({
            showCloseIcon: true,
            closeOnEscape: true,
            content:
              '<p class="dialog-contain">Please enter correct end time.</p> ',
          });
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
      if (prevRow.length > 0) {
        prevRow = prevRow[prevRow.length - 1];
        if (new Date(prevRow.In).getTime() >= new Date(args.value).getTime()) {
          DialogConfirm.showDialog({
            showCloseIcon: true,
            closeOnEscape: true,
            content:
              '<p class="dialog-contain">Please enter correct end time.</p> ',
          });
          args.target.element.ej2_instances[0].value = undefined;
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return (
              item.Id === args.target.id.split('-')[1] &&
              item.RId == args.target.element.parentElement.parentElement.id
            );
          });
          if (itemIndex !== -1) {
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
      else {
        var current = this.modifiedArray.filter(
          (item: any) =>
            item.RId == args.target.element.parentElement.parentElement.id &&
            item.Id === args.target.id.split(['-'])[1]
        );
        if (current.length > 0) {
          if (
            new Date(args.value).getTime() <= new Date(current[0].In).getTime()
          ) {
            DialogConfirm.showDialog({
              showCloseIcon: true,
              closeOnEscape: true,
              content:
                '<p class="dialog-contain">Please enter correct end time.</p> ',
            });
            args.target.element.ej2_instances[0].value = undefined;
          } else {
            let itemIndex = this.modifiedArray.findIndex(
              (item: any) => item.Id === args.target.id.split('-')[1]
            );
            if (itemIndex !== -1) {
              this.modifiedArray[itemIndex].Out =
                Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                  .toISOString()
                  .replace('Z', '');
            }
          }
        } else {
          let itemIndex = this.modifiedArray.findIndex((item: any) => {
            return item.Id === args.target.id.split('-')[1];
          });
          if (itemIndex !== -1) {
            this.modifiedArray[itemIndex].Out =
              Common.RemoveTimezoneOffsetFromDate(new Date(args.value))
                .toISOString()
                .replace('Z', '');
          }
        }
      }
    }
  };

  private TimePickerTemplate = (props: any) => {
    const startTimeId = `I-${props.Id}-${props.In ? props.In.split('T')[1].replaceAll(':', '').split('.')[0] : '00'}` + '|' + props.RId;
    const endTimeId = `O-${props.Id}-${props.Out ? props.Out.split('T')[1].replaceAll(':', '').split('.')[0] : '00'}` + '|' + props.RId;

    let preval: any
    let minval: any
    if (props.RId > 0) {
      preval = this.newSummaryData.filter((x: any) => x.Id == props.Id && x.RId < props.RId)[0].Out;
      minval = new Date(new Date(preval).setMinutes(new Date(preval).getMinutes() + 1));
    }

    // this.newSummaryData.filter(x=> x.Id == props.Id && x.RId < props.RId)[0].Out

    return (
      <div id={props.RId} style={{ display: 'flex', gap: '10px' }}>
        <TimePickerComponent
          enableMask={true}
          format='h:mm a'
          id={startTimeId}
          placeholder='Start Time'
          value={props.In ? new Date(props.In) : undefined}
          onChange={this.handleStartTimeChange}
          ref={(sc) => (this.startTimeRef = sc)}
          className={`${props.In
            ? props.In.split('T')[1].replaceAll(':', '').split('.')[0]
            : '00'
            }`}
          min={minval}
          step={5}
        >
          <TimeInject services={[MaskedDateTime]} />
        </TimePickerComponent>
        <TimePickerComponent
          id={endTimeId}
          enableMask={true}
          format='h:mm a'
          placeholder='End Time'
          value={props.Out ? new Date(props.Out) : undefined}
          onChange={this.handleEndTimeChange}
          ref={(sc) => (this.endTimeRef = sc)}
          className={`${props.In
            ? props.In.split('T')[1].replaceAll(':', '').split('.')[0]
            : '00'
            }`}
          step={5}
        >
          <TimeInject services={[MaskedDateTime]} />
        </TimePickerComponent>
      </div>
    );
  };

  private formatDuration(durationMs: any) {
    if (this.state._dataInMinutes) {
      return durationMs;
    } else {
      const hours = Math.floor(durationMs / 60);
      const minutes = durationMs % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private toolbarClick(args: any) {
    let _row: any = this.state._clickedrow;
    let existingData = this.modifiedArray.filter(
      (item: any) => item.Id == _row.id
    );
    if (args.item.id === 'Timesheet_add') {
      this.currentRId++;
      let newEntry: any = {
        Id: _row.id,
        In: undefined,
        Out: undefined,
        RId: existingData ? existingData.length : this.currentRId,
        Inid: Common.blankguid,
        Outid: Common.blankguid,
        _newAdded: true,
      };
      if (this.TimeRangeref) {
        this.newSummaryData.push(newEntry);
        this.modifiedArray = this.newSummaryData;
        this.TimeRangeref?.addRecord(newEntry, this.newSummaryData.length - 1);
      }
    }
  }

  private DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {

        this.newSummaryData = this.modifiedArray.filter(
          (item: any) => item._newAdded == false);

        if (this.state.showDialog === true) {
          this.setState({ showDialog: false, _isEditing: false, });
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {


        let _rows: any = this.state._clickedrow;
        let addPayload: any = [];
        let updatePayload: any = [];
        let currRowData = this.modifiedArray.filter(
          (item: any) => item.Id == _rows.id
        );
        if (currRowData.length > 0) {

          if (currRowData.filter((x: any) => x.In == undefined || x.Out == undefined).length > 0) {
            DialogConfirm.showDialog({
              showCloseIcon: true,
              closeOnEscape: true,
              content:
                '<p class="dialog-contain">Please enter start and end time.</p> ',
            });
            return;
          }


          currRowData.map((item: any) => {
            addPayload.push(
              {
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.In,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
              },
              {
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.Out,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
              }
            );
            updatePayload.push(
              {
                id: item.Inid,
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.In,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
              },
              {
                id: item.Outid,
                employeeId: this.props.selectorData.id,
                shiftId: this.props.selectorData.employeeShift[0].shiftId,
                logindatetime: item.Out,
                sourcetype: 1,
                baseWorkHours:
                  this.props.selectorData.employeeShift[0].shift.shiftTran[0]
                    .workingHour,
              }
            );
          });
        }
        if (_rows.cells[2].innerHTML || _rows.cells[3].innerHTML) {
          this.updateAttendance(updatePayload);
        } else {
          this.addAttendance(addPayload);
        }
        let inCell = _rows.querySelector('td:nth-child(3)');
        let outCell = _rows.querySelector('td:nth-child(4)');
        if (currRowData[0].In) {
          inCell.innerHTML = Common.formatDate(
            new Date(currRowData[0].In),
            'HH:mm'
          );
        } else {
          inCell.innerHTML = '';
        }
        if (currRowData[currRowData.length - 1].Out) {
          outCell.innerHTML = Common.formatDate(
            new Date(currRowData[currRowData.length - 1].Out),
            'HH:mm'
          );
        } else {
          outCell.innerHTML = '';
        }

        this.setState({
          showDialog: false,
          _isEditing: false,
        });

      }
    },
  ];

  private dialogclose() {
    this.newSummaryData = this.modifiedArray.filter(
      (item: any) => item._newAdded == false);

    this.setState({
      showDialog: false,
      _isEditing: false,
    });
  }

  private dialogContent() {
    var _content: any;
    if (this.state._dlgInvoker == 'rowclick') {
      if (this.state._isEditing == true) {
        let _rows: any = this.state._clickedrow;
        let TimeEData = this.newSummaryData.filter(
          (x: any) => x.Id == _rows.id
        );
        let newBlankEntry = {
          Id: _rows.id,
          In: undefined,
          Out: undefined,
          RId: 0,
          Inid: Common.blankguid,
          Outid: Common.blankguid,
        };
        if (TimeEData.length === 0) {
          TimeEData.push(newBlankEntry);
          if (
            !this.newSummaryData.some(
              (item: any) =>
                item.id === newBlankEntry.Id && item.RId === newBlankEntry.RId
            )
          ) {
            this.newSummaryData.push(newBlankEntry);
            this.modifiedArray = this.newSummaryData;
          }
        }

        _content = (
          <>
            <div
              style={{
                fontSize: '25px',
                borderBottom: '1px solid steelblue',
                paddingBottom: '12px',
              }}
            >
              {Common.formatDate(new Date(parseInt(_rows.id)), 'MMM dd, yyyy (EEE)')}
            </div>
            <div >
              <div style={{ width: '100%', height: '10px' }}></div>
              <div id='attendance'>
                <GridComponent
                  id={this.GridId}
                  dataSource={TimeEData}
                  toolbar={['Add']}
                  height={'25vh'}
                  toolbarClick={this.toolbarClick.bind(this)}
                  editSettings={{ allowAdding: true, allowEditing: true }}
                  ref={(sc) => (this.TimeRangeref = sc)}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      template={this.TimePickerTemplate.bind(this)}
                    />
                  </ColumnsDirective>
                  <Inject services={[Toolbar, Edit]} />
                </GridComponent>
                {/* <div
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    float: 'right',
                    margin: '20px 0px',
                  }}
                >
                  <button
                    className='flat-button'
                    id='btnCancel'
                    style={{
                      marginRight: '15px',
                    }}
                    onClick={this.btnHandleClick.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className='flat-button'
                    id='btnAccept'
                    style={{
                      marginRight: '10px',
                    }}
                    onClick={this.btnHandleClick.bind(this)}
                  >
                    Accept
                  </button>
                </div> */}
              </div>
            </div>
          </>
        );
      }
    }

    return <>{_content}</>;
  }

  /*
  private process_day() {
    this.TotalBreakTime = null;
    this.TotalWorkTime = null;
    this.TotalOverTime = null;
    this.TotalShortTime = null;
    let _final: any = [];
    let emp_shift = this.props.selectorData.employeeShift.some((item: any) => {
      return item.shift.code === 'GS' && item.shift.name === 'General Shift';
    });
    if (
      this.state._startDate !== undefined &&
      this.state._endDate !== undefined
    ) {
      const date = new Date(this.state._startDate as any);
      while (date <= new Date(this.state._endDate as any)) {
        let classes: any = [];
        classes.push('tr');
        classes.push('text-center');
        let _data: any = undefined;
        if (this.state.Attendance !== undefined) {
          var data = (this.state.Attendance as any).filter((item: any) => {
            if (
              item.employeeId ==
              this.props.selectorData.employeeShift[0].employeeId
            ) {
              return (
                new Date(item.logindatetime).getDate() ==
                new Date(date).getDate() &&
                new Date(item.logindatetime).getMonth() ==
                new Date(date).getMonth()
              );
            }
          });
        }
        if (
          this.newSummaryData.filter(
            (f: any) => f.Id == new Date(date).getTime().toString()
          ).length <= 0
        ) {
          let In: any = undefined;
          let Out: any = undefined;
          let Inid: any = undefined;
          let Outid: any = undefined;
          var _InTime: { [key: number]: any } = {};
          var _OutTime: { [key: number]: any } = {};
          var _InTimeId: { [key: number]: any } = {};
          var _OutTimeId: { [key: number]: any } = {};
          var index: any = 0;
          var TotalLen: any = 0;
          if (data?.length > 0) {
            data.forEach((item: any) => {
              if (item.typeid == '1') {
                if (_InTime[index]) {
                  _OutTime[index] = undefined;
                  index++;
                }
                _InTime[index] = item.logindatetime;
                _InTimeId[index] = item.id;
              } else if (item.typeid == '2') {
                _OutTime[index] = item.logindatetime;
                _OutTimeId[index] = item.id;
                if (_InTime[index] == undefined) {
                  _InTime[index] = undefined;
                  index++;
                }
              }
              if (_InTime[index] && _OutTime[index]) {
                index++;
              }
            });
 
            if (Object.keys(_InTime).length > Object.keys(_OutTime).length) {
              TotalLen = Object.keys(_InTime).length;
            } else {
              TotalLen = Object.keys(_OutTime).length;
            }
            for (let index = 0; index < TotalLen; index++) {
              In = _InTime[index];
              Out = _OutTime[index];
              Inid = _InTimeId[index];
              Outid = _OutTimeId[index];
              this.newSummaryData.push({
                Id: new Date(date).getTime().toString(),
                In,
                Out,
                RId: index,
                Inid,
                Outid,
              });
            }
            this.modifiedArray = this.newSummaryData;
          }
        }
 
        if (data?.length > 0) {
          _data = data;
          this.BreakTime = _data[0].totalBreak;
          this.WorkTime = _data[0].totalWorked;
          this.OverTime = _data[0].totalOvertime;
          this.ShortTime = _data[0].totalShort;
 
          this.TotalBreakTime = this.TotalBreakTime += _data[0].totalBreak;
          this.TotalWorkTime = this.TotalWorkTime += _data[0].totalWorked;
          this.TotalOverTime = this.TotalOverTime += _data[0].totalOvertime;
          this.TotalShortTime = this.TotalShortTime += _data[0].totalShort;
        }
        var _elem = this.generate_Element(date, _data);
 
        _final
          .push
          // <tr
          //   id={new Date(date).getTime().toString()}
          //   className={classes.join(' ')}
          //   style={{
          //     backgroundColor: `${
          //       emp_shift
          //         ? new Date(date).getDay() === 0
          //           ? '#faf0e6b5'
          //           : 'transparent'
          //         : new Date(date).getDay() === 6
          //         ? ' whitesmoke'
          //         : 'transparent' && new Date(date).getDay() === 0
          //         ? '#faf0e6b5'
          //         : 'transparent'
          //     }`,
          //   }}
          // >
          //   {_elem}
          // </tr>
          ();
        date.setDate(date.getDate() + 1);
      }
    }
    return _final;
  }
  */

  private generate_Element(date: Date, data?: any) {
    let classes: any = [];
    classes.push('tr');
    classes.push('text-center');
    let element: any = [];
    element.push(<td className='bodytd1'>{this.iconTemplate(date, data)}</td>);
    element.push(
      <td className='bodytd1'>
        {this.state._fulldate
          ? Common.formatDate(date, 'dd-MM-yyyy')
          : Common.formatDate(date, 'dd')}
      </td>
    );
    element.push(
      <td className='bodytd2'>{Common.formatDate(date, 'EEEE')}</td>
    );
    if (data) {
      element.push(
        <td
          id='span1'
          className='bodytd4'
          onDoubleClick={this.doubleclick.bind(this)}
        >
          {Common.formatDate(new Date(data[0].logindatetime), 'HH:mm')}
        </td>
      );
      element.push(
        <td
          id='span2'
          className='bodytd4'
          onDoubleClick={this.doubleclick.bind(this)}
        >
          {Common.formatDate(
            new Date(data[data.length - 1]?.logindatetime),
            'HH:mm'
          )}
        </td>
      );
      element.push(
        <td
          id='span3'
          className='bodytd5'
          style={{ textAlign: this.state._dataInMinutes ? 'right' : 'center' }}
        >
          {this.state._dataInMinutes
            ? this.BreakTime
            : this.formatDuration(this.BreakTime)}
        </td>
      );
      element.push(
        <td
          id='span4'
          className='bodytd6'
          style={{ textAlign: this.state._dataInMinutes ? 'right' : 'center' }}
        >
          {this.state._dataInMinutes
            ? this.WorkTime
            : this.formatDuration(this.WorkTime)}
        </td>
      );
      element.push(
        <td
          id='span5'
          className='bodytd7'
          style={{ textAlign: this.state._dataInMinutes ? 'right' : 'center' }}
        >
          {this.state._dataInMinutes
            ? this.OverTime
            : this.formatDuration(this.OverTime)}
        </td>
      );
      element.push(
        <td
          id='span6'
          className='bodytd8'
          style={{ textAlign: this.state._dataInMinutes ? 'right' : 'center' }}
        >
          {this.state._dataInMinutes
            ? this.ShortTime
            : this.formatDuration(this.ShortTime)}
        </td>
      );
    } else {
      element.push(
        <td
          id='span1'
          className='bodytd4'
          onDoubleClick={this.doubleclick.bind(this)}
        ></td>
      );
      element.push(
        <td
          id='span2'
          className='bodytd4'
          onDoubleClick={this.doubleclick.bind(this)}
        ></td>
      );
      element.push(<td id='span3' className='bodytd5'></td>);
      element.push(<td id='span4' className='bodytd6'></td>);
      element.push(<td id='span5' className='bodytd7'></td>);
      element.push(<td id='span6' className='bodytd8'></td>);
    }
    // element.push(<td className='bodytd9'></td>);
    // element.push(<td className='bodytd10'></td>);

    return element;
  }

  private customAggregate(args: any) {
    let totalBreakTime: any = 0;
    let totalWorkedTime: any = 0;
    let totalOverTime: any = 0;
    let totalShortTime: any = 0;
    args.result.forEach((item: any) => {
      if (item.BreakTime) {
        if (Number(item.BreakTime)) {
          totalBreakTime += item.BreakTime;
        } else if (item.BreakTime.includes(':')) {
          const [hours, minutes] = item.BreakTime.split(':').map(Number);
          totalBreakTime += hours * 60 + minutes;
        }
      }
      if (item.WorkedTime) {
        if (Number(item.WorkedTime)) {
          totalWorkedTime += item.WorkedTime;
        } else if (item.WorkedTime.includes(':')) {
          const [hours, minutes] = item.WorkedTime.split(':').map(Number);
          totalWorkedTime += hours * 60 + minutes;
        }
      }
      if (item.OverTime) {
        if (Number(item.OverTime)) {
          totalOverTime += item.OverTime;
        } else if (item.OverTime.includes(':')) {
          const [hours, minutes] = item.OverTime.split(':').map(Number);
          totalOverTime += hours * 60 + minutes;
        }
      }
      if (item.ShortTime) {
        if (Number(item.ShortTime)) {
          totalShortTime += item.ShortTime;
        } else if (item.ShortTime.includes(':')) {
          const [hours, minutes] = item.ShortTime.split(':').map(Number);
          totalShortTime += hours * 60 + minutes;
        }
      }
    });
    return { totalBreakTime, totalWorkedTime, totalOverTime, totalShortTime };
  }

  private TotalBreakTemplate(_props: any) {
    const totalBreakTime = _props.Custom?.totalBreakTime || 0;
    if (this.state._dataInMinutes) {
      return totalBreakTime;
    } else {
      const hours = Math.floor(totalBreakTime / 60);
      const minutes = totalBreakTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalWorkedTemplate(_props: any) {
    const totalWorkedTime = _props.Custom?.totalWorkedTime || 0;
    if (this.state._dataInMinutes) {
      return totalWorkedTime;
    } else {
      const hours = Math.floor(totalWorkedTime / 60);
      const minutes = totalWorkedTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalOverTimeTemplate(_props: any) {
    const totalOverTime = _props.Custom?.totalOverTime || 0;
    if (this.state._dataInMinutes) {
      return totalOverTime;
    } else {
      const hours = Math.floor(totalOverTime / 60);
      const minutes = totalOverTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private TotalShortTemplate(_props: any) {
    const totalShortTime = _props.Custom?.totalShortTime || 0;
    if (this.state._dataInMinutes) {
      return totalShortTime;
    } else {
      const hours = Math.floor(totalShortTime / 60);
      const minutes = totalShortTime % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }
  }

  private captionTemplate() {
    return <span>Total {this.state._dataInMinutes ? 'Minutes' : 'Hours'}</span>;
  }

  private commandTemplate(args: any) {
    if (args.Day !== 'Sunday') {
      return (
        <div
          key={Math.random()}
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <i
            key={Math.random()}
            id='TimeAdd'
            onClick={
              args.In && args.Out ? () => { } : this.handleMenuClick.bind(this)
            }
            className={
              args.In && args.Out
                ? 'e-icons e-plus-small e-disabled'
                : 'e-icons e-plus-small'
            }
            style={{ cursor: args.In && args.Out ? '' : 'pointer' }}
            title='Add'
          ></i>
          <i
            key={Math.random()}
            id='TimeEdit'
            onClick={
              args.In && args.Out ? this.handleMenuClick.bind(this) : () => { }
            }
            className={
              args.In && args.Out
                ? 'e-icons e-edit-2'
                : 'e-icons e-edit-2 e-disabled'
            }
            style={{ cursor: args.In && args.Out ? 'pointer' : '' }}
            title='Edit'
          ></i>
          <i
            key={Math.random()}
            id='TimeDelete'
            onClick={
              args.In && args.Out ? this.handleMenuClick.bind(this) : () => { }
            }
            className={
              args.In && args.Out
                ? 'e-icons e-delete-3'
                : 'e-icons e-delete-3 e-disabled'
            }
            style={{ cursor: args.In && args.Out ? 'pointer' : '' }}
            title='Delete'
          ></i>
        </div>
      );
    }
  }

  private onDataBound() {
    if (this.TimeGrid) {
      let GridDataSource: any = this.TimeGrid.dataSource;
      let table = this.TimeGrid.element.querySelector(
        '.e-gridcontent .e-content tbody'
      );
      let rows = this.TimeGrid.element.querySelectorAll(
        '.e-gridcontent .e-content tbody tr'
      );
      rows.forEach((row: any, index: any) => {
        let rowId = GridDataSource[index]?.id;
        if (GridDataSource[index]?.isHoliday == true) {
          const cells = row.querySelectorAll('.e-rowcell');
          cells.forEach((cell: any) => {
            cell.style.color = 'red';
            if (cell.cellIndex === 2) {
              cell.textContent = `${GridDataSource[index]?.ToolTip}`;
              cell.setAttribute('colSpan', 2);
              cell.style.textAlign = 'center';
              cell.setAttribute('title', `${GridDataSource[index]?.ToolTip}`);
            }
            else if (cell.cellIndex === 3) {
              cell.style.display = 'none';
            }
          });
        }
        // <span className="e-badge e-badge-info e-badge-overlap e-badge-dot"></span>
        if (GridDataSource[index]?.isMultiple == true) {
          const cells = row.querySelectorAll('.e-rowcell');
          if (cells && cells.length > 0) {
            cells[1].textContent = cells[1].textContent + ' *';
            cells[1].setAttribute('title', 'Has Multiple Entries (' + `${GridDataSource[index]?.totaldata}` + ')');
          }
        }
        const cells = row.querySelectorAll('.e-rowcell');
        cells.forEach((cell: any) => {
          if (cell.cellIndex === 2 || cell.cellIndex === 3) {
            cell.style.cursor = 'pointer';
          }
        });
        if (GridDataSource[index]?.DayId == 0) {
          row.style.backgroundColor = 'Linen'
        }
        if (GridDataSource[index]?.DayId == 6) {
          row.style.backgroundColor = 'whitesmoke'
        }
        row.setAttribute('id', rowId);
      });

      table?.addEventListener('click', (event: any) => {
        const target = event.target;
        if (event.target.style.color !== 'red') {
          if (target && target.closest('td.e-rowcell')) {
            const rowIndex = target.closest('td.e-rowcell').cellIndex;
            if (rowIndex === 2 || rowIndex === 3) {
              this.doubleclick(event);
            }
          }
        }
      });
    }
  }

  public updateDimensions = () => {
    const divElement: HTMLElement = document.getElementById('attendance') as HTMLElement;
    if (!divElement) return;
    const gridHeader: HTMLElement = divElement?.querySelector(
      'e-gridheader.e-stackedheader.e-lib.e-droppable'
    ) as HTMLElement;
    if (gridHeader) {
      gridHeader.style.paddingRight = '0px';
    }

    const gridFooter: HTMLElement = divElement?.querySelector('e-grid.e-footerpadding') as HTMLElement;
    if (gridFooter) {
      gridFooter.style.paddingRight = '0px';
    }
  };

  private onMenuItemSelect(e: any) {
    if (this.clickedRow !== undefined) {
      if (e.item.id === '101') {
        this.doubleclick(this.clickedRow);
      } else if (e.item.id === '102') {
        this.doubleclick(this.clickedRow);
      } if (e.item.id === '103') {
        let payload: any = {
          employeeId: this.props.selectorData.id,
          loginDatetime: Common.formatDate(new Date(Number(this.clickedRowId)), 'yyyy-MM-dd')
        }
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/delete`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            this.newSummaryData = [];
            this.clickedRow = undefined;
            this.clickedRowId = undefined;
            let getpayload: any = {
              startDate: this.state._startDate,
              endDate: this.state._endDate,
            };
            this.getAttendance(getpayload);
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => { });
      }
    }
  }

  private onContextMenuBeforeOpen(e: any) {
    if (e) {
      if (Number(e.event.target.dataset['colindex']) == 2 || Number(e.event.target.dataset['colindex']) == 3) {
        const data = e.event.target.closest('tr');
        if (data.querySelector('td:nth-child(3)').textContent == '') {
          this.menuObj.enableItems(['New'], true);
          this.menuObj.enableItems(['Edit'], false);
          this.menuObj.enableItems(['Delete'], false);
        }
        if (data.querySelector('td:nth-child(3)').textContent !== '') {
          this.menuObj.enableItems(['New'], false);
          this.menuObj.enableItems(['Edit'], true);
          this.menuObj.enableItems(['Delete'], true);
        }
        this.clickedRow = e.event;
        this.clickedRowId = data.id;
      }
      else {
        e.cancel = true;
      }
    }
  }

  render() {

    this.updateDimensions();

    let DateColumns: any = [
      {
        field: 'Date',
        headerText: 'Date',
        width: 50,
        textAlign: 'Left',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'Day',
        headerText: 'Week Day',
        width: 60,
        textAlign: 'Left',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
    ];
    let TimeColumns: any = [
      {
        field: 'BreakTime',
        headerText: 'Break',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'WorkedTime',
        headerText: 'Regular',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'OverTime',
        headerText: 'OverTime',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
      {
        field: 'ShortTime',
        headerText: 'Short',
        width: 60,
        textAlign: 'Right',
        minWidth: 10,
        customAttributes: { class: 'customHeaderCss' },
      },
    ];

    if (this.state._datafilled !== undefined) {
      if (
        this.state._startDate !== undefined &&
        this.state._endDate !== undefined
      ) {
        const date = new Date(this.state._startDate as any);
        this.emp_Data = [];
        let rcount = 0;
        while (date <= new Date(this.state._endDate as any)) {
          var data = (this.state.Attendance as any).filter((item: any) => {
            if (item.employeeId === this.props.selectorData.id) {
              return (
                new Date(item.logindatetime).getDate() ===
                new Date(date).getDate() &&
                new Date(item.logindatetime).getMonth() ===
                new Date(date).getMonth()
              );
            }
          });

          let isHoliday: boolean = false;
          let hName: string = ''

          if (this.state._holidays.length > 0) {
            if (this.state._holidays.filter((x: any) => new Date(x.date).getDate() === new Date(date).getDate() && new Date(x.date).getMonth() === new Date(date).getMonth()).length > 0) {
              isHoliday = true;
              hName = this.state._holidays.filter((x: any) => new Date(x.date).getDate() === new Date(date).getDate() && new Date(x.date).getMonth() === new Date(date).getMonth()).map((i: any) => i.name)
                .join(', ');
            }
          }

          if (this.state._leaves.length > 0) {

          }

          if (
            this.emp_Data.filter(
              (item: any) => item.Id == new Date(date).getTime().toString()
            ).length <= 0
          ) {
            this.emp_Data.push({
              id: new Date(date).getTime().toString(),
              RowId: Common.formatDate(new Date(date), 'yyyyMMdd'),
              EmplyoeeId: data[0]?.employeeId,
              Employee: data[0]?.employee,
              ShiftId: data[0]?.shiftId,
              Date: this.state._fulldate
                ? Common.formatDate(date, 'dd-MM-yyyy')
                : Common.formatDate(date, 'dd'),
              Day: Common.formatDate(date, 'EEEE'),
              In: data[0]?.logindatetime
                ? Common.formatDate(new Date(data[0]?.logindatetime), 'HH:mm')
                : '',
              Out: data[data.length - 1]?.logindatetime
                ? Common.formatDate(
                  new Date(data[data.length - 1]?.logindatetime),
                  'HH:mm'
                )
                : '',
              BreakTime: this.state._dataInMinutes
                ? typeof data[0]?.totalBreak === 'number'
                  ? data[0]?.totalBreak
                  : ''
                : typeof data[0]?.totalBreak === 'number'
                  ? this.formatDuration(data[0]?.totalBreak)
                  : '',
              WorkedTime: this.state._dataInMinutes
                ? typeof data[0]?.totalWorked === 'number'
                  ? data[0]?.totalWorked
                  : ''
                : typeof data[0]?.totalWorked === 'number'
                  ? this.formatDuration(data[0]?.totalWorked)
                  : '',
              OverTime: this.state._dataInMinutes
                ? typeof data[0]?.totalOvertime === 'number'
                  ? data[0]?.totalOvertime
                  : ''
                : typeof data[0]?.totalOvertime === 'number'
                  ? this.formatDuration(data[0]?.totalOvertime)
                  : '',
              ShortTime: this.state._dataInMinutes
                ? typeof data[0]?.totalShort === 'number'
                  ? data[0]?.totalShort
                  : ''
                : typeof data[0]?.totalShort === 'number'
                  ? this.formatDuration(data[0]?.totalShort)
                  : '',
              isMultiple: data.length > 2 ? true : false,
              isHoliday: isHoliday,
              ToolTip: hName,
              DayId: date.getDay(),
              rowidx: rcount,
              totaldata: data.length / 2,
            });
          }
          if (
            this.newSummaryData.filter(
              (f: any) => f.Id == new Date(date).getTime().toString()
            ).length <= 0
          ) {
            let In: any = undefined;
            let Out: any = undefined;
            let Inid: any = undefined;
            let Outid: any = undefined;
            var _InTime: { [key: number]: any } = {};
            var _OutTime: { [key: number]: any } = {};
            var _InTimeId: { [key: number]: any } = {};
            var _OutTimeId: { [key: number]: any } = {};
            var _newAdded: any = false;
            var index: any = 0;
            var TotalLen: any = 0;
            if (data.length > 0) {
              for (let d = 0; d < data.length; d++) {
                const item = data[d];

                if (item.typeid == '1') {
                  if (_InTime[index]) {
                    _OutTime[index] = undefined;
                    index++;
                  }
                  _InTime[index] = item.logindatetime;
                  _InTimeId[index] = item.id;
                } else if (item.typeid == '2') {
                  _OutTime[index] = item.logindatetime;
                  _OutTimeId[index] = item.id;
                  if (_InTime[index] == undefined) {
                    _InTime[index] = undefined;
                    index++;
                  }
                }
                if (_InTime[index] && _OutTime[index]) {
                  index++;
                }

              }
              // data.forEach((item: any) => {
              //   if (item.typeid == '1') {
              //     if (_InTime[index]) {
              //       _OutTime[index] = undefined;
              //       index++;
              //     }
              //     _InTime[index] = item.logindatetime;
              //     _InTimeId[index] = item.id;
              //   } else if (item.typeid == '2') {
              //     _OutTime[index] = item.logindatetime;
              //     _OutTimeId[index] = item.id;
              //     if (_InTime[index] == undefined) {
              //       _InTime[index] = undefined;
              //       index++;
              //     }
              //   }
              //   if (_InTime[index] && _OutTime[index]) {
              //     index++;
              //   }
              // });

              if (Object.keys(_InTime).length > Object.keys(_OutTime).length) {
                TotalLen = Object.keys(_InTime).length;
              } else {
                TotalLen = Object.keys(_OutTime).length;
              }
              for (let index = 0; index < TotalLen; index++) {
                In = _InTime[index];
                Out = _OutTime[index];
                Inid = _InTimeId[index];
                Outid = _OutTimeId[index];
                this.newSummaryData.push({
                  Id: new Date(date).getTime().toString(),
                  In,
                  Out,
                  RId: index,
                  Inid,
                  Outid,
                  _newAdded,
                });
              }
              this.modifiedArray = this.newSummaryData;
            }
          }
          date.setDate(date.getDate() + 1);
          rcount += 1;
        }
      }
    }

    return (
      // <>
      //   <div id='attMainTable' style={{ display: 'grid' }}>
      //     {/* <div>
      //       <div id='attDetails' className='table-container'>
      //         <table id='atttable' className='itable'>
      //           <thead className='thead'>
      //             <tr>
      //               <th colSpan={2} rowSpan={2} className='td1'></th>
      //               <th colSpan={2} rowSpan={2} className='td2'>
      //                 In
      //               </th>
      //               <th colSpan={2} rowSpan={2} className='td3'>
      //                 Out
      //               </th>
      //               <th colSpan={6} className='td4'>
      //                 Work Time (
      //                 {this.state._dataInMinutes ? 'Minutes' : 'Hours'})
      //               </th>
      //             </tr>
      //             <tr>
      //               <th className='td8'>Break</th>
      //               <th className='td8'>Regular</th>
      //               <th className='td8'>Overtime</th>
      //               <th className='td8'>Short</th>
      //               <th className='td8'>Note</th>
      //               <th className='td9'>Approval</th>
      //             </tr>
      //             <tr className='blank-space'></tr>
      //           </thead>
      //           <tbody className='tbody'>{this.process_day()}</tbody>
      //         </table>
      //       </div>
      //       <div id='attSummary' className='summary'>
      //         <table className='summary-table'>
      //           <tr>
      //             <td className='summary-td1'>Total Hours</td>
      //             <td className='summary-td2'>
      //               {this.state._dataInMinutes
      //                 ? this.TotalBreakTime
      //                 : this.formatDuration(this.TotalBreakTime)}
      //             </td>
      //             <td className='summary-td3'>
      //               {this.state._dataInMinutes
      //                 ? this.TotalWorkTime
      //                 : this.formatDuration(this.TotalWorkTime)}
      //             </td>
      //             <td className='summary-td4'>
      //               {this.state._dataInMinutes
      //                 ? this.TotalOverTime
      //                 : this.formatDuration(this.TotalOverTime)}
      //             </td>
      //             <td className='summary-td5'>
      //               {this.state._dataInMinutes
      //                 ? this.TotalShortTime
      //                 : this.formatDuration(this.TotalShortTime)}
      //             </td>
      //             <td className='summary-td6'></td>
      //             <td className='summary-td7'></td>
      //           </tr>
      //         </table>
      //       </div>
      //     </div> */}
      //     <div>
      //       <div id='attDetails' className='table-container'>
      //         <table id='atttable' className='itable'>
      //           <thead className='thead'>
      //             <tr>
      //               <th colSpan={3} rowSpan={2} className='td1'></th>
      //               <th colSpan={2} rowSpan={2} className='td2'>
      //                 In
      //               </th>
      //               <th colSpan={2} rowSpan={2} className='td3'>
      //                 Out
      //               </th>
      //               <th colSpan={4} className='td4'>
      //                 Work Time (
      //                 {this.state._dataInMinutes ? 'Minutes' : 'Hours'})
      //               </th>
      //             </tr>
      //             <tr>
      //               <th className='td8'>Break</th>
      //               <th className='td8'>Regular</th>
      //               <th className='td8'>Overtime</th>
      //               <th className='td8'>Short</th>
      //               {/* <th className='td8'>Note</th>
      //               <th className='td9'>Approval</th> */}
      //             </tr>
      //             <tr className='blank-space'></tr>
      //           </thead>
      //           <tbody className='tbody'>{this.process_day()}</tbody>
      //         </table>
      //       </div>
      //       <div id='attSummary' className='summary'>
      //         <table className='summary-table'>
      //           <tfoot className='tfoot'>
      //             <tr>
      //               <td className='summary-td1'>
      //                 Total {this.state._dataInMinutes ? 'Minutes' : 'Hours'}
      //               </td>
      //               <td
      //                 className='summary-td2'
      //                 style={{
      //                   textAlign: this.state._dataInMinutes
      //                     ? 'right'
      //                     : 'center',
      //                 }}
      //               >
      //                 {this.state._dataInMinutes
      //                   ? this.TotalBreakTime
      //                   : this.formatDuration(this.TotalBreakTime)}
      //               </td>
      //               <td
      //                 className='summary-td3'
      //                 style={{
      //                   textAlign: this.state._dataInMinutes
      //                     ? 'right'
      //                     : 'center',
      //                 }}
      //               >
      //                 {this.state._dataInMinutes
      //                   ? this.TotalWorkTime
      //                   : this.formatDuration(this.TotalWorkTime)}
      //               </td>
      //               <td
      //                 className='summary-td4'
      //                 style={{
      //                   textAlign: this.state._dataInMinutes
      //                     ? 'right'
      //                     : 'center',
      //                 }}
      //               >
      //                 {this.state._dataInMinutes
      //                   ? this.TotalOverTime
      //                   : this.formatDuration(this.TotalOverTime)}
      //               </td>
      //               <td
      //                 className='summary-td5'
      //                 style={{
      //                   textAlign: this.state._dataInMinutes
      //                     ? 'right'
      //                     : 'center',
      //                 }}
      //               >
      //                 {this.state._dataInMinutes
      //                   ? this.TotalShortTime
      //                   : this.formatDuration(this.TotalShortTime)}
      //               </td>
      //               {/* <td className='summary-td6'></td>
      //               <td className='summary-td7'></td> */}
      //             </tr>
      //           </tfoot>
      //         </table>
      //       </div>
      //     </div>
      //     <div>
      //       {this.state.showDialog === true ? (
      //         <DialogComponent
      //           id='defaultDialog1'
      //           showCloseIcon={true}
      //           visible={this.state.showDialog}
      //           width={this.state._dlgWidth}
      //           height={this.state._dlgHeight}
      //           close={() => this.setState({ showDialog: false })}
      //           content={this.dialogContent.bind(this)}
      //           isModal={true}
      //         ></DialogComponent>
      //       ) : null}
      //     </div>
      //   </div>
      // </>
      <>
        <div id='attendance' style={{ display: 'grid' }} className='table-container'>
          <div className='mb-2'>
            <div className='PeriodSelector'>
              <DatePickerComponent
                placeholder='Choose a date'
                value={this.state.selectedDate}
                start='Year'
                depth='Year'
                format={'MMMM y'}
                onChange={this.handleChange.bind(this)}
                showClearButton={false}
              />
            </div>
            <div className='DataDisplaySelector'>
              <DropDownListComponent
                dataSource={['Hours', 'Minutes']}
                value='Hours'
                select={this.handleDataDisplay.bind(this)}
              />
            </div>
            <div className='DataDisplaySelector'>
              <DropDownListComponent
                dataSource={['Date', 'Day']}
                value='Date'
                select={this.handleDateDataDisplay.bind(this)}
              />
            </div>
          </div>
          <GridComponent
            id='GrdTimesheet'
            dataSource={this.emp_Data.length > 0 ? this.emp_Data : []}
            height={'Calc(100vh - 420px)'}
            gridLines='Both'
            ref={(sc) => (this.TimeGrid = sc)}
            dataBound={this.onDataBound.bind(this)}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                width='50'
                template={this.commandTemplate.bind(this)}
              /> */}
              <ColumnDirective
                columns={DateColumns}
                width='100'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='In'
                field='In'
                width='50'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='Out'
                field='Out'
                width='50'
                customAttributes={{ class: 'customHeaderCss' }}
              />
              <ColumnDirective
                headerText='Total Work'
                textAlign='Center'
                columns={TimeColumns}
                width='100'
                customAttributes={{ class: 'customHeaderCss' }}
              />
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field='Out'
                    type='Custom'
                    footerTemplate={this.captionTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='BreakTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalBreakTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='WorkedTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalWorkedTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='OverTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalOverTimeTemplate.bind(this)}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    field='ShortTime'
                    type='Custom'
                    customAggregate={this.customAggregate.bind(this)}
                    footerTemplate={this.TotalShortTemplate.bind(this)}
                  ></AggregateColumnDirective>
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Aggregate]} />
          </GridComponent>
        </div>
        <div>
          {this.state.showDialog === true ? (
            <DialogComponent
              id='defaultDialog1'
              showCloseIcon={true}
              visible={this.state.showDialog}
              width={this.state._dlgWidth}
              close={this.dialogclose.bind(this)}
              content={this.dialogContent.bind(this)}
              isModal={true}
              buttons={this.DialogButtonModel}
            ></DialogComponent>
          ) : null}
        </div>
        <ContextMenuComponent ref={menu => this.menuObj = menu} target='#GrdTimesheet' items={this.menuItems} beforeOpen={this.onContextMenuBeforeOpen.bind(this)} select={this.onMenuItemSelect.bind(this)} />
      </>
    );
  }
}

export default Attendance;
